import React from 'react';
import { styled, SwapIcon } from '@statsbomb/react-components';
import { HeaderButton } from './HeaderButton';
import { BodyText2 } from '../typography/Typography.styles';
import { ITheme } from '../../types/ITheme';

const SwapText = styled(BodyText2)`
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: none;
  }
`;

export const MatchSwapTeamButton = ({ onClick }: { onClick: () => void }) => (
  <HeaderButton type="button" onClick={onClick} title="Swap Team">
    <SwapIcon width="24" alt="Swap Team Icon" />
    <SwapText>Swap Team</SwapText>
  </HeaderButton>
);

export default MatchSwapTeamButton;
