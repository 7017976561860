import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { generateNavigation, navConfig } from '../config/navigation';
import { isPlayerKeeper, firstKeeperFromSquad } from '../utils/player';
import { selectedCompSeason, selectedMatchId, selectedPlayerId, selectedTeamId } from './filters';
import { getPlayersInTeamSeason } from './playersInTeam';
import { getUserRole } from './user';

export const isMenuOpen = atom<boolean>(false);
export const isMobileFiltersOpen = atom<boolean>(false);

export const isDesktopMenuExpanded = atomWithStorage('isDesktopMenuExpanded', <boolean>false);

/**
 * I have used playerSelectedId as playerId and selectedPlayerId are declared already
 * And I want keeper and player to be consistent
 */
export const keeperId = atom(get => {
  const squad = get(getPlayersInTeamSeason);
  if (!squad) return null;

  const playerSelectedId = get(selectedPlayerId);
  if (playerSelectedId && isPlayerKeeper('primaryPosition', playerSelectedId, squad)) return playerSelectedId;

  try {
    const kId = firstKeeperFromSquad(squad).playerId;
    return kId;
  } catch (e) {
    // Print the error so that we can see it.
    // eslint-disable-next-line no-console
    console.error(e);
  }

  // Keeper ID not found. Probably errored. When we make a new CompSeason it has no matches, so there are no keepers!
  return 0;
});

export const playerId = atom(get => get(selectedPlayerId));

export const teamId = atom(get => get(selectedTeamId));

export const matchId = atom(get => get(selectedMatchId));

export const compSeasonId = atom(get => {
  const selectedCompSeasonId = get(selectedCompSeason);
  return selectedCompSeasonId || [0, 0];
});

export const navigationItems = atom(get =>
  generateNavigation(
    navConfig,
    get(getUserRole),
    get(keeperId) || 0,
    get(playerId) || 0,
    get(teamId),
    get(matchId),
    get(compSeasonId)[0]
  )
);
