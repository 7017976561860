export const range = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);

/**
 * Calculates which pages to show on the pagination buttons, attempting to keep
 * the number of page options displayed constant to prevent the component
 * from changing size.
 *
 * @param {Object} args
 * @param {number} args.currentPage The currently selected page
 * @param {number} args.lastPage The highest page a user could select
 * @param {number} args.adjacentPages Num pages to show either side of the
 * current page button
 * @returns {Array} List of numbers to show on pagination buttons
 */
export const pagesToShow = ({ currentPage, adjacentPages, lastPage }) => {
  const extraPages = adjacentPages + adjacentPages;

  const targetMax = currentPage + adjacentPages;
  const targetMin = currentPage - adjacentPages;

  const max = targetMin <= 1 ? extraPages + 1 : targetMax;
  const min = targetMax >= lastPage ? lastPage - extraPages : targetMin;

  return range(Math.max(min, 1), Math.min(max, lastPage));
};
