import {
  IDisplaySettingPitchMode,
  Period,
  IPitchOrientationFilter,
  IPassesFilter,
  PASSES_BETWEEN_PLAYERS,
  PASSES_VOLUME,
  PASS_XG_OBV_BETWEEN_PLAYERS,
  PASS_VOLUME_PER_PLAYER,
  PASS_XG_OBV_PER_PLAYER,
} from '../../types/passNetwork';
import { ColourTheme } from '../../types/vizTheme';

export const passesFilterConfig: IPassesFilter = {
  [PASSES_BETWEEN_PLAYERS]: {
    selected: true,
    title: 'Passes between players',
    children: {
      [PASSES_VOLUME]: {
        selected: true,
        title: 'Pass volume between players',
      },
      [PASS_XG_OBV_BETWEEN_PLAYERS]: {
        selected: true,
        title: 'Pass xG/OBV between players',
      },
    },
  },
  [PASS_VOLUME_PER_PLAYER]: {
    selected: true,
    title: 'Pass volume per player',
  },
  [PASS_XG_OBV_PER_PLAYER]: {
    selected: true,
    title: 'Pass xG/OBV per player',
  },
};

export const modelFiltersConfig = [
  {
    text: 'OBV',
    value: 'obv',
    selected: true,
  },
  {
    text: 'xG Chain',
    value: 'xg',
    selected: false,
  },
];

export const gameFilterGameTimeConfig: readonly Period[] = [
  Period.STARTING,
  Period.FIRST,
  Period.SECOND,
  Period.FIRST_VS_SECOND,
  Period.THIRD,
  Period.FOURTH,
];

export const pitchOrientationConfig: IPitchOrientationFilter[] = [
  {
    text: 'Portrait',
    value: 'portrait',
    selected: true,
  },
  {
    text: 'Landscape',
    value: 'landscape',
    selected: false,
  },
];

export const PlayerPositionsFilterConfig = ['Average Pass Location', 'Starting Position'];

export const displaySettingPitchModeConfig: readonly IDisplaySettingPitchMode[] = [
  {
    text: 'Light',
    value: ColourTheme.LIGHT,
  },
  {
    text: 'Dark',
    value: ColourTheme.DARK,
  },
];
