import React from 'react';
import { useAtomValue } from 'jotai/utils';
import { isDesktopMenuExpanded as isDesktopMenuExpandedAtom, isMenuOpen as isMenuOpenAtom } from './atoms/navigation';
import './App.css';
import { AppRoutes } from './routes/AppRoutes';
import * as monitoring from './utils/monitoring';
import { getUserId } from './atoms/user';

export function App() {
  const isMenuOpen = useAtomValue(isMenuOpenAtom);
  const isDesktopMenuExpanded = useAtomValue(isDesktopMenuExpandedAtom);
  const userId = useAtomValue(getUserId);
  /* istanbul ignore if */
  if (process.env.NODE_ENV !== 'test') {
    monitoring.initialSetup(userId);
  }

  return <AppRoutes {...{ isMenuOpen, isDesktopMenuExpanded }} />;
}

export default App;
