import React, { useEffect, useState } from 'react';

/**
 * Passes the 'scrollingDown' prop to the supplied component.
 * `scrollingDown` is true when the user is scrolling down, otherwise false.
 * **NOTE: May require you to specify the Props generic 'P' on assignment or the compiler may complain.**
 * e.g. const Foo = withSticky<{ children: React.ReactNode; role: string }>
 *
 * @param WrappedComponent The component to make sticky
 * @returns The same component passed in but with the 'scrollingDown' prop added
 */
export const withScrollingDown =
  <P extends {}>(WrappedComponent: React.JSXElementConstructor<P>) =>
  (props: P) => {
    const [scrollDepth, setScrollDepth] = useState(0);
    const [scrollingDown, setScrollingDown] = useState(false);

    // GCM: Ignoring the handleScroll fn because JSDom doesn't handle scroll events making this hard to test.
    /* istanbul ignore next */
    const handleScroll = () => {
      setScrollingDown(window.pageYOffset > scrollDepth);
      setScrollDepth(window.pageYOffset);
    };

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [scrollDepth]);

    return <WrappedComponent scrollingDown={scrollingDown} {...props} />;
  };

export default withScrollingDown;
