import { DownloadIcon as StyledDownloadIcon, styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

type ButtonVariants = 'primary' | 'secondary';

export const Button = styled.button(({ theme, $variant = 'primary' }: { theme: ITheme; $variant: ButtonVariants }) => ({
  border: 'none',
  borderRadius: 3,
  backgroundColor:
    $variant === 'primary' ? theme.colours.graphics.tertiary.main : theme.colours.graphics.secondary.main,
  color: $variant === 'primary' ? theme.colours.ink.secondary.main : theme.colours.ink.primary.main,
  padding: [theme.spacing(0.5), theme.spacing(0.7), theme.spacing(0.5), theme.spacing(0.7)].join(' '),
  cursor: 'pointer',
  display: 'flex',
  lineHeight: theme.spacing(3),
  width: 'auto',
  fontSize: theme.typography.button.size,
  fontWeight: theme.typography.button.weight,
  boxShadow: theme.effects.dropShadow.regular,
  float: 'right' as unknown as undefined, // wtf
  '&:hover': {
    backgroundColor: theme.colours.brand.interface.light,
    color: theme.colours.ink.inverse.main,
  },
  '& > span': {
    flex: 2,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },

  '&:hover svg': {
    fill: theme.colours.ink.inverse.main,
  },
}));

export const DownloadIcon = styled(StyledDownloadIcon)`
  fill: ${({ theme }: { theme: ITheme }) => theme.colours.ink.secondary.main};
  height: ${({ theme }: { theme: ITheme }) => theme.spacing(2.5)};
  margin-top: ${({ theme }: { theme: ITheme }) => theme.spacing(0.5)};
  float: left;
  width: auto;
`;
