import {
  map,
  pipe,
  prop,
  filter,
  sum,
  both,
  length,
  uniqBy,
  sortWith,
  descend,
  reverse,
  sortBy,
  propEq,
  Dictionary,
  reduce,
} from 'ramda';
import { MATCH_PERIODS } from '../consts/match';
import { IMatchStat } from '../types/match';
import {
  IFormation,
  IShot,
  IShotsAndKeyPassStats,
  IPassesTouchesXgChainStat,
  IDefensiveActions,
  IObv,
  IPressure,
  IStatTableCol,
  IPassesAndTouchesStat,
  IPressureStat,
  IXgChainStat,
} from '../types/matchStats';
import { ISubstitution, ISubstitutionFormatted } from '../types/substitutions';
import { ITeamNameIndex } from '../types/team';

export const shotTakenByPlayer = (player: IFormation) => (shot: IShot) => shot.playerId === player.playerId;
export const shotAssistTakenByPlayer = (player: IFormation) => (shot: IShot) =>
  shot.shotAssistPlayerId === player.playerId && shot.assisted;
export const isShotGoal = (shot: IShot) => shot.goal === true;
export const isShotFromSetPiece = (shot: IShot): boolean => shot.fromSetPiece === true;
export const isNotShotFromSetPiece = (shot: IShot): boolean => shot.fromSetPiece === false;

export const getXg: (s: Record<'xg', number>) => number = prop('xg');
export const sumXg = pipe(map(getXg), sum);
export const calculateXg = (player: IFormation) => pipe(filter(shotTakenByPlayer(player)), sumXg);

export const assistSetPiece = (player: IFormation) => filter(both(shotAssistTakenByPlayer(player), isShotFromSetPiece));
export const assistNotSetPiece = (player: IFormation) =>
  filter(both(shotAssistTakenByPlayer(player), isNotShotFromSetPiece));

export const calculateXgAssists = (player: IFormation) =>
  pipe(filter(shotAssistTakenByPlayer(player)), map(getXg), sum);

type CalculateShotsData = (arg0: IFormation) => (arg1: IShot[]) => number;

export const calculateShots: CalculateShotsData = player => pipe(filter(shotTakenByPlayer(player)), length);

export const calculateGoals: CalculateShotsData = (player: IFormation) =>
  pipe(filter(both(shotTakenByPlayer(player), isShotGoal)), length);

export const calculateKeyPasses: CalculateShotsData = (player: IFormation) =>
  pipe(filter(shotAssistTakenByPlayer(player)), length);

export const calculateAssists: CalculateShotsData = (player: IFormation) =>
  pipe(filter(shotAssistTakenByPlayer(player)), filter(isShotGoal), length);

export const calculateOpenPlayKeyPasses: CalculateShotsData = (player: IFormation) =>
  pipe(filter(both(shotAssistTakenByPlayer(player), isNotShotFromSetPiece)), length);

export const calculateOpenPlayAssists: CalculateShotsData = (player: IFormation) =>
  pipe(assistNotSetPiece(player), filter(isShotGoal), length);

export const calculateOpenPlayXgAssists: CalculateShotsData = (player: IFormation) =>
  pipe(assistNotSetPiece(player), sumXg);

export const calculateSetPieceKeyPasses: CalculateShotsData = (player: IFormation) =>
  pipe(filter(both(shotAssistTakenByPlayer(player), isShotFromSetPiece)), length);

export const calculateSetPieceAssists: CalculateShotsData = (player: IFormation) =>
  pipe(assistSetPiece(player), filter(isShotGoal), length);

export const calculateSetPieceXgAssist = (player: IFormation) => pipe(assistSetPiece(player), sumXg);

export const getPlayerData =
  (shotsData: IShot[]) =>
  (player: IFormation): IShotsAndKeyPassStats => {
    const xg = calculateXg(player)(shotsData);
    const shots = calculateShots(player)(shotsData);

    return {
      playerId: player.playerId,
      playerName: player.playerName,
      teamId: player.playerTeamId,
      teamName: player.playerTeamId === player.homeTeamId ? player.homeTeamName : player.awayTeamName,
      shots,
      goals: calculateGoals(player)(shotsData),
      xg,
      kp: calculateKeyPasses(player)(shotsData),
      assists: calculateAssists(player)(shotsData),
      xgAssist: calculateXgAssists(player)(shotsData),
      opkp: calculateOpenPlayKeyPasses(player)(shotsData),
      opAssists: calculateOpenPlayAssists(player)(shotsData),
      opXgAssist: calculateOpenPlayXgAssists(player)(shotsData),
      spkp: calculateSetPieceKeyPasses(player)(shotsData),
      spAssists: calculateSetPieceAssists(player)(shotsData),
      spXgAssist: calculateSetPieceXgAssist(player)(shotsData),
      xgShots: xg > 0 ? xg / shots : 0,
    };
  };

const ignoreFields = ['playerId', 'playerName', 'teamId', 'teamName'];

export const playerHasStats = (data: Dictionary<number | string>) =>
  Object.keys(data).some(key => !ignoreFields.includes(key) && data[key] !== 0 && data[key] !== null);

export const splitHomeAway = <T extends { teamId: number }>(
  homeTeamId: number,
  awayTeamId: number,
  homeTeamName: string,
  awayTeamName: string,
  data: T[]
): { homeData: Array<T & ITeamNameIndex>; awayData: Array<T & ITeamNameIndex> } => ({
  homeData: data.filter(d => d.teamId === homeTeamId).map(d => ({ ...d, teamName: homeTeamName })),
  awayData: data.filter(d => d.teamId === awayTeamId).map(d => ({ ...d, teamName: awayTeamName })),
});

export const formatShotsAndPassesTable = (
  homeTeamId: number,
  awayTeamId: number,
  homeTeamName: string,
  awayTeamName: string,
  shots: IShot[]
) =>
  pipe(
    uniqBy((f: IFormation) => f.playerId),
    map(getPlayerData(shots)),
    filter<Dictionary<number | string>>(playerHasStats),
    sortWith([descend(prop('shots'))]),
    data => splitHomeAway<IShotsAndKeyPassStats>(homeTeamId, awayTeamId, homeTeamName, awayTeamName, data)
  );

export const getPlayerDataPassesAndTouches = (stat: IPassesTouchesXgChainStat): IPassesAndTouchesStat => ({
  playerId: stat.playerId,
  playerName: stat.playerName,
  teamId: stat.teamId,
  teamName: stat.teamName,
  tb: stat.playerMatchThroughBalls,
  pib: stat.playerMatchPassesInsideBox,
  pIntoB: stat.playerMatchPassesIntoBox,
  opPintoB: stat.playerMatchOpPassesIntoBox,
  tib: stat.playerMatchTouchesInsideBox,
  lb: stat.playerMatchLongBalls,
  lbPercentage: stat.playerMatchLongBallRatio * 100,
  opPass: stat.playerMatchOpPasses,
  opF3Pass: stat.playerMatchOpF3Passes,
  passPercentage: stat.playerMatchPassingRatio * 100,
  cross: stat.playerMatchCrosses,
  crossPercentage: stat.playerMatchCrossingRatio * 100,
});

export const formatTable = (
  mapFn: any,
  homeTeamId: number,
  awayTeamId: number,
  homeTeamName: string,
  awayTeamName: string,
  sortByProp: string = 'playerMatchXgchain'
) =>
  pipe(
    // @ts-ignore
    sortBy(prop(sortByProp)),
    reverse,
    map(mapFn),
    filter<Dictionary<number | string>>(playerHasStats)
  );

export const getXgChain = (stat: IXgChainStat) => ({
  playerId: stat.playerId,
  playerName: stat.playerName,
  teamId: stat.teamId,
  xgChain: stat.xgChain,
  opXgChain: stat.opXgChain,
  xgBuildup: stat.xgBuildup,
  opXgBuildup: stat.opXgBuildup,
});

export const getDefensiveActionStats = (stat: IDefensiveActions) => ({
  playerId: stat.playerId,
  playerName: stat.playerName,
  teamId: stat.teamId,
  tackles: stat.playerMatchTackles,
  interceptions: stat.playerMatchInterceptions,
  tacklesAndInterceptions: stat.playerMatchTackles + stat.playerMatchInterceptions,
  dribbledPast: stat.playerMatchDribbledPast,
  challengePercentage: stat.playerMatchChallengeRatio * 100,
  fouls: stat.playerMatchFouls,
  clearances: stat.playerMatchClearances,
  successfulAerials: stat.playerMatchSuccessfulAerials,
  aerialPercentage: stat.playerMatchAerialRatio * 100,
  dribbles: stat.playerMatchDribbles,
  dispossessions: stat.playerMatchDispossessions,
  penaltiesWon: stat.playerMatchPenaltiesWon,
});

export const getObvStats = (stat: IObv) => ({
  playerId: stat.playerId,
  playerName: stat.playerName,
  teamId: stat.teamId,
  obv: stat.obv,
  obvPass: stat.obvPass,
  obvShot: stat.obvShot,
  obvDefensiveAction: stat.obvDefensiveAction,
  obvDribbleCarry: stat.obvDribbleCarry,
  obvGk: stat.obvGk,
});

// Under pressures!
const isPressureByPlayer = (player: IFormation) => (pressure: IPressure) =>
  pressure.pressurePlayerId === player.playerId;

type CalculatePressureData = (arg0: IFormation) => (arg1: IPressure[]) => number;

const countPressures: CalculatePressureData = (player: IFormation) => pipe(filter(isPressureByPlayer(player)), length);

const getDuration: (s: Record<'pressureDuration', number>) => number = prop('pressureDuration');
const sumDuration = pipe(map(getDuration), sum);
const calculateTotalDuration = (player: IFormation) => pipe(filter(isPressureByPlayer(player)), sumDuration);
const calculateActionFails: CalculatePressureData = (player: IFormation) =>
  pipe(filter(both(isPressureByPlayer(player), propEq('pressuredEventOutcome', false))), length);

export const getPressures =
  (pressures: IPressure[]) =>
  (player: IFormation): IPressureStat => {
    const numPressures = pipe(uniqBy(prop('pressureEventId')), countPressures(player))(pressures) as number;

    const totalDurationFormatted = pipe(
      uniqBy(prop('pressureEventId')),
      calculateTotalDuration(player)
    )(pressures) as number;

    return {
      playerId: player.playerId,
      playerName: player.playerName,
      teamId: player.playerTeamId,
      teamName: player.playerTeamId === player.homeTeamId ? player.homeTeamName : player.awayTeamName,
      pressures: numPressures,
      totalDuration: totalDurationFormatted,
      durationPerPressure: numPressures > 0 ? totalDurationFormatted / numPressures : 0,
      pressuredActionFails: calculateActionFails(player)(pressures),
    };
  };

export const formatPressuresTable = (
  homeTeamId: number,
  awayTeamId: number,
  homeTeamName: string,
  awayTeamName: string,
  pressures: IPressure[]
) =>
  pipe(
    uniqBy((f: IFormation) => f.playerId),
    (data: IFormation[]) => map(getPressures(pressures))(data),
    sortBy(prop('pressures')),
    data => reverse(data),
    filter<Dictionary<number | string>>(playerHasStats),
    data => splitHomeAway<IPressureStat>(homeTeamId, awayTeamId, homeTeamName, awayTeamName, data)
  );

export const isCounterPressure = (p: IPressure) => !!p.isGegen;

export const formatStatRow =
  <T extends ITeamNameIndex>(groupByKey: string, config: IStatTableCol[]) =>
  (statRow: T) =>
    reduce(
      (newStats, stat) => ({ ...newStats, [stat.title]: statRow[stat.stat] }),
      { [groupByKey]: statRow[groupByKey], teamName: statRow.teamName },
      config
    );

interface IMatchStatCheck extends IMatchStat {
  [key: string]: any;
  homeTeam: boolean;
  teamName: string;
  teamId: number;
}

export const formatMatchStatRow = (config: IStatTableCol[]) => (statRow: IMatchStatCheck) =>
  reduce((newStats, stat) => ({ ...newStats, [stat.title]: statRow[stat.stat] }), { teamId: statRow.teamId }, config);

export const formatSubstitutions = (sub: ISubstitution): ISubstitutionFormatted => ({
  ...sub,
  period: MATCH_PERIODS[sub.periodId],
});
