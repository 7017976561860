import { pipe, filter, not, isEmpty, find } from 'ramda';
import { IFormation } from '../types/matchStats';
import { IPlayerSeason } from '../types/player';

const goalkeeper = 'Goalkeeper';

export const isPlayerPosition = (key: string, playerId: number, position: string) =>
  pipe(
    filter(
      (playerStats: IPlayerSeason | IFormation) => playerStats.playerId === playerId && playerStats[key] === position
    ),
    isEmpty,
    not
  );

export const playerMatchingPosition = (position: string) =>
  pipe(find((playerStats: IPlayerSeason) => playerStats.primaryPosition === position));

export const playerNotMatchingPosition = (position: string) =>
  pipe(find((playerStats: IPlayerSeason) => playerStats.primaryPosition !== position));

export const isPlayerKeeper = (
  key: string,
  playerId: number,
  playerSeasonData: IPlayerSeason[] | IFormation[]
): boolean => isPlayerPosition(key, playerId, goalkeeper)(playerSeasonData);

export const firstKeeperFromSquad = (playerSeasonData: IPlayerSeason[]): IPlayerSeason => {
  const firstGoalkeeper = playerMatchingPosition(goalkeeper)(playerSeasonData);
  if (!firstGoalkeeper) throw new Error('Goalkeeper not found in Squad');
  return firstGoalkeeper;
};

export const firstOutfielderFromSquad = (playerSeasonData: IPlayerSeason[]): IPlayerSeason => {
  const firstOutfielder = playerNotMatchingPosition(goalkeeper)(playerSeasonData);
  if (!firstOutfielder) throw new Error('Outfield not found in Squad');
  return firstOutfielder;
};
