import React from 'react';
import { FilterIcon } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { IMatchDetails } from '../../types/match';
import { MatchDetailText, MobileSubnavWrapper } from './MobileSubnav.styles';
import { hyphenDateFromTZ } from '../../utils/general';
import withScrollingDown from '../../hoc/withScrollingDown';
import { isMobileFiltersOpen } from '../../atoms/navigation';
import { IconButton } from '../button/IconButton';

const StickyWrapper =
  withScrollingDown<{ children: React.ReactNode; role: string; onClick: () => void }>(MobileSubnavWrapper);

export const MobileSubnavMatchSection = ({ matchDetails }: { matchDetails?: IMatchDetails }) => {
  const { homeTeamName, awayTeamName, matchDate } = matchDetails || {};
  const formattedMatchDate = matchDate && hyphenDateFromTZ(matchDate as string);
  const [, setIsOpen] = useAtom(isMobileFiltersOpen);

  return (
    <StickyWrapper role="presentation" onClick={() => setIsOpen(true)}>
      <MatchDetailText>
        {matchDetails ? `${homeTeamName} vs ${awayTeamName}, ${formattedMatchDate}` : `Loading Match Details...`}
      </MatchDetailText>
      <IconButton icon={<FilterIcon width={30} />} />
    </StickyWrapper>
  );
};
