export const WIDTH = 120;
export const HEIGHT = 80;
export const PENALTY_BOX_WIDTH = 44;
export const PENALTY_BOX_HEIGHT = 18;
export const WIDTH_ZONES = 5;
export const PENALTY_BOX_WIDTH_ZONES = 3;
export const SIX_YARD_BOX_WIDTH = 6;
export const SIX_YARD_BOX_HEIGHT = 20;
export const PENALTY_SPOT_DISTANCE = 12;
export const CENTRE_CIRCLE_RADIUS = 10;
export const SPOT_RADIUS = 0.2;
export const GOAL_HEIGHT = 8;
