import { partial } from 'ramda';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { TABLE_SORT_STATES } from '../../types/table';
import { sortItems } from '../../utils/table';

export const xgChain = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player',
    toolTip: 'Player Name',
    stat: 'playerName',
    sortableFn: partial(sortItems, ['playerName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'xGChain',
    toolTip: 'xG Chain',
    stat: 'xgChain',
    toFixed: 2,
    sortableFn: partial(sortItems, ['xgChain']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OP xGChain',
    toolTip: 'Open Play xG Chain',
    stat: 'opXgChain',
    toFixed: 2,
    sortableFn: partial(sortItems, ['opXgChain']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'xG Buildup',
    toolTip: 'xG Buildup',
    stat: 'xgBuildup',
    toFixed: 2,
    sortableFn: partial(sortItems, ['xgBuildup']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OP xG Buildup',
    toolTip: 'Open Play xG Buildup',
    stat: 'opXgBuildup',
    toFixed: 2,
    sortableFn: partial(sortItems, ['opXgBuildup']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
];
