import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

export const Icon = styled.span`
  margin-right: ${({ theme }: { theme: ITheme }) => theme.spacing(0.5)};
  min-width: ${({ theme }: { theme: ITheme }) => theme.spacing(2)};
  min-height: ${({ theme }: { theme: ITheme }) => theme.spacing(2)};
  > svg {
    fill: ${({ has360, theme }: { has360: boolean; theme: ITheme }) =>
      has360 ? theme.colours.brand.interface.main : theme.colours.graphics.secondary.main};
    &:hover {
      fill: ${({ has360, theme }: { has360: boolean; theme: ITheme }) =>
        has360 ? theme.colours.brand.interface.dark : theme.colours.graphics.secondary.dark};
    }
  }
`;
