import { IStatTableCol } from '../../types/matchStats';

export const matchStatsMetaData: IStatTableCol[] = [
  {
    title: 'Goals',
    stat: 'score',
    toFixed: 0,
  },
  {
    title: 'Penalty Shootout',
    stat: 'penaltyScore',
    toFixed: 0,
  },
  {
    title: 'xG',
    stat: 'xg',
    toFixed: 2,
  },
  {
    title: 'Cumulative xG',
    stat: 'xgConditional',
    toFixed: 2,
  },
  {
    title: 'Shots',
    stat: 'shots',
    toFixed: 0,
  },
  {
    title: 'Shots On Target',
    stat: 'shotsOnTarget',
    toFixed: 0,
  },
  {
    title: 'Possession %',
    stat: 'posessionRatio',
    toFixed: 0,
  },
  {
    title: 'Pass Completion %',
    stat: 'passRatio',
    toFixed: 0,
  },
  {
    title: 'Total Passes (Completed)',
    stat: 'totalPasses',
  },
  {
    title: 'Pressures',
    stat: 'pressures',
    toFixed: 0,
  },
  {
    title: 'Pressure Regains',
    stat: 'pressureRegains',
    toFixed: 0,
  },
  {
    title: 'Tackles Won (Attempts)',
    stat: 'tacklesWon',
  },
  {
    title: 'Yellow Cards',
    stat: 'yellowCards',
    toFixed: 0,
  },
  {
    title: 'Red Cards / Second Yellow Cards',
    stat: 'redAndSecondYellowCards',
  },
];

export const matchStatsMetaDataExtraFields: IStatTableCol[] = [
  {
    title: 'Team name',
    stat: 'teamName',
  },
];
