import { styled } from '@statsbomb/react-components';
import { GAP_BETWEEN_COMPONENTS } from '../../../consts/spacing';
import { ITheme } from '../../../types/ITheme';
import { desktopOnly, MobileOnlyCard } from '../../gridVariants/gridVariants';

export const PageTitle = styled.h2(({ theme }: { theme: ITheme }) => ({
  fontSize: theme.typography.heading2.size,
  fontWeight: theme.typography.heading2.weight,
  flexBasis: '33rem',
  [theme.mqFrom('md')]: {
    float: 'left',
  },
}));

export const ScoreLine = styled.div(({ theme }: { theme: ITheme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.typography.body2.size,
  fontWeight: theme.typography.body2.weight,
  paddingRight: theme.spacing(1),
  flexBasis: '100%',
  [theme.mqFrom('md')]: {
    textAlign: 'right',
  },
}));

export const DesktopOnly = desktopOnly(styled.div`
  display: flex;
`);

export const Logo = styled.img(() => ({
  float: 'right',
  margin: `${GAP_BETWEEN_COMPONENTS} auto`,
}));

export const MobileTitleCard = styled(MobileOnlyCard)(() => ({
  alignItems: 'flex-start',
  margin: `${GAP_BETWEEN_COMPONENTS} 0`,
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
