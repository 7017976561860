/* istanbul ignore file */
import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';
import { FullscreenContainer } from '../modals/Fullscreen.styles';

export const Section = styled.section`
  display: flex;
  width: 100%;
  padding: ${({ theme }: { theme: ITheme }) => `${theme.spacing()} 0`};
  align-items: center;
`;

export const TitleSection = styled(Section)`
  justify-content: space-between;
  padding-bottom: 0;
`;

export const ChildrenSection = styled(Section)`
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }: { theme: ITheme }) => theme.spacing(2)};
`;

export const CloseButton = styled.button`
  border: 0;
  background: transparent;
  &:hover {
    > svg {
      fill: ${({ theme }: { theme: ITheme }) => theme.colours.brand.interface.light};
    }
  }
`;

export const MobileFilterModal = styled(FullscreenContainer)`
  flex-direction: column;
  padding: ${({ theme }: { theme: ITheme }) => theme.spacing()};
  gap: ${({ theme }: { theme: ITheme }) => theme.spacing(2)};
  width: 100%;
  align-items: center;
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: none;
  }
`;
