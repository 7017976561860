import React from 'react';
import { CloseIcon } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { MobileFilterModal, TitleSection, ChildrenSection } from './MobileFilters.styles';
import { Button } from '../button/Button.styles';
import { Title } from '../typography/Typography.styles';
import { IconButton } from '../button/IconButton';
import { isMobileFiltersOpen } from '../../atoms/navigation';

interface MobileFiltersProps {
  title: string;
  children: React.ReactNode;
}

export const MobileFilters = (props: MobileFiltersProps) => {
  const { title, children } = props;

  const [isOpen, setIsOpen] = useAtom(isMobileFiltersOpen);

  if (!isOpen) return null;

  const onClickFn = () => setIsOpen(false);
  return (
    <MobileFilterModal>
      <TitleSection>
        <Title>{title}</Title>
        <IconButton onClick={onClickFn} icon={<CloseIcon width="24" alt="Close Icon" />} />
      </TitleSection>
      <ChildrenSection>{children}</ChildrenSection>
      <Button onClick={onClickFn}>Close</Button>
    </MobileFilterModal>
  );
};
