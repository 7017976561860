import { toPairs, reduce, pipe, mergeLeft, cond, identity, mergeRight, objOf, T, map, ifElse } from 'ramda';
import { IApiInit } from '../types/api';
import { IKeyOfStrings } from '../types/generic';

export const isLocal = document.location.port === '3000' || document.location.port === '8081';

const resourcePrefixPath = '/v2';
const pagePrefixPath = '/_';

/* istanbul ignore next */
const apiPrefix = '/api/v2';

/* istanbul ignore next */
export const resourcePrefix = (path: string) => (isLocal ? '' : resourcePrefixPath) + path;

/* istanbul ignore next */
export const pagePrefix = (path: string) => pagePrefixPath + path;

// Replace snake_case stuff with camelCase stuff
export const snakeToCamel = (key: string) => key.replace(/_(\w)/g, (_, c) => c.toUpperCase());

const isObj = (value: unknown): value is StrObj => typeof value === 'object' && value !== null && !Array.isArray(value);

const isArr = (value: unknown): value is Array<StrObj> => Array.isArray(value);

type StrObj = Record<string, unknown>;
type ConvFn = (keyName: string) => string;
type ResponseConversionFn = (convFn: ConvFn) => (o: StrObj) => object;

export const convertResponse: ResponseConversionFn = convertFn =>
  ifElse(
    isObj,
    pipe(
      toPairs,
      reduce(
        (newObj: object, [key, v]: [string, unknown]) =>
          pipe(
            cond([
              // @ts-ignore
              [isArr, map(convertResponse(convertFn))],
              [isObj, convertResponse(convertFn)],
              [T, identity],
            ]),
            objOf(convertFn(key)),
            mergeRight(newObj)
          )(v as StrObj),
        {}
      )
    ),
    identity
  );

export const camelToSnake = (key: string) => key.replace(/[A-Z]/g, s => `_${s.toLowerCase()}`);

export const camelToKebab = (key: string) => key.replace(/[A-Z]/g, s => `-${s.toLowerCase()}`);

export const convertObjKeysCamelToKebab = pipe(
  toPairs,
  reduce((newObj: object, [key, value]) => mergeLeft({ [camelToKebab(key)]: value }, newObj), {})
);

/**
 * Redirect to /login if the last call was redirected.
 * This could be a bad idea if there are any redirects on the site to anything other than /login
 * but I can't work out how to grab the location header locally as it's cross origin.
 * */
export const handleResponse = async (res: Response) => {
  if (res?.type === 'opaqueredirect') {
    (window as any).location = '/login';
  }
  return res.json();
};

export const fetchFromApi = async (input: RequestInfo, init?: RequestInit) => {
  const res = await fetch(apiPrefix + input, {
    ...{
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      redirect: 'manual',
    },
    ...init,
  });

  return handleResponse(res);
};

export const fetchMany = async <K extends IKeyOfStrings>(input: RequestInfo, init?: IApiInit<K>) => {
  const body = init?.body ? JSON.stringify(convertObjKeysCamelToKebab(init.body)) : null;
  const response = await fetchFromApi(input, { ...init, body });
  return response.map(convertResponse(snakeToCamel));
};

export const fetchOne = async <K extends IKeyOfStrings>(input: RequestInfo, init?: IApiInit<K>) => {
  const body = init?.body ? JSON.stringify(convertObjKeysCamelToKebab(init.body)) : null;
  const response = await fetchFromApi(input, { ...init, body });
  return convertResponse(snakeToCamel)(response);
};
