import React from 'react';
import { DropdownSelectList } from '@statsbomb/react-components';
import { useAtomValue } from 'jotai/utils';
import * as filters from '../../atoms/filters';
import { DropdownClickHandler, IOptionCS, ICompSeasonOption } from '../../types/dropdown';
import { ICompetitionSeason } from '../../types/competitionSeason';
import { compSeasonsForTeam } from '../../atoms/competitionSeasons';
import { generateCompSeasonId } from '../../utils/competitionSeasons';
import { CompSeasonOptionInner, FlagIcon, FlagIconMissing, TextWithEllipsis } from './CompSeasonFilter.styles';
import { Icon } from './MatchFilter.styles';

// JTD: These are continents, but there may be others we don't have flags for.
const countryCodesWithoutFlags = ['sam', 'ncam', 'asia', 'afr'];

const DropdownList = DropdownSelectList;
const { Option } = DropdownList.Components;

export const CompSeasonOption = ({ data, ...props }: { data: ICompSeasonOption }) => (
  <Option {...props}>
    <CompSeasonOptionInner>
      <TextWithEllipsis title={data?.label} $width={50}>
        {data?.label}
      </TextWithEllipsis>
      <TextWithEllipsis $width={30}>{data?.seasonName}</TextWithEllipsis>
      <Icon>
        {data.countryCode && !countryCodesWithoutFlags.includes(data.countryCode) ? (
          <FlagIcon
            src={`/images/svg/Flags/4x3/${data.countryCode}.svg`}
            height="16"
            width="20"
            alt={`Flag Icon ${data.countryCode}`}
          />
        ) : (
          <FlagIconMissing />
        )}
      </Icon>
    </CompSeasonOptionInner>
  </Option>
);

export const CompSeasonFilterInner = ({
  compSeasons,
  selectedCompSeason,
  onSelectCompSeason,
}: {
  compSeasons: ICompetitionSeason[];
  selectedCompSeason?: ICompetitionSeason;
  onSelectCompSeason: DropdownClickHandler<IOptionCS>;
}) => (
  <DropdownList
    components={{ Option: CompSeasonOption }}
    label="Competition/Season"
    name="compseason-select"
    options={compSeasons.map(cs => ({
      value: generateCompSeasonId(cs.competitionId, cs.seasonId),
      label: cs.competitionName,
      seasonName: cs.seasonName,
      countryCode: cs.countryCode,
      countryId: cs.countryId,
      countryName: cs.countryName,
    }))}
    placeholder="Select a Comp/Season"
    value={
      selectedCompSeason
        ? {
            value: generateCompSeasonId(selectedCompSeason.competitionId, selectedCompSeason.seasonId),
            label: `${selectedCompSeason.competitionName} ${selectedCompSeason.seasonName}`,
            seasonName: `${selectedCompSeason.seasonName}`,
            countryCode: selectedCompSeason.countryCode,
            countryId: selectedCompSeason.countryId,
            countryName: selectedCompSeason.countryName,
          }
        : null
    }
    onSelect={onSelectCompSeason}
  />
);

export const CompSeasonFilter = ({ onSelectCompSeason }: { onSelectCompSeason: DropdownClickHandler<IOptionCS> }) => {
  const compSeasons = useAtomValue(compSeasonsForTeam);
  const compSeason = useAtomValue(filters.selectedCompSeason);
  if (!compSeason || !compSeasons || !compSeasons.length) return null;

  const [selectedCompId, selectedSeasonId] = compSeason;

  const selectedCompSeason = compSeasons.find(
    cs => cs.competitionId === selectedCompId && cs.seasonId === selectedSeasonId
  );

  return <CompSeasonFilterInner {...{ compSeasons, selectedCompSeason, onSelectCompSeason }} />;
};
