import React from 'react';
import { pipe } from 'ramda';
import {
  ThreeSixtyIcon,
  PlayerSoccerIcon,
  TeamSoccerIcon,
  MatchSoccerIcon,
  LeagueIcon,
  IqTacticsIcon,
  IqScoutIcon,
  AdminIcon,
  AccountIcon,
  SupportIcon,
  GoalkeeperIcon,
} from '@statsbomb/react-components';
import { LoginType } from '../types/IUser';
import { INavSection, Nav, SectionPrefix } from '../types/Navigation';

export const onlyAdmins = (loginType: LoginType) => loginType === 'admin' || loginType === 'dev-admin';
export const onlyDevAdmins = (loginType: LoginType) => loginType === 'dev-admin';

const goalkeeperUrlPrefix = '/keeper/:keeperId';
const playerUrlPrefix = '/player/:playerId';
const teamUrlPrefix = '/team/:teamId';
const matchrUrlPrefix = '/match/:matchId';
const leaguerUrlPrefix = '/competition/:compId';

export const navConfig: Nav = [
  {
    id: 'player',
    name: 'Player',
    icon: <PlayerSoccerIcon />,
    sections: ['/player'],
    position: 'top',
    links: [
      { name: 'Overview', href: `${playerUrlPrefix}/dashboard` },
      { name: 'Radars', href: `${playerUrlPrefix}/radar` },
      { name: 'Shot Map', href: `${playerUrlPrefix}/shots` },
      { name: 'Shot Distribution', href: `${playerUrlPrefix}/dot-histogram` },
      { name: 'Defense Activity', href: `${playerUrlPrefix}/defensive-activity` },
      { name: 'Minutes Played', href: `${playerUrlPrefix}/minutes-played` },
      { name: 'Similar Players', href: `${playerUrlPrefix}/similar` },
      { name: 'Positions Played', href: `${playerUrlPrefix}/player-position` },
    ],
  },
  {
    id: 'keeper',
    name: 'Keeper',
    icon: <GoalkeeperIcon />,
    sections: ['/keeper'],
    position: 'top',
    links: [
      { name: 'Goalkeeper Overview', href: `${goalkeeperUrlPrefix}/overview` },
      { name: 'Radars GK', href: `${goalkeeperUrlPrefix}/radar` },
      { name: 'League Table', href: `${goalkeeperUrlPrefix}/league-table` },
      { name: 'Shots Faced Map', href: `${goalkeeperUrlPrefix}/shots-faced` },
      { name: 'Shots Faced Distribution', href: `${goalkeeperUrlPrefix}/dot-histogram` },
      { name: 'Goal View', href: `${goalkeeperUrlPrefix}/goal-view` },
      { name: 'Goalkeeper Comparison', href: `${goalkeeperUrlPrefix}/scatter` },
      { name: 'Minutes Played', href: `${goalkeeperUrlPrefix}/minutes-played` },
      { name: 'Claimables', href: `${goalkeeperUrlPrefix}/claimables` },
      { name: 'Similar Players', href: `${goalkeeperUrlPrefix}/similar` },
    ],
  },
  {
    id: 'team',
    name: 'Team',
    icon: <TeamSoccerIcon />,
    sections: ['/team'],
    position: 'top',
    links: [
      { name: 'Overview', href: `${teamUrlPrefix}/dashboard` },
      { name: 'Team Style', href: `${teamUrlPrefix}/radar` },
      { name: 'Fixtures', href: `${teamUrlPrefix}/fixtures` },
      { name: 'Results', href: `${teamUrlPrefix}/league-table` },
      { name: 'Squad', href: `${teamUrlPrefix}/squad` },
      { name: 'Player Ages', href: `${teamUrlPrefix}/age-minutes-scatter` },
      { name: 'Player Comparison', href: `${teamUrlPrefix}/treemap` },
      { name: 'Shot Map', href: `${teamUrlPrefix}/shots` },
      { name: 'Shot Distribution - Players', href: `${teamUrlPrefix}/dot-histogram` },
      { name: 'Shot Distribution - For and Against', href: `${teamUrlPrefix}/xg-distro` },
      { name: 'Trendline', href: `${teamUrlPrefix}/trendline` },
      { name: 'Match Time', href: `${teamUrlPrefix}/xg-timing` },
      { name: 'Corners', href: `${teamUrlPrefix}/corners` },
      { name: 'Defensive Activity', href: `${teamUrlPrefix}/heatmap` },
      { name: 'Similar Teams', href: `${teamUrlPrefix}/similar` },
      { name: 'Engine Room', href: `${teamUrlPrefix}/pass-simulation` },
    ],
  },
  {
    id: 'match',
    name: 'Match',
    icon: <MatchSoccerIcon />,
    sections: ['/match'],
    position: 'top',
    links: [
      { name: 'Overview', href: `${matchrUrlPrefix}/dashboard` },
      { name: 'Match Stats', href: `/_${matchrUrlPrefix}/match-stats` },
      { name: 'Race Chart', href: `${matchrUrlPrefix}/race-chart` },
      { name: 'Shot Map', href: `${matchrUrlPrefix}/shots` },
      { name: 'Pass Network', href: `/_${matchrUrlPrefix}/pass-network` },
      { name: 'Corner Touches', href: `${matchrUrlPrefix}/corners` },
      { name: 'Pressure Map', href: `${matchrUrlPrefix}/pressure-map` },
      { name: 'Formation', href: `${matchrUrlPrefix}/formations` },
      { name: 'Freeze Frame Viewer', href: `${matchrUrlPrefix}/freeze-frame-viewer` },
    ],
  },
  {
    id: 'league',
    name: 'League',
    icon: <LeagueIcon />,
    sections: ['/competition'],
    position: 'top',
    links: [
      { name: 'League Table', href: `${leaguerUrlPrefix}/league-table` },
      { name: 'Fixtures', href: `${leaguerUrlPrefix}/fixtures` },
      { name: 'Team Stats', href: `${leaguerUrlPrefix}/team-stats` },
      { name: 'Team Comparison', href: `${leaguerUrlPrefix}/team-comparison` },
      { name: 'Player Stats', href: `${leaguerUrlPrefix}/player-stats` },
    ],
  },
  {
    id: 'tactics',
    name: 'Tactics',
    icon: <IqTacticsIcon />,
    sections: ['/engine-room'],
    position: 'top',
    links: [{ name: 'IQ Tactics', href: `/engine-room` }],
  },
  {
    id: 'scout',
    name: 'Scout',
    icon: <IqScoutIcon />,
    sections: ['/scout'],
    position: 'top',
    links: [
      { name: 'Scout Men', href: `/scout/search/men` },
      { name: 'Scout Women', href: `/scout/search/women` },
    ],
  },
  {
    id: '360',
    name: '360',
    icon: <ThreeSixtyIcon />,
    sections: ['/match'],
    position: 'top',
    links: [{ name: '360', href: `${matchrUrlPrefix}/freeze-frame-viewer` }],
  },
  {
    id: 'account',
    icon: <AccountIcon />,
    name: 'Account',
    sections: ['/personalisation', '/status'],
    position: 'bottom',
    links: [
      { name: 'Favourites', href: `/personalisation/my-favourites` },
      { name: 'Radar Templates', href: `/personalisation/my-radar-templates` },
      { name: 'Preferences', href: `/personalisation/my-preferences` },
      { name: 'Collection Status', href: `/status/match-status/:compId` },
      { name: 'Log Out', href: `/logout` },
    ],
  },
  {
    id: 'admin',
    name: 'Admin',
    icon: <AdminIcon />,
    sections: ['/admin', '/reports'],
    position: 'bottom',
    restrictTo: onlyAdmins,
    links: [
      { name: 'Manage Accounts', href: `/manage-accounts` },
      { name: 'Manage Users', href: `/manage-users` },
      { name: 'Manage Licenses by Account', href: `/manage-licenses/account` },
      { name: 'Manage Licenses by Competition', href: `/manage-licenses/competition` },
      { name: 'Manage Competition Seasons', href: `/manage-cs`, restrictTo: onlyDevAdmins },
      { name: 'Manage Competitions', href: `/manage-competitions` },
      { name: 'Manage Team Colours', href: `/manage-team-colours` },
      { name: 'Activity', href: `/activity-log` },
      { name: 'Stat Distribution', href: `/stat-distribution` },
      { name: 'Hidden Players Report', href: `/player` },
      { name: 'Hidden Teams Report', href: `/team` },
    ],
  },
  {
    id: 'help',
    name: 'Help',
    icon: <SupportIcon />,
    sections: ['/info'],
    position: 'bottom',
    links: [
      { name: 'Tutorials', href: `/tutorials` },
      { name: 'Technical Glossary', href: `/sbdata-glossary` },
      { name: 'Privacy Policy', href: `/privacy-policy` },
      { name: "T&C's", href: `/terms-and-conditions` },
    ],
  },
];

const restrictSections = (loginType: LoginType) => (nav: Nav) =>
  nav.filter(({ restrictTo = () => true }) => restrictTo(loginType));

const restrictLinks = (loginType: LoginType) => (nav: Nav) =>
  nav.map(section => ({
    ...section,
    links: section.links.filter(({ restrictTo = () => true }) => restrictTo(loginType)),
  }));

const tokenReplace = (token: string, value: string) => (nav: Nav) =>
  nav.map(section => ({
    ...section,
    links: section.links.map(({ href, ...rest }) => ({ href: href.replace(token, value), ...rest })),
  }));

export const generateNavigation = (
  nav: Nav,
  loginType: LoginType,
  keeperId: number,
  playerId: number,
  teamId: number,
  matchId: number,
  compId: number
): Nav =>
  pipe(
    restrictSections(loginType),
    restrictLinks(loginType),
    tokenReplace(':keeperId', `${keeperId}`),
    tokenReplace(':playerId', `${playerId}`),
    tokenReplace(':teamId', `${teamId}`),
    tokenReplace(':matchId', `${matchId}`),
    tokenReplace(':compId', `${compId}`)
  )(nav);

export const isSectionSelected = (
  sectionPath: SectionPrefix,
  subpage: string | undefined,
  section: INavSection
): boolean => {
  if (subpage === 'freeze-frame-viewer') return section.id === '360';
  if (section.id === '360') return subpage === 'freeze-frame-viewer';
  return section.sections.some(path => path === sectionPath);
};
