import { styled } from '@statsbomb/react-components';
import { TOP_NAV_HEIGHT } from '../../consts/navigation';
import { ZINDEX } from '../../consts/zIndex';
import { ITheme } from '../../types/ITheme';

export const MobileSubnavWrapper = styled.div<{ scrollingDown: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  width: 100vw;
  z-index: ${ZINDEX.modals[0]};
  position: sticky;
  top: ${TOP_NAV_HEIGHT};
  padding: ${({ theme }: { theme: ITheme }) => theme.spacing()};
  background: ${({ theme }: { theme: ITheme }) => theme.colours.canvas.secondary};
  box-shadow: ${({ theme }: { theme: ITheme }) => theme.effects.dropShadow.regular};

  transition: top 0.6s ease-in-out;
  top: ${({ scrollingDown }: { scrollingDown: boolean }) => (scrollingDown ? '-5rem' : TOP_NAV_HEIGHT)};

  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: none;
  }
`;

export const MatchDetailText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
