import React, { useRef } from 'react';
import { CloseIcon } from '@statsbomb/react-components';
import { Row } from 'react-flexbox-grid';
import { Button } from '../button/Button.styles';
import { IconButton } from '../button/IconButton';
import { DownloadImgButton } from '../downloads/Download';
import { Background, Modal, ModalContent, ModalFooter, ModalHeader, ModalContentWrapper } from './DownloadModal.styles';
import { Card } from '../Card.styles';

export const DownloadModal = ({
  children,
  onClose,
  title,
  isHidden,
  height,
  width,
}: {
  onClose: () => void;
  title: string;
  isHidden: boolean;
  children: React.ReactNode;
  height: number;
  width: number;
}) => {
  const downloadRef = useRef<HTMLDivElement>(null);
  const dimensionSizes = { width, height };

  return (
    <Background data-testid="download-modal-overlay" $isHidden={isHidden} onClick={onClose}>
      <Modal data-testid="download-modal" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <ModalHeader>
          <span>Download Preview</span>
          <IconButton onClick={onClose} icon={<CloseIcon width="24" alt="Close Icon" />} />
        </ModalHeader>
        <ModalContent>
          <ModalContentWrapper>
            <div ref={downloadRef} style={dimensionSizes}>
              <Card size={dimensionSizes} as={Row} between="xs">
                {children}
              </Card>
            </div>
          </ModalContentWrapper>
        </ModalContent>
        <ModalFooter>
          <Button style={{ margin: '1rem' }} $variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <DownloadImgButton ref={downloadRef} title={title} onDownloadComplete={onClose}>
            Download
          </DownloadImgButton>
        </ModalFooter>
      </Modal>
    </Background>
  );
};
