import { atomWithQuery } from 'jotai/query';
import { fetchMany } from '../utils/api';
import { selectedCompSeasonId } from './filters';
import { ITeamSeason } from '../types/team';

export interface ITeamsInLeaguePayload {
  [key: string]: number;
  competitionId: number;
  seasonId: number;
}

export const getTeamsInLeague = atomWithQuery(get => ({
  queryKey: ['getTeamsInLeague', get(selectedCompSeasonId)],
  queryFn: async ({ queryKey: [, compSeasonId] }): Promise<ITeamSeason[]> =>
    fetchMany(`/comp-season/${compSeasonId}/teams`),
}));
