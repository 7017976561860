import React, { ReactElement } from 'react';
import { StyledIconButton } from './IconButton.styles';

interface IconButtonTypes extends React.HTMLAttributes<HTMLButtonElement> {
  icon: ReactElement;
}

export const IconButton = ({ icon, ...props }: IconButtonTypes) => (
  <StyledIconButton type="button" {...props}>
    {icon}
  </StyledIconButton>
);
