import { range } from 'ramda';
import React from 'react';
import { keyText } from '../../../../config/passNetwork/key';
import { ObvXgDataModel, Period } from '../../../../types/passNetwork';
import { Arrow } from './Arrow';
import { KeyText } from './Key.styles';
import KeyElement from './KeyElement';

export const PassVolumeKey = ({
  selectedModel,
  selectedMinPasses,
  colour,
  selectedPeriod,
}: {
  selectedModel: ObvXgDataModel;
  selectedMinPasses: number;
  colour: string;
  selectedPeriod: Period;
}) => {
  const lineTextPasses = keyText[selectedModel].lineText(selectedMinPasses, selectedPeriod);

  const lineY1 = 6.25;
  const lineY2 = 2.5;
  const lineGap = 12;
  const lineX2 = 7.5;
  const stokeWidthDiff = 0.25;

  return (
    <div>
      <KeyElement>
        {range(0, 4).map(index => (
          <line
            x1={index * lineGap}
            x2={lineX2 + index * lineGap}
            y1={lineY1}
            y2={lineY2}
            strokeWidth={0.25 + index * stokeWidthDiff}
            stroke={colour}
          />
        ))}
        <Arrow />
      </KeyElement>
      <KeyText>
        <p>{lineTextPasses.min}</p>
        <p>{lineTextPasses.max}</p>
      </KeyText>
    </div>
  );
};
