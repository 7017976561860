import { sortBy, prop, descend, sort, applySpec, filter } from 'ramda';
import { Dispatch, SetStateAction } from 'react';
import { IStatTableCol } from '../types/matchStats';
import { Period } from '../types/passNetwork';
import { TABLE_SORT_STATES } from '../types/table';

export const formatSortOrder = (col: IStatTableCol, colToSort: IStatTableCol) => {
  if (colToSort?.stat === col?.stat && colToSort.order === TABLE_SORT_STATES.ASC) return TABLE_SORT_STATES.DESC;
  if (colToSort?.stat === col?.stat && colToSort.order === TABLE_SORT_STATES.DEFAULT) return TABLE_SORT_STATES.ASC;
  return TABLE_SORT_STATES.DEFAULT;
};

export const getSortState = (colsToSort: IStatTableCol[] | [], col: IStatTableCol) => {
  if (!colsToSort || colsToSort.length < 1) return col.order;
  const foundCol = colsToSort.find(c => c.stat === col.stat);
  return foundCol?.order;
};

export const sortItems = <T extends { [k: string | number | symbol]: string | number }>(
  key: string,
  order: string,
  data: T[]
): T[] => {
  if (order === TABLE_SORT_STATES.DEFAULT) return data;
  if (order === TABLE_SORT_STATES.DESC) return sort(descend((d: T) => d[key]))(data);
  return sortBy(prop(key))(data);
};

export const updateSortCols = (key: string, cols: IStatTableCol[], col: IStatTableCol) =>
  cols.map(c => {
    if (c[key] === col[key]) return { ...col, order: formatSortOrder(col, c) };
    return c.order ? { ...c, order: TABLE_SORT_STATES.DEFAULT } : c;
  });

export const updateMultiSortCols = (key: string, cols: IStatTableCol[], col: IStatTableCol) =>
  cols.map(c => {
    if (c[key] === col[key]) return { ...col, order: formatSortOrder(col, c) };
    return c;
  });

export const sortStatsData = <T>(colsToSort: IStatTableCol[], data: T) => {
  const orderedCol = colsToSort.find(c => c.order === TABLE_SORT_STATES.DESC || c.order === TABLE_SORT_STATES.ASC);
  return (orderedCol?.sortableFn ? orderedCol?.sortableFn(orderedCol?.order, data) : data) as T;
};

export const multiSortStatsData = <T>(colsToSort: IStatTableCol[], data: T) =>
  colsToSort.reduce(
    (formattedData, col) => (col?.sortableFn ? col?.sortableFn(col?.order, formattedData) : formattedData) as T,
    data
  );

export const groupByPeriod: <T>(arg0: T[]) => {
  firstHalf: T[];
  secondHalf: T[];
} = applySpec({
  firstHalf: filter((p: Record<'periodId', number>) => p.periodId === Period.FIRST),
  secondHalf: filter((p: Record<'periodId', number>) => p.periodId === Period.SECOND),
});

export const paginationPageChange =
  (cb: Dispatch<SetStateAction<number>>) =>
  ({ pageSelected }: { pageSelected: number }) =>
    cb(pageSelected);
