import { Atom } from 'jotai';
import { IHomeAndAwayStats, IMatchStatMeta } from './match';
import { IStatTableCol } from './matchStats';

export const TABLE_SORT_STATES = {
  DEFAULT: 'default',
  DESC: 'desc',
  ASC: 'asc',
} as const;

export interface IHomeAndAwayTablePaginatedProps<T> {
  stats: Atom<IHomeAndAwayStats<T>>;
  columnMetaData: IStatTableCol[];
  field: string | string[];
  meta: IMatchStatMeta;
  itemsPerPage: number;
  siblingCount: number;
  multiSort?: boolean;
  isHeadSticky?: boolean;
  stickyColumns?: { [key: string]: number }[];
}

export interface IHomeAndAwayTableProps<T> {
  stats: Atom<IHomeAndAwayStats<T>>;
  columnMetaData: IStatTableCol[];
  field: string | string[];
  meta: IMatchStatMeta;
  multiSort?: boolean;
  isHeadSticky?: boolean;
  stickyColumns?: { [key: string]: number }[];
}
