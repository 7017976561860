export const resultType = ['player', 'goalkeeper', 'team', 'competition', 'match'] as const;

export type ResultType = typeof resultType[number];

export type SearchResults = Array<ISearchResult>;

export interface ISearchSection {
  id: ResultType;
  title: string[];
  items: ISearchResult[];
}

export interface ISearchResult {
  countryCode: string;
  detail: string | null;
  female: boolean;
  id: number;
  name: string;
  type: ResultType;
}
