import {
  ColourPalette,
  ThemeColourPalette,
  ThemeFontSize,
  StrokeWidths,
  VizTheme,
  ColourTheme,
} from '../types/vizTheme';

/**
 * TODO:
 * Palette property values will need input from
 * jim / kat
 */
const LIGHT_VIZ_PALETTE: ColourPalette = {
  TEXT: {
    DEFAULT: '#333333',
    BORDER: '#f4f4f4',
  },
  PITCH: {
    BACKGROUND: '#FFFFFF',
    LINES: '#666666',
  },
};

const DARK_VIZ_PALETTE: ColourPalette = {
  TEXT: {
    DEFAULT: '#999999',
    BORDER: '#121212',
  },
  PITCH: {
    BACKGROUND: '#121212',
    LINES: '#666666',
  },
};

/**
 * this is so that we can use mode switching like:
 * const theme = VIZ_PALETTE[colourMode];
 */
export const VIZ_PALETTE: ThemeColourPalette = {
  [ColourTheme.LIGHT]: LIGHT_VIZ_PALETTE,
  [ColourTheme.DARK]: DARK_VIZ_PALETTE,
};

/**
 * For these objects below I've given these values I thought were suitable
 * based on the default value. Probably needs to be updated from jim / kat
 */
const LINE_STROKE_WIDTH: StrokeWidths = {
  THIN: 0.25,
  DEFAULT: 0.5,
  THICK: 0.75,
};

const TEXT_STROKE_WIDTH: StrokeWidths = {
  THIN: 0.2,
  DEFAULT: 0.4,
  THICK: 0.6,
};

const FONT_SIZE: ThemeFontSize = {
  SMALL: 2.2,
  DEFAULT: 2.4,
  LARGE: 2.6,
};

export const VIZ_THEME: VizTheme = {
  COLOURS: {
    [ColourTheme.LIGHT]: LIGHT_VIZ_PALETTE,
    [ColourTheme.DARK]: DARK_VIZ_PALETTE,
  },
  TEXT: {
    FONT_SIZE,
  },
  LINES: {
    LINE_STROKE_WIDTH,
    TEXT_STROKE_WIDTH,
  },
};
