import { styled } from '@statsbomb/react-components';
import { ZINDEX } from '../../consts/zIndex';
import { ITheme } from '../../types/ITheme';

export const SideNavigationWrapper = styled.div`
  z-index: ${ZINDEX.navigation[0]};

  > aside {
    height: 100%;

    @media screen and (min-width: 768px) and (min-height: ${({
        $itemLength,
        theme,
      }: {
        $itemLength: number;
        theme: ITheme;
      }) => theme.relativeSize(parseFloat(theme.spacing(6.2)) * $itemLength + 4.5)}) {
      height: calc(100vh - 5.2rem);
    }
  }
`;
