import { atom } from 'jotai';
import { atomWithQuery } from 'jotai/query';
import { QueryFunction, QueryKey } from 'react-query';
import { filter, map, partial, pick, pipe } from 'ramda';
import {
  formatCSVData,
  getPlayedMatchesForTeam,
  tableAsCSV,
  formatColHeaderWithTableTitle,
  removePeriodPenalties,
} from '../utils/match';
import {
  IMatchDetails,
  IMatchStatAway,
  IMatchStatHome,
  IMatchStatMeta,
  IMatchStatResponse,
  IMatchStatResponseItem,
  IMatchStats,
  IMatchSummary,
  isMatchStatHome,
} from '../types/match';
import { fetchMany, fetchOne } from '../utils/api';
import {
  IPressure,
  IMatchPressure,
  IMatchPlayerShotsAndPassesStats,
  IFormation,
  IShot,
  IPlayerStat,
  TableStats,
  IStatTableCol,
  IPassesAndTouchesStat,
  IXgChainStat,
  IDefensiveActionsStat,
  IObvStat,
} from '../types/matchStats';
import {
  formatShotsAndPassesTable,
  formatPressuresTable,
  isCounterPressure,
  formatSubstitutions,
  splitHomeAway,
  formatTable,
  getPlayerDataPassesAndTouches,
  getXgChain,
  getDefensiveActionStats,
  getObvStats,
} from '../utils/matchStats';
import { matchId as matchIdAtom, selectedCompSeasonId, selectedTeamId } from './filters';
import { shotsAndPasses } from '../config/statsTable/shotsAndPasses';
import { defensiveActions } from '../config/statsTable/defensiveActions';
import { xgChain } from '../config/statsTable/xgChain';
import { passingAndTouches } from '../config/statsTable/passingAndTouches';
import { pressuresAndCounterPressures } from '../config/statsTable/pressures';
import { obv } from '../config/statsTable/obv';
import { ISubstitution, ISubstitutionFormatted } from '../types/substitutions';
import { IObvXgChain } from '../types/passNetwork';
import { matchPeriodQueryString } from './passNetwork';
import { hyphenDateTimeFromTZ } from '../utils/general';

export const matchFetchMany = (path: string) =>
  (async <T extends any>({ queryKey: [, matchId] }: { queryKey: unknown[] }): Promise<T[] | null> => {
    if (!matchId) return null;
    return (await fetchMany(`/match/${matchId}${path}`)) as T[];
  }) as QueryFunction<unknown | null, QueryKey>;

export const statsQueryFn = (async ({
  queryKey: [, matchId],
}: {
  queryKey: unknown[];
}): Promise<IMatchStats | null> => {
  if (!matchId) return null;

  const [statA, statB] = (await fetchMany(`/match/${matchId}/stats`)) as IMatchStatResponse;
  if (!statA || !statB) return null;

  const homeStats = isMatchStatHome(statA) ? statA : statB;
  const awayStats = isMatchStatHome(statB) ? statA : statB;
  const totalPasses = homeStats.passes + awayStats.passes;

  const staticStatKeys = [
    'challenges',
    'homeTeam',
    'passes',
    'pressureRegains',
    'pressures',
    'redCards',
    'secondYellowCards',
    'shots',
    'shotsOnTarget',
    'successfulPasses',
    'tackles',
    'xg',
    'xgConditional',
    'yellowCards',
  ] as const;

  const staticHomeStats: Pick<IMatchStatResponseItem, typeof staticStatKeys[number]> = pick(
    staticStatKeys as readonly (keyof IMatchStatResponseItem)[],
    homeStats
  );

  const staticAwayStats: Pick<IMatchStatResponseItem, typeof staticStatKeys[number]> = pick(
    staticStatKeys as readonly (keyof IMatchStatResponseItem)[],
    awayStats
  );

  const home: IMatchStatHome = {
    ...staticHomeStats,
    homeTeam: true,
    passRatio: (homeStats.passes > 0 ? homeStats.successfulPasses / homeStats.passes : 0) * 100,
    penaltyScore: statA.matchHomePenaltyScore || 0,
    posessionRatio: (totalPasses > 0 ? homeStats.passes / totalPasses : 0) * 100,
    redAndSecondYellowCards: `${homeStats.redCards} / ${homeStats.secondYellowCards}`,
    score: statA.matchHomeScore,
    tacklesWon: `${homeStats.tackles} (${homeStats.tackles + homeStats.challenges})`,
    teamId: statA.matchHomeTeamId,
    teamName: statA.homeTeamName,
    totalPasses: `${homeStats.passes} (${homeStats.successfulPasses})`,
  };

  const away: IMatchStatAway = {
    ...staticAwayStats,
    homeTeam: false,
    passRatio: (awayStats.passes > 0 ? awayStats.successfulPasses / awayStats.passes : 0) * 100,
    penaltyScore: statA.matchAwayPenaltyScore || 0,
    posessionRatio: 100 - home.posessionRatio,
    redAndSecondYellowCards: `${awayStats.redCards} / ${awayStats.secondYellowCards}`,
    score: statA.matchAwayScore,
    tacklesWon: `${awayStats.tackles} (${awayStats.tackles + awayStats.challenges})`,
    teamId: statA.matchAwayTeamId,
    teamName: statA.awayTeamName,
    totalPasses: `${awayStats.passes} (${awayStats.successfulPasses})`,
  };

  const meta = pick(
    [
      'awayTeamName',
      'homeTeamName',
      'matchAwayTeamId',
      'matchDate',
      'matchHomeTeamId',
      'matchId',
      'hasExtraTime',
      'hasPenaltyShootout',
    ],
    statA
  ) as IMatchStatMeta;

  return {
    home,
    away,
    meta,
  };
}) as QueryFunction<IMatchStats | null, QueryKey>;

export const detailsQueryFn = (async ({
  queryKey: [, matchId],
}: {
  queryKey: unknown[];
}): Promise<IMatchDetails | null> => {
  if (!matchId) return null;
  return (await fetchOne(`/match/${matchId}/detail`)) as IMatchDetails;
}) as QueryFunction<IMatchDetails | null, QueryKey>;

export const matchesForCSQuery = (async ({ queryKey: [, compSeasonId] }): Promise<IMatchSummary[]> => {
  if (!compSeasonId) return [];
  return (await fetchMany(`/comp-season/${compSeasonId}/matches`)) as IMatchSummary[];
}) as QueryFunction<IMatchSummary[], QueryKey>;

export const stats = atomWithQuery(get => ({
  queryKey: ['matchStats', get(matchIdAtom)],
  queryFn: statsQueryFn,
}));

export const details = atomWithQuery(get => ({
  queryKey: ['matchDetails', get(matchIdAtom)],
  queryFn: detailsQueryFn,
}));

export const matchPlayerStatsAtom = atomWithQuery(get => ({
  queryKey: ['matchPlayerStats', get(matchIdAtom)],
  queryFn: matchFetchMany('/player-stats'),
}));

export const matchFormationsAtom = atomWithQuery(get => ({
  queryKey: ['matchFormations', get(matchIdAtom)],
  queryFn: matchFetchMany('/formations') as QueryFunction<IFormation[]>,
}));

export const matchShotsAtom = atomWithQuery(get => ({
  queryKey: ['matchShots', get(matchIdAtom)],
  queryFn: matchFetchMany('/shots') as QueryFunction<IShot[]>,
}));

export const matchesForCompSeason = atomWithQuery(get => ({
  queryKey: ['matchesForCS', get(selectedCompSeasonId)],
  queryFn: matchesForCSQuery,
}));

export const matchPressures = atomWithQuery(get => ({
  queryKey: ['matchPlayerPressures', get(matchIdAtom)],
  queryFn: matchFetchMany('/pressures') as QueryFunction<IPressure[]>,
}));

export const passNetworkObvXgChain = atomWithQuery(get => ({
  queryKey: [`passNetworkObvXgChain-${get(matchPeriodQueryString)}`, get(matchIdAtom)],
  queryFn: matchFetchMany(`/pass-network?period=${get(matchPeriodQueryString)}`) as QueryFunction<IObvXgChain[]>,
}));

export const matchPlayerObvXgChainAtom = atomWithQuery(get => ({
  queryKey: ['matchPlayerObvXgChain', get(matchIdAtom)],
  queryFn: matchFetchMany('/player-stats-xgchain-obv'),
}));

const matchStatsResponseAtom = atom(get => {
  const matchDetails = get(details);
  const statsResponse = get(matchPlayerStatsAtom);
  const statsResponseXGObv = get(matchPlayerObvXgChainAtom);

  const { matchHomeTeamId, matchAwayTeamId, homeTeamName, awayTeamName } = matchDetails as IMatchDetails;
  return {
    details: matchDetails,
    statsResponse,
    statsResponseXGObv,
    homeTeamId: matchHomeTeamId,
    awayTeamId: matchAwayTeamId,
    homeTeamName,
    awayTeamName,
  };
});

export const passingAndTouchesAtom = atom(get => {
  const { statsResponse, homeTeamId, awayTeamId, homeTeamName, awayTeamName } = get(matchStatsResponseAtom);
  const tableFormat = formatTable(
    getPlayerDataPassesAndTouches,
    homeTeamId,
    awayTeamId,
    homeTeamName,
    awayTeamName
  )(statsResponse) as IPassesAndTouchesStat[];

  return splitHomeAway<IPassesAndTouchesStat>(homeTeamId, awayTeamId, homeTeamName, awayTeamName, tableFormat);
});

export const xgChainAtom = atom(get => {
  const { statsResponseXGObv, homeTeamId, awayTeamId, homeTeamName, awayTeamName } = get(matchStatsResponseAtom);
  const tableFormat = formatTable(
    getXgChain,
    homeTeamId,
    awayTeamId,
    homeTeamName,
    awayTeamName,
    'xgChain'
  )(statsResponseXGObv) as IXgChainStat[];

  return splitHomeAway<IXgChainStat>(homeTeamId, awayTeamId, homeTeamName, awayTeamName, tableFormat);
});

export const defensiveActionsAtom = atom(get => {
  const { statsResponse, homeTeamId, awayTeamId, homeTeamName, awayTeamName } = get(matchStatsResponseAtom);
  const tableFormat = formatTable(
    getDefensiveActionStats,
    homeTeamId,
    awayTeamId,
    homeTeamName,
    awayTeamName
  )(statsResponse) as IDefensiveActionsStat[];

  return splitHomeAway<IDefensiveActionsStat>(homeTeamId, awayTeamId, homeTeamName, awayTeamName, tableFormat);
});

export const obvAtom = atom(get => {
  const { statsResponseXGObv, homeTeamId, awayTeamId, homeTeamName, awayTeamName } = get(matchStatsResponseAtom);
  const tableFormat = formatTable(
    getObvStats,
    homeTeamId,
    awayTeamId,
    homeTeamName,
    awayTeamName,
    'obv'
  )(statsResponseXGObv) as IObvStat[];

  return splitHomeAway<IObvStat>(homeTeamId, awayTeamId, homeTeamName, awayTeamName, tableFormat);
});

export const matchShotsAndKeyPassesAtom = atom(get => {
  const matchDetails = get(details);
  const shots = get(matchShotsAtom).filter(removePeriodPenalties);
  const formations = get(matchFormationsAtom);
  const { matchHomeTeamId, matchAwayTeamId, homeTeamName, awayTeamName } = matchDetails as IMatchDetails;
  const shotsAndPassesData = formatShotsAndPassesTable(
    matchHomeTeamId,
    matchAwayTeamId,
    homeTeamName,
    awayTeamName,
    shots
  )(formations);

  return shotsAndPassesData as IMatchPlayerShotsAndPassesStats;
});

export const matchesForTeam = atom(get => getPlayedMatchesForTeam(get(selectedTeamId))(get(matchesForCompSeason)));

export const pressuresAtom = atom(get => {
  const pressureResponse = get(matchPressures);
  const { homeTeamId, awayTeamId, homeTeamName, awayTeamName } = get(matchStatsResponseAtom);
  const formations = get(matchFormationsAtom);

  return formatPressuresTable(
    homeTeamId,
    awayTeamId,
    homeTeamName,
    awayTeamName,
    pressureResponse
  )(formations) as IMatchPressure;
});

export const counterPressuresAtom = atom(get => {
  const pressureResponse = get(matchPressures);
  const { homeTeamId, awayTeamId, homeTeamName, awayTeamName } = get(matchStatsResponseAtom);
  const formations = get(matchFormationsAtom);

  return pipe(
    filter(isCounterPressure),
    partial(formatPressuresTable, [homeTeamId, awayTeamId, homeTeamName, awayTeamName])
  )(pressureResponse)(formations) as IMatchPressure;
});

export const allMatchStatsCSVData = atom(get => {
  const tableStats: TableStats<IPlayerStat>[] = [
    [get(matchShotsAndKeyPassesAtom), shotsAndPasses],
    [get(passingAndTouchesAtom), passingAndTouches],
    [get(defensiveActionsAtom), defensiveActions],
    [get(xgChainAtom), xgChain],
    [get(obvAtom), obv],
    [
      get(pressuresAtom),
      formatColHeaderWithTableTitle('Pressures', ['Player', 'Pressures'])(
        pressuresAndCounterPressures
      ) as IStatTableCol[],
    ],
    [
      get(counterPressuresAtom),
      formatColHeaderWithTableTitle('Counterpressures', ['Player'])(pressuresAndCounterPressures) as IStatTableCol[],
    ],
  ];

  const CSVData = formatCSVData('playerId', tableStats);
  return tableAsCSV('playerId')(CSVData);
});

export const matchSubstitutionsAtom = atomWithQuery(get => ({
  queryKey: ['matchSubstitutions', get(matchIdAtom)],
  queryFn: matchFetchMany('/substitutions') as QueryFunction<ISubstitution[]>,
}));

export const substitutionsAtom = atom(get => {
  const substitutions = get(matchSubstitutionsAtom);
  const { matchHomeTeamId, matchAwayTeamId, homeTeamName, awayTeamName } = get(details) as IMatchDetails;

  return pipe(map(formatSubstitutions), data =>
    splitHomeAway<ISubstitutionFormatted>(matchHomeTeamId, matchAwayTeamId, homeTeamName, awayTeamName, data)
  )(substitutions);
});

export const matchDownloadVsFileName = atom(get => {
  const { homeTeamName, awayTeamName, matchDate } = get(details) as IMatchDetails;
  return `${homeTeamName} vs ${awayTeamName} (${hyphenDateTimeFromTZ(matchDate)})`;
});
