/**
 * z-index values should only come from one of these values.
 * Read the z-index policy: https://www.notion.so/statsbomb/z-index-Management-2ca4b020c7334b04a0f2d687477a01bc
 */

export const ZINDEX = {
  negatives: [-32, -64, -96, -128],
  bodyContent: [0, 32, 64, 96],
  modals: [128, 160, 192, 224],
  navigation: [256, 288, 320, 352],
  overlays: [384, 416, 448, 480],
} as const;
