import {
  MAXIMUM_PASS_COMBINATIONS,
  MAX_OBV,
  MAX_PASSES,
  MAX_TOUCHES,
  MAX_XG,
  MIN_OBV,
  MIN_PASSES,
  MIN_TOUCHES,
  MIN_XG,
} from '../../consts/passNetwork';
import { ObvXgKeyLookup, Period } from '../../types/passNetwork';
import { periodDivisor } from '../../components/viz/partial/pitch/utils';
import { formatNumberToDP } from '../../utils/number';

const lineText = (selectedMinPasses: Number, selectedPeriod: Period) => ({
  min: `${selectedMinPasses} ${selectedMinPasses === 1 ? 'pass' : 'passes'}`,
  max: `${formatNumberToDP(periodDivisor(selectedPeriod, MAXIMUM_PASS_COMBINATIONS), 0)}+ passes`,
});

export const keyText: ObvXgKeyLookup = {
  obv: {
    circleSizeText: (selectedPeriod: Period) => ({
      min: `${MIN_PASSES} pass`,
      /** this is formatted to 0dp as we don't have fractions of a pass */
      max: `${formatNumberToDP(periodDivisor(selectedPeriod, MAX_PASSES), 0)}+ passes`,
    }),
    lineText,
    circleColourText: { min: `${MIN_OBV} OBV`, max: `${MAX_OBV}+ OBV` },
  },
  xg: {
    circleSizeText: (selectedPeriod: Period) => ({
      min: `${MIN_TOUCHES} touch`,
      /** this is formatted to 0dp as we don't have fractions of a touch */
      max: `${formatNumberToDP(periodDivisor(selectedPeriod, MAX_TOUCHES), 0)}+ touches`,
    }),
    lineText,
    circleColourText: { min: `${MIN_XG} xG`, max: `${MAX_XG}+ xG` },
  },
};
