import { styled } from '@statsbomb/react-components';
import { EXPANDED_NAV_WIDTH, NAV_WIDTH } from '../../consts/navigation';
import { ITheme } from '../../types/ITheme';

export const Section = styled.main(
  ({
    theme,
    isMenuOpen,
    isDesktopMenuExpanded,
  }: {
    theme: ITheme;
    isMenuOpen: boolean;
    isDesktopMenuExpanded: boolean;
  }) => ({
    display: isMenuOpen ? 'none' : 'initial',
    transition: 'margin 0.4s ease-in-out',
    flex: 1,
    padding: [theme.spacing(1.6), 0].join(' '),
    width: isDesktopMenuExpanded ? `calc(100% - ${EXPANDED_NAV_WIDTH})` : `calc(100vw - ${NAV_WIDTH})`,
  })
);
