import { styled } from '@statsbomb/react-components';
import { keyframes, css } from 'styled-components';
import { BodyText1 } from '../typography/Typography.styles';

const logoSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const topLogoSpin = (animationPercentageOffset: number) => keyframes`
  ${animationPercentageOffset}% {
    transform: rotate(0deg);
  }
  ${20 + animationPercentageOffset}% {
    transform: rotate(180deg);
  }
  ${40 + animationPercentageOffset}% {
    transform: rotate(180deg);
  }
  ${55 + animationPercentageOffset}% {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const bottomLogoSpin = (animationPercentageOffset: number) => keyframes`
  ${50 + animationPercentageOffset}% {
    transform: rotate(0deg);
  }
  ${60 + animationPercentageOffset}% {
    transform: rotate(180deg);
  }
  ${75 + animationPercentageOffset}% {
    transform: rotate(180deg);
  }
  ${90 + animationPercentageOffset}% {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const standardAnimationOptions = 'infinite 2s ease-in-out';

export const SpinnerContainer = styled.div`
  rotate: 315deg;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${logoSpin} ${standardAnimationOptions};
  }
  height: 150px;
  width: 150px;
  position: relative;
`;

export const SpinnerSectionContainer = styled.div`
  height: 50%;
  clip-path: inset(0 0 0 0);
`;

const topSpinnerSectionAnimation = (props: { animationPercentageOffset: number }) =>
  // prettier-ignore
  css`${topLogoSpin(props.animationPercentageOffset)} ${standardAnimationOptions}`;

const bottomSpinnerSectionAnimation = (props: { animationPercentageOffset: number }) =>
  // prettier-ignore
  css`${bottomLogoSpin(props.animationPercentageOffset)} ${standardAnimationOptions}`;

export const TopSpinnerRotation = styled.div`
  position: absolute;
  clip-path: inset(0 0 50% 0);
  @media (prefers-reduced-motion: no-preference) {
    animation: ${topSpinnerSectionAnimation};
  }
`;

export const BottomSpinnerRotation = styled.div`
  margin-top: -50%;
  position: absolute;
  clip-path: inset(50% 0 0 0);
  @media (prefers-reduced-motion: no-preference) {
    animation: ${bottomSpinnerSectionAnimation};
  }
`;

export const LoadingMessage = styled(BodyText1)`
  height: 0px;
  @media (prefers-reduced-motion: no-preference) {
    visibility: hidden;
  }
`;
