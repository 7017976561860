import { ReactElement } from 'react';
import { LoginType } from './IUser';

const sectionIds = [
  'keeper',
  'player',
  'team',
  'match',
  'league',
  'tactics',
  'scout',
  '360',
  'account',
  'admin',
  'help',
] as const;

// Added 360 for now as it won't display match subnav
export const sectionPrefixes = [
  '/keeper',
  '/player',
  '/team',
  '/match',
  '/competition',
  '/engine-room',
  '/scout',
  '/match',
  '/personalisation',
  '/status',
  '/admin',
  '/reports',
  '/info',
] as const;

export type SectionPrefix = typeof sectionPrefixes[number];

export interface INavSection {
  id: typeof sectionIds[number];
  name: string;
  sections: Array<typeof sectionPrefixes[number]>;
  links: Array<ILink>;
  position: 'top' | 'bottom';
  restrictTo?: (loginType: LoginType) => boolean;
  icon: ReactElement;
}

export type Nav = INavSection[];

export interface ILink {
  name: string;
  href: string;
  restrictTo?: (loginType: LoginType) => boolean;
}
