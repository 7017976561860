import { useEffect, useState } from 'react';
import { IStatTableCol } from '../types/matchStats';
import { ObvXgDataModel } from '../types/passNetwork';
import { multiSortStatsData, sortStatsData, updateMultiSortCols, updateSortCols } from '../utils/table';

export const useStatTableSort = <T,>(
  multiSort: boolean,
  columnMetaData: IStatTableCol[],
  data: T[],
  modelToHide: ObvXgDataModel | null
) => {
  const [colsToSort, setColToSort] = useState(columnMetaData);

  useEffect(() => {
    const colsToSortFormatted = columnMetaData.filter(m => m.stat !== modelToHide);
    setColToSort(colsToSortFormatted);
  }, [modelToHide]);

  const dataFormatted = multiSort ? multiSortStatsData<T[]>(colsToSort, data) : sortStatsData<T[]>(colsToSort, data);

  const sortCb = (col: IStatTableCol) => {
    if (!col?.sortableFn) return null;
    if (multiSort) return () => setColToSort(updateMultiSortCols('stat', colsToSort, col));
    return () => setColToSort(updateSortCols('stat', colsToSort, col));
  };

  return [dataFormatted, colsToSort, sortCb] as [T[], IStatTableCol[], (arg0: IStatTableCol) => T[] | null];
};
