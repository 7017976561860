import React from 'react';
import { useAtom } from 'jotai';
import { Number } from '@statsbomb/react-components';
import ModelFilter from './ModelFilters';
import PassFilters from './PassFilters';
import { Title } from './ModelFilters.styles';
import { minimumPasses } from '../../atoms/passNetwork';
import { Filter, FilterGroup } from './FilterWrapper.styles';

const ChartSettingsFilters = () => {
  const [minPasses, setMinPasses] = useAtom(minimumPasses);
  const passesChangeHandler = ({ newValue }: { newValue: number }) => setMinPasses(newValue);

  // Temporarily removed the Player Position and Min Time on Pitch filters.
  // Leaving this commented for now assuming it will be re-added in future.
  // If not, please remove.
  //
  // const [minMinutes, setMinMinutes] = useAtom(minimumMinutes);
  // const minutesChangeHandler = ({ newValue }: { newValue: number }) => setMinMinutes(newValue);

  return (
    <Filter>
      <FilterGroup>
        <ModelFilter />
      </FilterGroup>
      <FilterGroup>
        <Title>Metrics displayed</Title>
        <Number
          id="minimum-passes"
          inputDigits={2}
          label="Minimum passes to display"
          onChange={passesChangeHandler}
          value={minPasses}
          min={0}
        />
        <PassFilters />
      </FilterGroup>
      {/* <FilterGroup>
        <Title>Minimum Time on Pitch</Title>
        <Number
          id="minimum-time-on-pitch"
          inputDigits={2}
          label="Minutes"
          onChange={minutesChangeHandler}
          value={minMinutes}
          min={0}
        />
      </FilterGroup>
      <FilterGroup>
        <PlayerPositions />
      </FilterGroup> */}
    </Filter>
  );
};

export default ChartSettingsFilters;
