import { styled } from '@statsbomb/react-components';
import { GAP_BETWEEN_COMPONENTS, INNER_GROUP_GAP, OUTER_GROUP_GAP } from '../../consts/spacing';
import { ITheme } from '../../types/ITheme';

export const DesktopFilterWrapper = styled.div`
  display: none;
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: flex;

    &:not(:first-of-type) {
      margin-top: ${GAP_BETWEEN_COMPONENTS};
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${OUTER_GROUP_GAP};
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${INNER_GROUP_GAP};
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.body1.size};
`;
