import {
  STROKE_MIN,
  STROKE_MAX,
  STROKE_FACTOR,
  REGULAR_PERIOD,
  EXTRA_TIME_PERIOD,
  FULL_MATCH,
} from '../../../../consts/passNetwork';
import { Period } from '../../../../types/passNetwork';
import { Scale } from '../../../../types/viz';
import { chromaValue } from '../../../../utils/viz/scales';

export const numClamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

export const valueWithinNewRange = (value: number, oldMin: number, oldMax: number, newMin: number, newMax: number) => {
  const numClamped = numClamp(value, oldMin, oldMax);
  const oldRangeRatio = (numClamped - oldMin) / (oldMax - oldMin);
  const newValue = (newMax - newMin) * oldRangeRatio + newMin;

  return Number(newValue.toFixed(2));
};

const periodFraction = (period: Period) => {
  switch (period) {
    case Period.FIRST:
    case Period.SECOND:
    case Period.FIRST_VS_SECOND:
      return REGULAR_PERIOD;
    case Period.THIRD:
    case Period.FOURTH:
      return EXTRA_TIME_PERIOD;
    default:
      return FULL_MATCH;
  }
};

// These may appear to be the wrong way round, but they're correct - periodFraction gives you back
// the fraction of the match for a given period.
// For match data, we want to "stretch" the range, so we must divide by the periodFraction.
// For showing the key information, we want to shrink the range, so we must multiply by the periodFraction.
export const periodMultiplier = (period: Period, value: number) => value / periodFraction(period);
export const periodDivisor = (period: Period, value: number) => value * periodFraction(period);

export const getPitchAttributes = (pitchLineColour: string, pitchBackground: string) => ({
  fill: pitchBackground,
  stroke: pitchLineColour,
  strokeWidth: 2,
  vectorEffect: 'non-scaling-stroke',
});

// Group strokes into whole-number buckets from STROKE_MIN to STROKE_MAX
export const calcStrokeWidthBuckets = (value: number, min: number, max: number) =>
  Math.round(valueWithinNewRange(value, min, max, STROKE_MIN, STROKE_MAX)) / STROKE_FACTOR;

export const calcStrokeColour = (scale: Scale, modelValue: number, min: number, max: number) =>
  chromaValue(scale, valueWithinNewRange(modelValue, min, max, 0, 1));
