export const MAX_PASSES = 100;
export const MAX_TOUCHES = 250;
export const MAX_XG = 1.5;
export const MIN_XG = 0.16;
export const MAX_OBV = 0.23;
export const MIN_OBV = -0.05;
export const MAX_COMBI_OBV = 0.16;
export const MIN_COMBI_OBV = 0.08;
export const MAX_COMBI_XG = 0.65;
export const MIN_COMBI_XG = 0;
export const MIN_PASSES = 1;
export const MIN_TOUCHES = 1;
export const MIN_RADIUS = 2.5;
export const MAX_RADIUS = 8;
export const DEFAULT_RADIUS = 4;
export const IQ_TO_SBD_X = 1.2;
export const IQ_TO_SBD_Y = 0.8;
export const MINIMUM_PASS_COMBINATIONS = 1;
export const MAXIMUM_PASS_COMBINATIONS = 40;
export const STROKE_MIN = 1;
export const STROKE_NEW_MAX = 1.25;
export const STROKE_MAX = 4;
export const STROKE_FACTOR = 5; // arbitrary number that made the stroke look good.

export const DEFAULT_OPACITY = 0.3;

// ie the number of 90s
export const FULL_MATCH = 1;
// ie 90 / 2
export const REGULAR_PERIOD = FULL_MATCH / 2;
// ie 90 / 6 (15m)
export const EXTRA_TIME_PERIOD = FULL_MATCH / 6;

export const ALL_SELECTED = {
  PASS_VOLUME_PER_PLAYER: 0,
  PASS_VOLUME: 48,
  PASS_OBV_XG_PER_PLAYER: 95,
};

export const PASS_VOLUME_PER_PLAYER_AND_PASS_VOLUME_SELECTED = {
  PASS_VOLUME_PER_PLAYER: 25,
  PASS_VOLUME: 80,
  PASS_OBV_XG_PER_PLAYER: 0,
};

export const PASS_VOLUME_PER_PLAYER_AND_PASS_OBV_XG_PER_PLAYER_SELECTED = {
  PASS_VOLUME_PER_PLAYER: 25,
  PASS_VOLUME: 0,
  PASS_OBV_XG_PER_PLAYER: 80,
};

export const PASS_VOLUME_AND_PASS_OBV_XG_PER_PLAYER_SELECTED = {
  PASS_VOLUME_PER_PLAYER: 0,
  PASS_VOLUME: 25,
  PASS_OBV_XG_PER_PLAYER: 75,
};

export const ONE_KEY_SELECTED = {
  PASS_VOLUME_PER_PLAYER: 51,
  PASS_VOLUME: 52,
  PASS_OBV_XG_PER_PLAYER: 47.5,
};

export const TOOLTIP_OFFSET_LEFT = 20;
export const TOOLTIP_OFFSET_RIGHT = 170;
export const TOOLTIP_OFFSET_TOP = 20;
