import { styled } from '@statsbomb/react-components';
import { TOP_NAV_HEIGHT } from '../../consts/navigation';
import { ZINDEX } from '../../consts/zIndex';
import { ITheme } from '../../types/ITheme';

export const SearchModal = styled.aside`
  z-index: ${ZINDEX.navigation[0]};
  position: fixed;
  top: ${TOP_NAV_HEIGHT};
  left: 0;
  right: 0;
  bottom: 0;
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    z-index: ${ZINDEX.navigation[0]};
  }
`;

export const SearchResults = styled.ul`
  position: fixed;
  top: ${TOP_NAV_HEIGHT};
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: ${({ theme }: { theme: ITheme }) => theme.colours.canvas.secondary};
  z-index: ${ZINDEX.navigation[0]};

  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    z-index: ${ZINDEX.navigation[0]};
    left: initial;
    bottom: initial;
    max-height: 39rem;
    height: auto;
    width: 60rem;
    box-shadow: ${({ theme }: { theme: ITheme }) => theme.effects.dropShadow.regular};
  }
`;

export const SearchResultItem = styled.a`
  display: block;
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.body1.size};
  padding: ${({ theme }: { theme: ITheme }) => [theme.spacing(0.25), theme.spacing(2)].join(' ')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-color: ${({ theme }: { theme: ITheme }) => theme.colours.canvas.primary};
  }
`;

export const SearchResultTitle = styled.h3(({ theme, $empty }: { theme: ITheme; $empty: boolean }) => ({
  display: 'block',
  fontSize: theme.typography.body1.size,
  color: theme.colours.ink.inverse.main,
  backgroundColor: $empty ? theme.colours.brand.interface.main : theme.colours.graphics.secondary.dark,
  padding: [theme.spacing(0.25), theme.spacing(2)].join(' '),
}));
