import React from 'react';
import { useAtomValue } from 'jotai/utils';
import {
  displaySettingPitchColourMode,
  gameFilterGameTime,
  minimumPasses,
  passesFilters,
  selectedDataModel,
} from '../../../../atoms/passNetwork';
import {
  PASSES_BETWEEN_PLAYERS,
  PASSES_VOLUME,
  PASS_VOLUME_PER_PLAYER,
  PASS_XG_OBV_PER_PLAYER,
} from '../../../../types/passNetwork';
import { PassVolumeKey } from './PassVolume';
import { CircleSizeKey } from './CircleSize';
import { ObvXgKey } from './ObvXgKey';
import { VIZ_THEME } from '../../../../consts/vizTheme';
import { scale } from '../../../../atoms/preferences';
import { KeyWrapper } from './Key.styles';

const PassNetworkKey = () => {
  const selectedMinPasses = useAtomValue(minimumPasses);
  const selectedModel = useAtomValue(selectedDataModel).value;
  const selectedPeriod = useAtomValue(gameFilterGameTime);
  const passFilters = useAtomValue(passesFilters);
  const selectedScale = useAtomValue(scale);
  const selectedPitchColourMode = useAtomValue(displaySettingPitchColourMode);

  const isPassBetweenPlayersSelected = passFilters[PASSES_BETWEEN_PLAYERS].selected;
  const isPassVolumeSelected = passFilters[PASSES_BETWEEN_PLAYERS].children[PASSES_VOLUME].selected;
  const isPassVolumePerPlayerSelected = passFilters[PASS_VOLUME_PER_PLAYER].selected;
  const isPassObvXgPerPlayerSelected = passFilters[PASS_XG_OBV_PER_PLAYER].selected;
  const isPassVolumeAndBetweenPlayersSelected = isPassVolumeSelected && isPassBetweenPlayersSelected;

  const { LINES, COLOURS } = VIZ_THEME;

  const colourThemeText = COLOURS[selectedPitchColourMode].TEXT.DEFAULT;

  return (
    <KeyWrapper>
      {isPassVolumePerPlayerSelected && (
        <CircleSizeKey selectedModel={selectedModel} selectedPeriod={selectedPeriod} colour={colourThemeText} />
      )}
      {isPassVolumeAndBetweenPlayersSelected && (
        <PassVolumeKey
          selectedModel={selectedModel}
          selectedMinPasses={selectedMinPasses}
          colour={colourThemeText}
          selectedPeriod={selectedPeriod}
        />
      )}
      {isPassObvXgPerPlayerSelected && (
        <ObvXgKey
          selectedModel={selectedModel}
          selectedScale={selectedScale}
          strokeLineWidth={LINES.LINE_STROKE_WIDTH.DEFAULT}
        />
      )}
    </KeyWrapper>
  );
};

export default PassNetworkKey;
