import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

export const Title = styled.h1`
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.heading1.size};
  font-weight: ${({ theme }: { theme: ITheme }) => theme.typography.heading1.weight};
`;

// TODO: Make reusable, pass in component similar to sidenav a/link
export const BodyText1 = styled.p`
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.body1.size};
  font-weight: ${({ theme }: { theme: ITheme }) => theme.typography.body1.weight};
`;

export const BodyText2 = styled.p`
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.body2.size};
  font-weight: ${({ theme }: { theme: ITheme }) => theme.typography.body2.weight};
`;

export const BoldSpan = styled.span`
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.body2.size};
  font-weight: ${({ theme, $isBold }: { theme: ITheme; $isBold: boolean }) =>
    $isBold ? theme.typography.body1.weight : theme.typography.body2.weight};
`;
