import { equals, groupBy, map, pipe, reverse, sortBy } from 'ramda';
import { chromaValue } from './viz/scales';

import {
  valueWithinNewRange,
  periodMultiplier,
  calcStrokeWidthBuckets,
  calcStrokeColour,
  periodDivisor,
} from '../components/viz/partial/pitch/utils';
import {
  MIN_RADIUS,
  MAX_RADIUS,
  IQ_TO_SBD_X,
  IQ_TO_SBD_Y,
  MAX_PASSES,
  MAX_TOUCHES,
  DEFAULT_RADIUS,
  MINIMUM_PASS_COMBINATIONS,
  MAXIMUM_PASS_COMBINATIONS,
  REGULAR_PERIOD,
  EXTRA_TIME_PERIOD,
} from '../consts/passNetwork';
import { COMBINATION_LINE_OFFSET, RADIUS_OFFSET_PASSER, RADIUS_OFFSET_RECEIVER } from '../consts/viz';
import { IPass } from '../types/passes';
import {
  ByPeriod,
  IMergedPassAndCombinations,
  IObvXgChain,
  IObvXgChainFormatted,
  IPassesFilter,
  IPassNetworkVizPassCombinations,
  IPassNetworkVizPasses,
  ModelDataByPeriod,
  ObvXgDataModel,
  obvXgDataModels,
  obxXgAssociatedAttr,
  PASSES_BETWEEN_PLAYERS,
  PASSES_VOLUME,
  PASS_XG_OBV_BETWEEN_PLAYERS,
  Period,
  PeriodName,
  PitchTitleByPeriod,
  TeamLookup,
  VizPassesWithUniqueValue,
} from '../types/passNetwork';
import { ITeamNameIndex } from '../types/team';
import { Line, Scale, VizCircle, VizText, VizTooltipPlayer, VizTooltipPlayerWithPeriodId } from '../types/viz';
import { splitHomeAway } from './matchStats';
import { circlesOverlap, distance, offsetLineEnd, rotate, shortenLine } from './viz/drawing';
import { MATCH_PERIODS } from '../consts/match';

export const formatPassNetworkObvXgChain = (
  homeTeamId: number,
  awayTeamId: number,
  homeTeamName: string,
  awayTeamName: string,
  selectedDataModel: ObvXgDataModel
) =>
  pipe(
    map(({ obvPass, ...rest }: IObvXgChain) => ({ ...rest, obv: obvPass })),
    sortBy(data => data[selectedDataModel]),
    data => reverse(data),
    data => splitHomeAway<IObvXgChainFormatted>(homeTeamId, awayTeamId, homeTeamName, awayTeamName, data)
  );

// JTD: No changes have been made to this file,
// but the test fails on the 2 sortBy fns
/* istanbul ignore next */
export const formatPassCombinations = (
  homeTeamId: number,
  awayTeamId: number,
  homeTeamName: string,
  awayTeamName: string,
  selectedDataModel: ObvXgDataModel
) =>
  pipe(
    sortBy((data: IPass) => data[selectedDataModel]),
    sortBy(data => data.passes),
    data => reverse(data),
    data => splitHomeAway<IPass>(homeTeamId, awayTeamId, homeTeamName, awayTeamName, data)
  );

export const isDuringRegularTime = (period: Period) =>
  period === Period.FIRST || period === Period.SECOND || period === Period.FIRST_VS_SECOND;

const getMaxPassValueByPeriod = (period: Period): number => {
  if (period === Period.STARTING) return MAX_PASSES;
  if (isDuringRegularTime(period)) return Math.ceil(MAX_PASSES * REGULAR_PERIOD);
  return Math.ceil(MAX_PASSES * EXTRA_TIME_PERIOD);
};

const getMaxTouchesValueByPeriod = (period: Period): number => {
  if (period === Period.STARTING) return MAX_TOUCHES;
  if (isDuringRegularTime(period)) return Math.ceil(MAX_TOUCHES * REGULAR_PERIOD);
  return Math.ceil(MAX_TOUCHES * EXTRA_TIME_PERIOD);
};

export const convertPassesToRadius = (period: Period, passes: number) => {
  const maxPassByPeriod = getMaxPassValueByPeriod(period);
  const passValue = (passes / maxPassByPeriod) * MAX_RADIUS;
  if (passValue < MIN_RADIUS) return MIN_RADIUS;
  if (passValue > MAX_RADIUS) return MAX_RADIUS;
  return passValue;
};

export const convertTouchesToRadius = (period: Period, touches: number) => {
  const maxTouchesByPeriod = getMaxTouchesValueByPeriod(period);
  const touchesValue = (touches / maxTouchesByPeriod) * MAX_RADIUS;
  if (touchesValue < MIN_RADIUS) return MIN_RADIUS;
  if (touchesValue > MAX_RADIUS) return MAX_RADIUS;
  return touchesValue;
};

export const transformXFromIQToSBD = (data: IObvXgChainFormatted): IObvXgChainFormatted => ({
  ...data,
  x: data.x * IQ_TO_SBD_X,
});
export const transformYFromIQToSBD = (data: IObvXgChainFormatted): IObvXgChainFormatted => ({
  ...data,
  y: 80 - data.y * IQ_TO_SBD_Y,
});

export const transformPassesAndTouchesToRadiusValue =
  (selectedPeriod: Period) =>
  (data: IObvXgChainFormatted): IObvXgChainFormatted & { passesRadius: number; touchesRadius: number } => ({
    ...data,
    passesRadius: convertPassesToRadius(selectedPeriod, data.passes),
    touchesRadius: convertTouchesToRadius(selectedPeriod, data.touches),
  });

export const transformPassesAndTouchesIQToSB =
  (selectedPeriod: Period) => (passAndTouchesData: IObvXgChainFormatted[]) =>
    pipe(
      map(transformYFromIQToSBD),
      map(transformXFromIQToSBD),
      map(transformPassesAndTouchesToRadiusValue(selectedPeriod))
    )(passAndTouchesData);

export const radiusValue = (passes: number, touches: number, model: ObvXgDataModel) =>
  model === obvXgDataModels[0] ? passes : touches;

const isPlayerSelected = (
  selectedPlayerToDisplay: VizTooltipPlayerWithPeriodId | null,
  playerId: number,
  periodId: number
) =>
  !selectedPlayerToDisplay ||
  (selectedPlayerToDisplay.playerId === playerId && selectedPlayerToDisplay.periodId === periodId);

export const mergeCombinationsWithPassData = (
  passes: IPassNetworkVizPasses[],
  passCombinations: IPassNetworkVizPassCombinations[],
  model: ObvXgDataModel,
  shouldUseDefaultStrokeWidth: boolean,
  shouldUseDefaultStrokeColour: boolean,
  scale: Scale,
  selectedPeriod: Period,
  selectedPlayerToDisplay: VizTooltipPlayerWithPeriodId | null,
  periodId: number
): IMergedPassAndCombinations[] =>
  passCombinations.reduce((combinations, combination) => {
    const receiver = passes.find(pass => pass.playerId === combination.receiverId);
    const passer = passes.find(pass => pass.playerId === combination.passerId);

    if (!receiver || !passer) return combinations;

    const passerRadius = radiusValue(passer.passesRadius, passer.touchesRadius, model) + RADIUS_OFFSET_PASSER;
    const receiverRadius = radiusValue(receiver.passesRadius, receiver.touchesRadius, model) + RADIUS_OFFSET_RECEIVER;
    const passerCircle = { cx: passer.x, cy: passer.y, r: passerRadius };
    const receiverCircle = { cx: receiver.x, cy: receiver.y, r: receiverRadius };

    // If the players overlap, don't show passing lines (they look funny)
    if (circlesOverlap(passerCircle, receiverCircle)) return combinations;

    const lineBetweenPlayers: Line = { x1: passer.x, y1: passer.y, x2: receiver.x, y2: receiver.y };
    const distanceBetweenPlayers = distance(lineBetweenPlayers);

    const shortenedLine = shortenLine(passerRadius, receiverRadius, lineBetweenPlayers);
    const offsetShortenedLine = offsetLineEnd(COMBINATION_LINE_OFFSET, distanceBetweenPlayers, shortenedLine);

    const strokeWidth = calcStrokeWidthBuckets(
      shouldUseDefaultStrokeWidth ? MINIMUM_PASS_COMBINATIONS : combination.passes, // combination value per player
      MINIMUM_PASS_COMBINATIONS,
      periodDivisor(selectedPeriod, MAXIMUM_PASS_COMBINATIONS)
    );

    const { combinationMin, combinationMax } = obxXgAssociatedAttr[model];
    const stroke = calcStrokeColour(
      scale,
      shouldUseDefaultStrokeColour ? combinationMin : periodMultiplier(selectedPeriod, combination[model]),
      combinationMin,
      combinationMax
    );

    return [
      ...combinations,
      {
        ...combination,
        ...offsetShortenedLine,
        stroke,
        strokeWidth,
        teamId: passer.teamId,
        key: `${passer.playerId}:${receiver.playerId}:${passer.periodId}`,
        playerId: passer.playerId,
        opacity: isPlayerSelected(selectedPlayerToDisplay, passer.playerId, periodId) ? 0.4 : 0.1,
      },
    ];
  }, [] as IMergedPassAndCombinations[]);

export const emptyPeriods: { [key in Period]: [] } = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] };

/**
 * Returns the pass combination data with associated viz info like line colour and thickness.
 */
export const getPassingLines = (
  {
    homeData,
    awayData,
  }: {
    homeData: (IPass & ITeamNameIndex)[];
    awayData: (IPass & ITeamNameIndex)[];
  },
  passData: IPassNetworkVizPasses[],
  model: ObvXgDataModel,
  minPasses: number,
  passesFilters: IPassesFilter,
  scale: Scale,
  selectedPeriod: Period,
  selectedPlayerToDisplay: VizTooltipPlayerWithPeriodId | null
) => {
  if (!passesFilters[PASSES_BETWEEN_PLAYERS]?.selected) return emptyPeriods; // if disabled, show nothing
  if (!homeData.length && !awayData.length) return emptyPeriods; // refactor when returning both home/away

  const passesBetweenChildren = passesFilters[PASSES_BETWEEN_PLAYERS].children;

  const filteredPassCombinations = [...homeData, ...awayData].filter(combi => combi.passes >= minPasses);

  const passesByPeriod = groupBy(p => `${p.periodId}`, passData);
  const combisByPeriod = groupBy(p => `${p.periodId}`, filteredPassCombinations);

  const passPeriods = Object.keys(passesByPeriod);
  const combiPeriods = Object.keys(combisByPeriod);

  if (!equals(passPeriods, combiPeriods)) return emptyPeriods; // bail if there's passes but no combinations (or vice versa)

  return passPeriods.reduce(
    (periodPasses, periodId) => ({
      ...periodPasses,
      [periodId]: mergeCombinationsWithPassData(
        passesByPeriod[`${+periodId}`],
        combisByPeriod[`${+periodId}`],
        model,
        !passesBetweenChildren[PASSES_VOLUME].selected,
        !passesBetweenChildren[PASS_XG_OBV_BETWEEN_PLAYERS].selected,
        scale,
        selectedPeriod,
        selectedPlayerToDisplay,
        +periodId
      ),
    }),
    emptyPeriods
  );
};

const generateVizCircleFromModel =
  (
    selectedModel: ObvXgDataModel,
    showDefaultFillColour: boolean,
    showPassVolume: boolean,
    scale: Scale,
    selectedPlayerToDisplay: VizTooltipPlayerWithPeriodId | null,
    periodId: number
  ) =>
  ({ passesRadius, touchesRadius, uniqueValue, x, y, teamId, ...restPlayer }: VizPassesWithUniqueValue): VizCircle => {
    const { attr, min, max } = obxXgAssociatedAttr[selectedModel];
    const modelValue = restPlayer[attr];
    const percentile = valueWithinNewRange(modelValue, min, max, 0, 1);
    const colour = chromaValue(scale, showDefaultFillColour ? 0 : percentile);
    const { touches, playerName, playerId, passes } = restPlayer;
    const toolTipData = {
      obv: [
        { label: 'OBV', value: modelValue ? modelValue.toFixed(2) : 0 },
        { label: 'Passes', value: passes },
      ],
      xg: [
        { label: 'xG', value: modelValue ? modelValue.toFixed(2) : 0 },
        { label: 'Touches', value: touches },
      ],
    };
    return {
      cx: x,
      cy: y,
      r: showPassVolume ? radiusValue(passesRadius, touchesRadius, selectedModel) : DEFAULT_RADIUS,
      fill: colour,
      stroke: colour,
      teamId,
      uniqueValue,
      playerName,
      playerId,
      toolTipData: toolTipData[selectedModel],
      fillOpacity: isPlayerSelected(selectedPlayerToDisplay, playerId, periodId) ? 0.3 : 0.1,
      strokeOpacity: isPlayerSelected(selectedPlayerToDisplay, playerId, periodId) ? 1 : 0.1,
    };
  };

/**
 * Generates the data for the Pass Network circles, including things like colour and size.
 */
export const getPassingCircles = (
  selectedModel: ObvXgDataModel,
  showDefaultFillColour: boolean,
  showPassVolume: boolean,
  modelData: ModelDataByPeriod,
  scale: Scale,
  selectedPlayerToDisplay: VizTooltipPlayerWithPeriodId | null
): ByPeriod<VizCircle[]> =>
  Object.entries(modelData).reduce(
    (allModelData, [periodId, modelPeriodData]) => ({
      ...allModelData,
      [periodId]: modelPeriodData.map(
        generateVizCircleFromModel(
          selectedModel,
          showDefaultFillColour,
          showPassVolume,
          scale,
          selectedPlayerToDisplay,
          +periodId
        )
      ),
    }),
    emptyPeriods
  );

/**
 * Generates the data for the Pass Network text labels, including things like position and text content
 */
export const getPassingText = (
  modelData: ModelDataByPeriod,
  isPortrait: boolean,
  selectedPlayerToDisplay: VizTooltipPlayerWithPeriodId | null
): ByPeriod<Array<VizText>> =>
  Object.entries(modelData).reduce(
    (allModelData, [periodId, modelPeriodData]) => ({
      ...allModelData,
      [periodId]: modelPeriodData.map(({ playerName, uniqueValue, x, y, teamId, playerId }) => ({
        x,
        y,
        text: playerName,
        uniqueValue,
        teamId,
        transform: isPortrait ? rotate(90, x, y) : '',
        opacity: isPlayerSelected(selectedPlayerToDisplay, playerId, +periodId) ? 1 : 0.3,
      })),
    }),
    emptyPeriods
  );

export const getPitchData = (
  includedPeriods: Period[],
  teamLookup: TeamLookup,
  selectedTeamIds: Array<number>
): Array<PitchTitleByPeriod> =>
  includedPeriods
    .map(periodId => {
      const pid: Period = +periodId;
      return selectedTeamIds.map(teamId => ({
        periodId: pid,
        teamId,
        title: `${teamLookup[teamId]}, ${PeriodName[pid]}`,
      }));
    })
    .flat();

export const filterByTeamId = <T extends { teamId: number }>(data: Array<T>, filterTeamId: number) =>
  data.filter(({ teamId }) => teamId === filterTeamId);

export const calcPlayerOnLeftSide = (player: VizTooltipPlayer, isPortrait: boolean, pitchWidth: number) => {
  if (isPortrait && player.cy < pitchWidth / 2) return true;
  if (!isPortrait && player.cx < pitchWidth / 2) return true;
  return false;
};

export const filterBySelectedPeriod = <T extends { periodId: number }>(data: Array<T>, includedPeriods: Period[]) =>
  data.filter(({ periodId }) => includedPeriods.includes(periodId));

export const addPeriodName = <T extends { periodId: number }>(item: T) => ({
  ...item,
  period: MATCH_PERIODS[item.periodId],
});
