import React, { useState, useRef, ReactElement } from 'react';
import { FilterIcon, TickDoneIcon } from '@statsbomb/react-components';
import { IPassNetworkFilters } from '../../types/passNetwork';
import {
  Button,
  ButtonWithIcon,
  MobileOnlyFullscreenContainer,
  ModalContent,
  ModalFooterContainer,
  Tabs,
  TitleSection,
} from './TabbedModal.styles';
import { Title } from '../typography/Typography.styles';

/**
 * TODO: Refactor the function scrollToPx
 * The calculation should be based on the width of the buttons
 * The current values here work, but they won't work if we change the length of the text
 */

const SCROLL_TO_WIDTH = 120;
const OFFSET = 100;
export const scrollToPx = (index: number) => index * SCROLL_TO_WIDTH - OFFSET;

const MobileFilterToDisplay = ({ component }: { component: IPassNetworkFilters['component'] }) => component;
const TabbedModal = ({ config }: { config: IPassNetworkFilters[] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalToDisplay, setModalToDisplay] = useState(config[0].component);
  const tabsRef = useRef<HTMLDivElement>(null);

  const modalContentToDisplayHandler = (component: ReactElement, ref: HTMLDivElement | null, index: number) => {
    // Had some funky behaviour with refs and scrollTo in jest
    /* istanbul ignore next */
    if (ref && ref.scrollTo) {
      ref.scrollTo({
        left: scrollToPx(index),
        behavior: 'smooth',
      });
    }

    setModalToDisplay(component);
  };

  return (
    <>
      {isOpen && (
        <MobileOnlyFullscreenContainer>
          <TitleSection>
            <Title>Filters</Title>
          </TitleSection>
          <Tabs ref={tabsRef} id="mobile-tablist" role="tablist">
            {config.map((navItem: IPassNetworkFilters, index: number) => (
              <Button
                type="button"
                role="tab"
                key={`tab-${navItem.name}`}
                $active={navItem.component === modalToDisplay}
                onClick={() => modalContentToDisplayHandler(navItem.component, tabsRef.current, index)}
              >
                {navItem.name}
              </Button>
            ))}
          </Tabs>
          <ModalContent>
            <MobileFilterToDisplay component={modalToDisplay} />
          </ModalContent>
        </MobileOnlyFullscreenContainer>
      )}
      <ModalFooterContainer>
        <ButtonWithIcon type="button" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <TickDoneIcon /> : <FilterIcon />} {isOpen ? 'Done' : 'Filters'}
        </ButtonWithIcon>
      </ModalFooterContainer>
    </>
  );
};

export default TabbedModal;
