import React, { Suspense, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { Col, Row } from 'react-flexbox-grid';
import * as passNetworkAtoms from '../../../atoms/passNetwork';
import Pitch from '../partial/pitch';
import { ByPeriod, IMergedPassAndCombinations, Period } from '../../../types/passNetwork';
import {
  PassingLines,
  PlayerCircle,
  PlayerCircleBorder,
  PlayerCircleTooltip,
  PlayerText,
} from '../partial/markings/Markers';
import { VizCircle, VizText, VizTooltipPlayer } from '../../../types/viz';
import {
  PassNetworkMarkersWrapper,
  PassNetworkPitch,
  PassNetworkPitchSvg,
  PassNetworkWrapper,
  PitchesWrapper,
  PitchTitle,
} from './PassNetwork.styles';
import PassNetworkKey from '../partial/key/PassNetworkKey';
import { VIZ_THEME } from '../../../consts/vizTheme';
import { filterByTeamId } from '../../../utils/passNetwork';
import useMousePosition from '../../../hooks/useMousePosition';
import { useWindowSize } from '../../../hooks/useWindowSize';
import PassNetworkTooltip from '../tooltips/PassNetworkTooltips';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';

const PlayerCircleLayer = ({ data }: { data: VizCircle[] }) => (
  <>
    {data.map(dataItem => {
      const componentId = `player-circle-${dataItem.uniqueValue}`;
      return (
        <g data-testid={componentId} key={componentId}>
          <PlayerCircle {...dataItem} />
        </g>
      );
    })}
  </>
);

const PlayerCircleBorderLayer = ({ data }: { data: VizCircle[] }) => (
  <>
    {data.map(dataItem => {
      const componentId = `player-circle-border-${dataItem.uniqueValue}`;
      return (
        <g data-testid={componentId} key={componentId}>
          <PlayerCircleBorder {...dataItem} />
        </g>
      );
    })}
  </>
);

const PlayerTextLayer = ({ colour, borderColour, data }: { colour: string; borderColour: string; data: VizText[] }) => (
  <>
    {data.map(({ x, y, text, uniqueValue, transform }) => {
      const componentId = `player-name-${uniqueValue}`;
      return (
        <g data-testid={componentId} key={componentId} transform={transform}>
          <PlayerText colour={colour} borderColour={borderColour} x={x} y={y} playerName={text} />
        </g>
      );
    })}
  </>
);

export const PassingCombinationsLayer = ({ data }: { data: IMergedPassAndCombinations[] }) => (
  <>
    {data.map(args => (
      <PassingLines {...args} uniqueValue={args.key} />
    ))}
  </>
);

export const CompleteCircleLayer = ({
  circles,
  periodId,
  pitchTeamId,
}: {
  circles: ByPeriod<VizCircle[]>;
  periodId: Period;
  pitchTeamId: number;
}) => {
  const data = filterByTeamId(circles[periodId], pitchTeamId);

  return (
    <>
      <PlayerCircleLayer {...{ data }} />
      <PlayerCircleBorderLayer {...{ data }} />
    </>
  );
};

const PlayerTooltip = ({
  data,
  onClick,
}: {
  data: VizCircle[];
  onClick: (arg0: VizTooltipPlayer, arg1: React.MouseEvent<SVGCircleElement, MouseEvent>) => void;
}) => (
  <>
    {data.map(({ uniqueValue, ...rest }) => (
      <PlayerCircleTooltip key={`player-circle-${uniqueValue}`} {...{ ...rest }} onClick={onClick} />
    ))}
  </>
);

const PassNetworkMarkers = ({
  periodId,
  pitchTeamId,
  onClickHandler,
}: {
  periodId: Period;
  pitchTeamId: number;
  onClickHandler: (
    arg0: React.MouseEvent<SVGCircleElement, MouseEvent>,
    arg1?: VizTooltipPlayer,
    arg2?: number
  ) => void;
}) => {
  const { passCombinations, circles, text } = useAtomValue(passNetworkAtoms.markerData);
  const selectedColourMode = useAtomValue(passNetworkAtoms.displaySettingPitchColourMode);
  const pitchDimensions = useAtomValue(passNetworkAtoms.pitchDimensions);
  const pitchRotation = useAtomValue(passNetworkAtoms.pitchRotation);
  const textData = filterByTeamId(text[periodId], pitchTeamId);

  const { TEXT } = VIZ_THEME.COLOURS[selectedColourMode];

  return (
    <PassNetworkPitchSvg
      width="100%"
      height="100%"
      viewBox={`0 0 ${pitchDimensions.width} ${pitchDimensions.height}`}
      data-testid="event-markers"
      onClick={onClickHandler}
    >
      <g transform={pitchRotation}>
        <PassingCombinationsLayer data={filterByTeamId(passCombinations[periodId], pitchTeamId)} />
        <CompleteCircleLayer {...{ circles, periodId, pitchTeamId }} />
        <PlayerTextLayer colour={TEXT.DEFAULT} borderColour={TEXT.BORDER} data={textData} />

        <PlayerTooltip
          data={filterByTeamId(circles[periodId], pitchTeamId)}
          onClick={(selectedPlayer, mouseEvent) => onClickHandler(mouseEvent, selectedPlayer, periodId)}
        />
      </g>
    </PassNetworkPitchSvg>
  );
};

const PassNetworksViz = ({ isOnModal }: { isOnModal: Boolean }) => {
  const {
    pitchRotation,
    pitchDimensions,
    isPortrait,
    model,
    selectedTeams,
    selectedPeriod,
    selectedColourMode,
    pitches,
  } = useAtomValue(passNetworkAtoms.vizProperties);

  const { TEXT, PITCH } = VIZ_THEME.COLOURS[selectedColourMode];

  const isSinglePitch = pitches.length === 1;

  const [playerToDisplay, setPlayerToDisplay] = useAtom(passNetworkAtoms.playerToDisplayTooltip);
  const containerRef = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useMousePosition(containerRef);
  const windowSize = useWindowSize();

  const downloadPitchColSizes = isSinglePitch ? { xs: 12 } : { xs: 6 };
  const pitchColSizes = isSinglePitch ? { xs: 12 } : { xs: 12, sm: 6 };

  const onClickHandler = (
    mouseEvent: React.MouseEvent<SVGCircleElement, MouseEvent>,
    selectedPlayer?: VizTooltipPlayer,
    periodId?: number
  ): void => {
    setPlayerToDisplay(selectedPlayer && periodId ? { ...selectedPlayer, periodId } : null);
    mouseEvent.stopPropagation();
    setMousePosition(mouseEvent);
  };

  useEffect(() => {
    setPlayerToDisplay(null);
  }, [windowSize.width, windowSize.height, isPortrait, model, selectedPeriod, selectedTeams]);

  return (
    <PassNetworkWrapper isPortrait={isPortrait} isSinglePitch={isSinglePitch} pitchBackground={PITCH.BACKGROUND}>
      <PitchesWrapper ref={containerRef} isPortrait={isPortrait} isSinglePitch={isSinglePitch}>
        <Row>
          {pitches.map(({ periodId, teamId: pitchTeamId, title }) => (
            <Col key={title} {...(isOnModal ? downloadPitchColSizes : pitchColSizes)} style={{ overflow: 'visible' }}>
              <PassNetworkPitch>
                <PitchTitle colour={TEXT.DEFAULT}>{title}</PitchTitle>
                <div style={{ position: 'relative' }}>
                  <PassNetworkPitchSvg
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${pitchDimensions.width} ${pitchDimensions.height}`}
                  >
                    <g transform={pitchRotation}>
                      <Pitch pitchLineColour={PITCH.LINES} pitchBackground={PITCH.BACKGROUND} />
                    </g>
                  </PassNetworkPitchSvg>
                  <PassNetworkMarkersWrapper>
                    <Suspense fallback={<LoadingSpinner />}>
                      <PassNetworkMarkers {...{ periodId, pitchTeamId, onClickHandler }} />
                    </Suspense>
                  </PassNetworkMarkersWrapper>
                </div>
              </PassNetworkPitch>
            </Col>
          ))}
          <PassNetworkTooltip
            mousePosition={mousePosition}
            playerToDisplay={playerToDisplay}
            pitchWidth={pitchDimensions.width}
            isPortrait={isPortrait}
          />
        </Row>
      </PitchesWrapper>
      <PassNetworkKey />
    </PassNetworkWrapper>
  );
};

export default PassNetworksViz;
