/**
 *
 * @param fn The function to debounce and eventually call
 * @param delay Delay time in ms
 * @returns void
 */
export const debounce = (fn: Function, delay: number) => {
  let timeout: number | null = null;

  return function debouncedFn(...args: unknown[]) {
    if (timeout) clearTimeout(timeout);
    timeout = window.setTimeout(() => fn(...args), delay);
  };
};
