import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

export const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  fill: ${({ theme }: { theme: ITheme }) => theme.colours.graphics.primary.main};
  gap:  ${({ theme }: { theme: ITheme }) => theme.spacing()};
  
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    gap: initial;
  }
 

  &:hover {
      > svg {
        background: ${({ theme }: { theme: ITheme }) => theme.colours.canvas.primary};
        border-radius: 0.3rem; // TODO: change this value when inputs change
        fill: ${({ theme }: { theme: ITheme }) => theme.colours.brand.interface.light};
      }
    }
  }
`;

export const MenuButton = styled(HeaderButton)`
  margin-right: ${({ theme }: { theme: ITheme }) => theme.spacing()};

  &:hover {
    background: inherit;
  }

  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: none;
  }
`;
