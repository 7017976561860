import React from 'react';
import { Atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { IStatTableCol } from '../../../types/matchStats';
import { IHomeAndAwayStats, IMatchStatMeta } from '../../../types/match';
import { useStatTableSort } from '../../../hooks/useStatTableSort';
import { nonSelectedDataModel } from '../../../atoms/passNetwork';
import { groupByPeriod } from '../../../utils/table';
import { TitleWrapper } from '../Table.styles';
import { HalfWidthCol } from '../../gridVariants/gridVariants';
import TableWithTitle from '../TableWithTitle';

export interface IHomeAndAwayTableProps<T> {
  stats: Atom<IHomeAndAwayStats<T>>;
  columnMetaData: IStatTableCol[];
  field: string | string[];
  meta: IMatchStatMeta;
  multiSort?: boolean;
  isHeadSticky?: boolean;
  stickyColumns?: { [key: string]: number }[];
}

const HomeAndAwayTableFirstVsSecond = <T extends Record<string, number | string>>({
  stats,
  columnMetaData,
  field,
  meta,
  multiSort = false,
  isHeadSticky = false,
  stickyColumns,
}: IHomeAndAwayTableProps<T>) => {
  const { homeData, awayData } = useAtomValue(stats);
  const { firstHalf: firstHalfHome, secondHalf: secondHalfHome } = groupByPeriod(homeData);
  const { firstHalf: firstHalfAway, secondHalf: secondHalfAway } = groupByPeriod(awayData);

  const modelValueToHide = useAtomValue(nonSelectedDataModel).value;

  const [homeDataFormatted, colsToSort, sortCb] = useStatTableSort(
    multiSort,
    columnMetaData,
    firstHalfHome,
    modelValueToHide
  );
  const [awayDataFormatted, colsToSortAway, sortCbAway] = useStatTableSort(
    multiSort,
    columnMetaData,
    firstHalfAway,
    modelValueToHide
  );

  const [homeDataFormattedSecond, colsToSortSecond, sortCbSecond] = useStatTableSort(
    multiSort,
    columnMetaData,
    secondHalfHome,
    modelValueToHide
  );
  const [awayDataFormattedSecond, colsToSortAwaySecond, sortCbAwaySecond] = useStatTableSort(
    multiSort,
    columnMetaData,
    secondHalfAway,
    modelValueToHide
  );

  return (
    <>
      <TitleWrapper>First Half</TitleWrapper>
      <>
        <HalfWidthCol>
          <TableWithTitle
            title={meta.homeTeamName}
            stats={homeDataFormatted as T[]}
            columnMetaData={colsToSort}
            field={field}
            sortCb={sortCb}
            isHeadSticky={isHeadSticky}
            stickyColumns={stickyColumns}
          />
        </HalfWidthCol>
        <HalfWidthCol>
          <TableWithTitle
            title={meta.awayTeamName}
            stats={awayDataFormatted as T[]}
            columnMetaData={colsToSortAway}
            field={field}
            sortCb={sortCbAway}
            isHeadSticky={isHeadSticky}
            stickyColumns={stickyColumns}
          />
        </HalfWidthCol>
      </>
      <TitleWrapper>Second Half</TitleWrapper>
      <>
        <HalfWidthCol>
          <TableWithTitle
            title={meta.homeTeamName}
            stats={homeDataFormattedSecond as T[]}
            columnMetaData={colsToSortSecond}
            field={field}
            sortCb={sortCbSecond}
            isHeadSticky={isHeadSticky}
            stickyColumns={stickyColumns}
          />
        </HalfWidthCol>
        <HalfWidthCol>
          <TableWithTitle
            title={meta.awayTeamName}
            stats={awayDataFormattedSecond as T[]}
            columnMetaData={colsToSortAwaySecond}
            field={field}
            sortCb={sortCbAwaySecond}
            isHeadSticky={isHeadSticky}
            stickyColumns={stickyColumns}
          />
        </HalfWidthCol>
      </>
    </>
  );
};

export default HomeAndAwayTableFirstVsSecond;
