import React, { Suspense } from 'react';
import { useAtomValue } from 'jotai/utils';
import { Col, Row } from 'react-flexbox-grid';
import { Atom } from 'jotai';
import { DownloadButton } from './Download';
import { IHomeAndAwayStats, IMatchStats } from '../../types/match';
import { allMatchStatsCSVData } from '../../atoms/match';
import { firstVsSecondCSVData, singleCSVData, singleCSVMatchData } from '../../utils/match';
import { IStatTableCol } from '../../types/matchStats';
import { ITeamNameIndex } from '../../types/team';
import { groupByPeriod } from '../../utils/table';

export const DownloadAllMatchStatsTables = ({ fileName }: { fileName: string }) => {
  const allTableStats = useAtomValue(allMatchStatsCSVData);

  return (
    <Row style={{ marginTop: '3.6rem' }}>
      <Col xs={12}>
        <DownloadButton title={`${fileName}.csv`} fileContents={allTableStats}>
          Download All
        </DownloadButton>
      </Col>
    </Row>
  );
};

export const DownloadSingleTable = <T extends ITeamNameIndex>({
  name,
  tableAtom,
  config,
  groupBy,
  fileName,
}: {
  name: string;
  tableAtom: Atom<IHomeAndAwayStats<T>>;
  config: IStatTableCol[];
  groupBy?: string;
  fileName?: string;
}) => {
  const tableAtomValue = useAtomValue(tableAtom);
  const singleTableStats = singleCSVData<T>(tableAtomValue, config, groupBy);

  return (
    <DownloadButton title={`${fileName}.csv`} fileContents={singleTableStats}>
      {name}
    </DownloadButton>
  );
};

interface IDownloadSingleMatchTableProps {
  fileName: string;
  name: string;
  tableAtom: Atom<IMatchStats | null>;
  config: IStatTableCol[];
}

export const DownloadSingleMatchTable = ({ fileName, name, tableAtom, config }: IDownloadSingleMatchTableProps) => {
  const tableAtomValue = useAtomValue(tableAtom);
  if (!tableAtomValue) return null;
  const singleTableStats = singleCSVMatchData(tableAtomValue, config);

  return (
    <DownloadButton title={`${fileName}.csv`} fileContents={singleTableStats}>
      {name}
    </DownloadButton>
  );
};

const periodConfig: IStatTableCol = {
  title: 'Period',
  stat: 'period',
};

interface IDownloadFirstVsSecondTableProps<T> {
  name: string;
  tableAtom: Atom<IHomeAndAwayStats<T>>;
  config: IStatTableCol[];
  groupBy?: string;
  fileName?: string;
}

export const DownloadFirstVsSecondTable = <T extends ITeamNameIndex>({
  name,
  tableAtom,
  config,
  groupBy,
  fileName,
}: IDownloadFirstVsSecondTableProps<T>) => {
  const tableAtomValue = useAtomValue(tableAtom);
  const { firstHalf, secondHalf } = groupByPeriod(tableAtomValue.homeData);
  const { firstHalf: firstHalfAway, secondHalf: secondHalfAway } = groupByPeriod(tableAtomValue.awayData);
  const CSVData = {
    firstHalf: { homeData: firstHalf, awayData: firstHalfAway },
    secondHalf: { homeData: secondHalf, awayData: secondHalfAway },
  };

  const configWithPeriod = [...config, periodConfig];

  const firstVsSecondTableStats = firstVsSecondCSVData<T>(CSVData, configWithPeriod, groupBy);

  return (
    <DownloadButton title={`${fileName}.csv`} fileContents={firstVsSecondTableStats}>
      {name}
    </DownloadButton>
  );
};

export const DownloadSomeTablesButton = <T extends ITeamNameIndex>({
  showFirstVsSecond,
  ...props
}: IDownloadFirstVsSecondTableProps<T> & { showFirstVsSecond: boolean }) => (
  // Because these buttons depend upon data fetched from the API, they can suspend while rendering.
  // Due to this, we need a suspense fallback here so that we don't end up falling back to the overall
  // MatchSection fallback. The fallback just renders an empty div as at the moment the places which use this
  // have a Loading message of their own.
  <Suspense fallback={<div />}>
    {showFirstVsSecond ? <DownloadFirstVsSecondTable<T> {...props} /> : <DownloadSingleTable {...props} />}
  </Suspense>
);
