import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

export const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
`;

export const Indicator = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 2rem;
  border-radius: 2rem;
  background: ${({ theme, $isChecked }: { theme: ITheme; $isChecked: boolean }) =>
    $isChecked ? theme.colours.brand.interface.main : theme.colours.graphics.secondary.main};
  &:hover {
    background: ${({ theme }: { theme: ITheme }) => theme.colours.brand.interface.light};
  }
  &:focus-visible {
    box-shadow: ${({ theme }: { theme: ITheme }) => theme.effects.focus};
    outline: none;
  }
`;

export const Label = styled.label`
  color: ${({ theme }: { theme: ITheme }) => theme.colours.ink.primary.main};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }: { theme: ITheme }) => theme.spacing()};
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.body1.size};
`;

export const Circle = styled.span`
  background: ${({ theme }: { theme: ITheme }) => theme.colours.graphics.tertiary.main};
  border: 1px solid ${({ theme }: { theme: ITheme }) => theme.colours.graphics.secondary.main};
  border-radius: 50%;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  position: relative;
  left: ${({ $isChecked }: { $isChecked: boolean }) => ($isChecked ? '1.6rem' : '0.25rem')};
  transition: 0.2s;
`;
