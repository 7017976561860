import { partial } from 'ramda';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { TABLE_SORT_STATES } from '../../types/table';
import { sortItems } from '../../utils/table';

export const defensiveActions = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player',
    toolTip: 'Player Name',
    stat: 'playerName',
    sortableFn: partial(sortItems, ['playerName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'T',
    toolTip: 'Tackles',
    stat: 'tackles',
    sortableFn: partial(sortItems, ['tackles']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'I',
    toolTip: 'Interceptions',
    stat: 'interceptions',
    sortableFn: partial(sortItems, ['interceptions']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'T+I',
    toolTip: 'Tackles & Interceptions',
    stat: 'tacklesAndInterceptions',
    sortableFn: partial(sortItems, ['tacklesAndInterceptions']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'DP',
    toolTip: 'Dribbled Past',
    stat: 'dribbledPast',
    sortableFn: partial(sortItems, ['dribbledPast']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'T/DP%',
    toolTip: 'Tackles Per Dribbled Past %',
    stat: 'challengePercentage',
    toFixed: 0,
    sortableFn: partial(sortItems, ['challengePercentage']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Fouls',
    toolTip: 'Fouls',
    stat: 'fouls',
    sortableFn: partial(sortItems, ['fouls']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Clear',
    toolTip: 'Clearances',
    stat: 'clearances',
    sortableFn: partial(sortItems, ['clearances']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'AerWin',
    toolTip: 'Aerial Wins',
    stat: 'successfulAerials',
    sortableFn: partial(sortItems, ['successfulAerials']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Aer%',
    toolTip: 'Aerial Win %',
    stat: 'aerialPercentage',
    toFixed: 0,
    sortableFn: partial(sortItems, ['aerialPercentage']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Drib',
    toolTip: 'Dribbles',
    stat: 'dribbles',
    sortableFn: partial(sortItems, ['dribbles']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Disp',
    toolTip: 'Dispossessions',
    stat: 'dispossessions',
    sortableFn: partial(sortItems, ['dispossessions']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'PW',
    toolTip: 'Penalties Won',
    stat: 'penaltiesWon',
    sortableFn: partial(sortItems, ['penaltiesWon']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
];
