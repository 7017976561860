import { styled } from '@statsbomb/react-components';

export const Container = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const List = styled.ul`
  border: ${({ theme }) => `thin solid ${theme.colours.canvas.tertiary}`};
  border-left: none;
  display: flex;
`;

export const Item = styled.li`
  display: flex;
`;

export const Button = styled.button`
  align-items: center;
  background-color: ${({ theme, $isActive }) => {
    if ($isActive) return theme.colours.brand.interface.main;
    return theme.colours.canvas.secondary.main;
  }};
  border-left: ${({ theme }) => `thin solid ${theme.colours.canvas.tertiary}`};
  color: ${({ theme, $isActive }) => ($isActive ? theme.colours.ink.inverse.main : theme.colours.ink.primary.main)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 3rem;
  fill: ${({ theme, disabled }) => (disabled ? theme.colours.canvas.tertiary : theme.colours.ink.main)};
  justify-content: center;
  width: 3rem;
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colours.brand.interface.light};
    color: ${({ theme, disabled }) => !disabled && theme.colours.brand.interface.ink};
  }
  .svg-fill {
    fill: ${({ theme, disabled }) => (disabled ? theme.colours.ink.secondary.main : theme.colours.ink.primary.main)};
  }
`;
