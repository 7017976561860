import { range } from 'ramda';
import React from 'react';
import { keyText } from '../../../../config/passNetwork/key';
import { KEY_CIRCLE_Y_POSITION } from '../../../../consts/viz';
import { ObvXgDataModel, Period } from '../../../../types/passNetwork';
import { Arrow } from './Arrow';
import { KeyText } from './Key.styles';
import KeyElement from './KeyElement';

export const CircleSizeKey = ({
  selectedModel,
  selectedPeriod,
  colour,
}: {
  selectedModel: ObvXgDataModel;
  selectedPeriod: Period;
  colour: string;
}) => {
  const circleSize = keyText[selectedModel].circleSizeText(selectedPeriod);

  const circleRadiusDiff = 0.5;
  const firstRadius = 2.5;
  const circleGap = 5;

  const calculateRadius = (index: number) => firstRadius + index * circleRadiusDiff;

  const getCirclePosition = (currentIndex: number) => {
    const currentRadius = calculateRadius(currentIndex);
    const previousCircleRadii = range(0, currentIndex).reduce(
      (acc, circleIndex) => acc + calculateRadius(circleIndex),
      0
    );
    return currentRadius + 2 * previousCircleRadii + currentIndex * circleGap;
  };

  return (
    <div>
      <KeyElement>
        {range(0, 4).map(index => (
          <circle fill={colour} r={calculateRadius(index)} cx={getCirclePosition(index)} cy={KEY_CIRCLE_Y_POSITION} />
        ))}
        <Arrow />
      </KeyElement>
      <KeyText>
        <p>{circleSize.min}</p>
        <p>{circleSize.max}</p>
      </KeyText>
    </div>
  );
};
