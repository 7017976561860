import { styled } from '@statsbomb/react-components';
import { ITheme } from '../types/ITheme';

export const Card = styled.div(
  ({
    theme: { colours, spacing, effects },
    size = {},
  }: {
    theme: ITheme;
    size: { width?: number; height?: number };
  }) => ({
    backgroundColor: colours.canvas.secondary,
    padding: spacing(1),
    margin: 0,
    boxShadow: effects.dropShadow.regular,
    ...size,
  })
);
