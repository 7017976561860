import React, { useMemo } from 'react';
import { useAtom, WritableAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import { Lens } from 'optics-ts';
import { Switch } from '../switch/Switch';
import { passesFilters } from '../../atoms/passNetwork';
import { INestedFilter, IPassesFilter, passFilterNames } from '../../types/passNetwork';
import { ChildWrapper, SwitchContainer, Wrapper } from './PassFilters.styles';

const ChildSwitch = ({
  filterName,
  childFilterName,
  atom,
}: {
  filterName: string;
  childFilterName: string;
  atom: WritableAtom<IPassesFilter, IPassesFilter>;
}) => {
  const attributeAtom = useMemo(
    () =>
      focusAtom(atom, optic =>
        (optic.prop(filterName).prop('children') as Lens<INestedFilter, any, any>).prop(childFilterName)
      ),
    [atom, filterName]
  );

  const [item, setItem] = useAtom(attributeAtom);

  return (
    <SwitchContainer key={childFilterName}>
      <Switch
        isChecked={item.selected}
        labelText={item.title}
        onChange={() => setItem({ ...item, selected: !item.selected })}
      />
    </SwitchContainer>
  );
};

const SwitchWrapper = ({
  filterName,
  atom,
}: {
  filterName: string;
  atom: WritableAtom<IPassesFilter, IPassesFilter>;
}) => {
  const attributeAtom = useMemo(() => focusAtom(atom, optic => optic.prop(filterName)), [atom, filterName]);

  const [item, setItem] = useAtom(attributeAtom);

  if (!item) return null;

  return (
    <React.Fragment key={filterName}>
      <SwitchContainer>
        <Switch
          isChecked={item.selected}
          labelText={item.title}
          onChange={() => setItem({ ...item, selected: !item.selected })}
        />
      </SwitchContainer>
      {item.selected && (
        <ChildWrapper>
          {Object.keys(item.children || []).map((child: string) => (
            <ChildSwitch key={child} filterName={filterName} childFilterName={child} atom={atom} />
          ))}
        </ChildWrapper>
      )}
    </React.Fragment>
  );
};

const PassFilters = () => (
  <Wrapper>
    {passFilterNames.map(name => (
      <SwitchWrapper key={name} filterName={name} atom={passesFilters} />
    ))}
  </Wrapper>
);

export default PassFilters;
