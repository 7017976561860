import React, { Suspense } from 'react';
import { useAtomValue } from 'jotai/utils';
import { atom } from 'jotai';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { ErrorBoundary } from '@sentry/react';
import { map } from 'ramda';
import * as matchAtoms from '../../../atoms/match';
import { Page } from '../../Page';
import { MainStatsTable } from '../../tables/MainStatsTable';
import { StatTitle, TableContainer } from '../../tables/Table.styles';
import { shotsAndPasses } from '../../../config/statsTable/shotsAndPasses';
import { passingAndTouches } from '../../../config/statsTable/passingAndTouches';
import { xgChain } from '../../../config/statsTable/xgChain';
import { defensiveActions } from '../../../config/statsTable/defensiveActions';
import { obv } from '../../../config/statsTable/obv';
import { matchStatsMetaData, matchStatsMetaDataExtraFields } from '../../../config/statsTable/matchStats';
import { pressuresAndCounterPressures } from '../../../config/statsTable/pressures';
import { IMatchStatsTable } from '../../../types/matchStats';
import {
  DownloadAllMatchStatsTables,
  DownloadSingleTable,
  DownloadSingleMatchTable,
} from '../../downloads/CSVDownloads';
import { Card } from '../../Card.styles';
import { HomeAndAwayTable } from '../../tables/HomeAndAwayTable';

const MatchStats = () => {
  const matchStats = useAtomValue(matchAtoms.stats);
  if (!matchStats) throw new Error('Missing Match ID');
  const fileName = useAtomValue(matchAtoms.matchDownloadVsFileName);

  const { home, away, meta } = matchStats;

  const statsTableData: IMatchStatsTable[] = [
    {
      name: 'Shots And Key Passes',
      stats: matchAtoms.matchShotsAndKeyPassesAtom,
      columnMetaData: shotsAndPasses,
      field: 'playerId',
    },
    {
      name: 'Passes And Touches',
      stats: matchAtoms.passingAndTouchesAtom,
      columnMetaData: passingAndTouches,
      field: 'playerId',
    },
    {
      name: 'Defensive Actions',
      stats: matchAtoms.defensiveActionsAtom,
      columnMetaData: defensiveActions,
      field: 'playerId',
    },
    { name: 'OBV', stats: matchAtoms.obvAtom, columnMetaData: obv, field: 'playerId' },
    {
      name: 'Pressures',
      stats: matchAtoms.pressuresAtom,
      columnMetaData: pressuresAndCounterPressures,
      field: 'playerId',
    },
    {
      name: 'Counterpressures',
      stats: matchAtoms.counterPressuresAtom,
      columnMetaData: pressuresAndCounterPressures,
      field: 'playerId',
    },
    { name: 'xG Chain', stats: matchAtoms.xgChainAtom, columnMetaData: xgChain, field: 'playerId' },
  ];

  return (
    <Page data-testid="match-stats-page" title={`${meta.homeTeamName} vs ${meta.awayTeamName} - Match Stats`}>
      <Grid fluid>
        <Row between="xs">
          <Col xs={12} lg={1} xl={3} />
          <Col xs={12} lg={10} xl={6}>
            <Card as={Row}>
              <Col xs={12}>
                <Row style={{ marginTop: '0.8rem' }}>
                  <Col xs={12} sm={6}>
                    <StatTitle>Match Stats</StatTitle>
                  </Col>
                  <Col xs={12} sm={6}>
                    <DownloadSingleMatchTable
                      {...{
                        name: 'Match Stats',
                        tableAtom: matchAtoms.stats,
                        config: [...matchStatsMetaDataExtraFields, ...matchStatsMetaData],
                        fileName: 'Match Stats',
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row style={{ marginTop: '1rem', marginBottom: '0.8rem' }}>
                  <Col xs={12}>
                    <TableContainer>
                      <MainStatsTable {...{ home, away, meta, config: matchStatsMetaData }} />
                    </TableContainer>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Col>
          <Col xs={12} lg={1} xl={3} />
        </Row>

        <Suspense fallback={null}>
          <DownloadAllMatchStatsTables fileName={`${fileName} - Match Stats.csv`} />
        </Suspense>

        {map(({ name, stats, columnMetaData, field }) => (
          <Suspense
            key={`statsTable-suspense-${name}`}
            fallback={
              <>
                <DownloadSingleTable
                  {...{ meta, name, tableAtom: stats, config: columnMetaData, fileName: 'Match stats' }}
                />
                <HomeAndAwayTable
                  stats={atom({ homeData: [], awayData: [] })}
                  {...{ columnMetaData, field, name, meta }}
                  key={`statsTable-${name}`}
                />
              </>
            }
          >
            <Card as={Row} style={{ marginTop: '3.6rem' }} between="xs">
              <Col xs={12}>
                <Row style={{ marginTop: '0.8rem' }}>
                  <Col xs={12} sm={6}>
                    <StatTitle>{name}</StatTitle>
                  </Col>
                  <Col xs={12} sm={6}>
                    <DownloadSingleTable
                      {...{ meta, name, tableAtom: stats, config: columnMetaData, fileName: 'Match stats' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row style={{ marginTop: '1rem', marginBottom: '0.8rem' }} data-testid={`statsTable-${name}`}>
                  <HomeAndAwayTable
                    {...{
                      stats,
                      columnMetaData,
                      field,
                      name,
                      meta,
                      isHeadSticky: true,
                      stickyColumns: [{ index: 1, width: 8 }],
                    }}
                    key={`statsTable-${name}`}
                  />
                </Row>
              </Col>
            </Card>
          </Suspense>
        ))(statsTableData)}
      </Grid>
    </Page>
  );
};

export const MatchStatsPage = () => (
  <ErrorBoundary fallback={<h3>Something went wrong</h3>}>
    <MatchStats />
  </ErrorBoundary>
);
