import { partial } from 'ramda';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { TABLE_SORT_STATES } from '../../types/table';
import { sortItems } from '../../utils/table';

export const shotsAndPasses = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player',
    toolTip: 'Player Name',
    stat: 'playerName',
    sortableFn: partial(sortItems, ['playerName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Shots',
    toolTip: 'Shots',
    stat: 'shots',
    toFixed: 0,
    sortableFn: partial(sortItems, ['shots']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Goals',
    toolTip: 'Goals',
    stat: 'goals',
    toFixed: 0,
    sortableFn: partial(sortItems, ['goals']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'xG',
    toolTip: 'xG',
    stat: 'xg',
    toFixed: 2,
    sortableFn: partial(sortItems, ['xg']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'xG/Shot',
    toolTip: 'xG Per Shot',
    stat: 'xgShots',
    toFixed: 2,
    sortableFn: partial(sortItems, ['xgShots']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'KP',
    toolTip: 'Key Passes',
    stat: 'kp',
    toFixed: 0,
    sortableFn: partial(sortItems, ['kp']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Assists',
    toolTip: 'Assists',
    stat: 'assists',
    toFixed: 0,
    sortableFn: partial(sortItems, ['assists']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'xG Assist',
    toolTip: 'xG Assists',
    stat: 'xgAssist',
    toFixed: 2,
    sortableFn: partial(sortItems, ['xgAssist']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OP KP',
    toolTip: 'Open Play Key Passes',
    stat: 'opkp',
    sortableFn: partial(sortItems, ['opkp']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OP Assists',
    toolTip: 'Open Play Assists',
    stat: 'opAssists',
    sortableFn: partial(sortItems, ['opAssists']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OP xG Asst',
    toolTip: 'Open Play xG Assisted',
    stat: 'opXgAssist',
    toFixed: 2,
    sortableFn: partial(sortItems, ['opXgAssist']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'SP KP',
    toolTip: 'Set Piece Key Passes',
    stat: 'spkp',
    sortableFn: partial(sortItems, ['spkp']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'SP Assists',
    toolTip: 'Set Piece Assists',
    stat: 'spAssists',
    sortableFn: partial(sortItems, ['spAssists']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'SP xG Asst',
    toolTip: 'Set Piece xG Assists',
    stat: 'spXgAssist',
    toFixed: 2,
    sortableFn: partial(sortItems, ['spXgAssist']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
];
