import { styled } from '@statsbomb/react-components';
import { BodyText2 } from '../typography/Typography.styles';

export const CompSeasonOptionInner = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TextWithEllipsis = styled(BodyText2)`
  max-width: ${({ $width }: { $width: number }) => `${$width}%` || 'auto'};
  width: ${({ $width }: { $width: number }) => `${$width}%` || 'auto'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

// GCM: This is the component we use when there's no flag to show in the <img/>.
// I'm using px here as the <img/> `width` attr is in px.
export const FlagIconMissing = styled.div`
  width: 20px;
  height: 16px;
`;

export const FlagIcon = styled.img`
  margin-top: 0.2rem;
`;
