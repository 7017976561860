import { partial } from 'ramda';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { TABLE_SORT_STATES } from '../../types/table';
import { sortItems } from '../../utils/table';

export const passes = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Passer',
    toolTip: 'Passer',
    stat: 'passerName',
    sortableFn: partial(sortItems, ['passerName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Receiver',
    toolTip: 'Receiver',
    stat: 'receiverName',
    sortableFn: partial(sortItems, ['receiverName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Passes',
    toolTip: 'Passes',
    stat: 'passes',
    sortableFn: partial(sortItems, ['passes']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'xG Chain',
    toolTip: 'xG',
    stat: 'xg',
    toFixed: 2,
    sortableFn: partial(sortItems, ['xg']),
    order: TABLE_SORT_STATES.DESC,
  },
  {
    title: 'OBV',
    toolTip: 'obv',
    stat: 'obv',
    toFixed: 2,
    sortableFn: partial(sortItems, ['obv']),
    order: TABLE_SORT_STATES.DESC,
  },
];

const defaultStats = ['passerName', 'receiverName', 'passes'];
const obvStats = [...defaultStats, 'obv'];
const xgChainStats = [...defaultStats, 'xg'];

export const obv = passes.filter(column => obvStats.includes(column.stat));
export const xg = passes.filter(column => xgChainStats.includes(column.stat));
