import { atom } from 'jotai';
import { atomWithQuery } from 'jotai/query';
import { ICompetitionSeason } from '../types/competitionSeason';
import { fetchMany } from '../utils/api';
import { getCompSeasonsFromFixtures } from '../utils/competitionSeasons';
import { getFixturesForTeam } from './fixturesForTeam';

export const compSeasons = atomWithQuery(() => ({
  queryKey: 'compSeasons',
  queryFn: async (): Promise<ICompetitionSeason[]> => fetchMany('/competition-seasons'),
}));

export const compSeasonsForTeam = atom((get): ICompetitionSeason[] | null => {
  const teamFixtures = get(getFixturesForTeam);
  const playedTeamFixtures = teamFixtures.filter(match => match.matchPlayStatus === 'Played');
  const allCompSeasons = get(compSeasons);
  if (!playedTeamFixtures || !allCompSeasons) return null;

  return getCompSeasonsFromFixtures(allCompSeasons)(playedTeamFixtures);
});
