/* istanbul ignore file */
import { styled } from '@statsbomb/react-components';
import { TOP_NAV_HEIGHT } from '../../consts/navigation';
import { ITheme } from '../../types/ITheme';

export const TopNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${TOP_NAV_HEIGHT};
  ${({ theme }: { theme: ITheme }) => theme.mqTo('md')} {
    background: ${({ theme }: { theme: ITheme }) => theme.colours.brand.interface.main};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  background: ${({ theme }: { theme: ITheme }) => theme.colours.graphics.tertiary.main};
  width: 15rem;
  height: ${TOP_NAV_HEIGHT};
  align-items: center;

  section {
    display: none;
  }

  button {
    display: none;
  }

  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: flex;
    width: 100%;
    padding: ${({ theme }: { theme: ITheme }) => `0 ${theme.spacing(0.5)} 0`};
    gap: ${({ theme }: { theme: ITheme }) => theme.spacing(0.5)};

    > section {
      display: flex;
    }

    > button {
      display: initial;
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }: { theme: ITheme }) => theme.colours.brand.interface.main};
  min-width: 17.5rem;
  padding: 12px 17px 13px;
  img {
    height: 25px;
  }
`;

export const Button = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: none;
  }
  &:hover {
    background: ${({ theme }: { theme: ITheme }) => theme.colours.brand.interface.light};
  }
`;
