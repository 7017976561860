import { Circle, Coordinate, Line } from '../../types/viz';

/**
 * Moves a single coordinate in one dimension by the ratio supplied.
 * @param c1 The coordinate to be moved
 * @param c2 The other coord on this axis (ie, if c1 is the x1 of a line, c2 will be x2)
 * @param ratio The fraction of the line to shorten by (ie, to shorten the line by 50%, pass in 0.5)
 * @returns The modified c1 coordinate
 */
export const shiftCoordinate = (c1: Coordinate, c2: Coordinate, ratio: number) => (1 - ratio) * c1 + ratio * c2;

/**
 * The length of a line (ie, the distance between 2 points)
 */
export const distance = ({ x1, y1, x2, y2 }: Line) => Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

/**
 * Do two circles overlap?
 */
export const circlesOverlap = (circle1: Circle, circle2: Circle) =>
  distance({ x1: circle1.cx, y1: circle1.cy, x2: circle2.cx, y2: circle2.cy }) < circle1.r + circle2.r;

/**
 * Shortens a line by the specified amount at each end
 * @param originShortenAmount The amount to shorten the start of the line
 * @param destinationShortenAmount The amount to remove from the end of the line
 * @param line The line to be shortened
 * @returns A new shorter line
 */
export const shortenLine = (
  originShortenAmount: number,
  destinationShortenAmount: number,
  { x1, y1, x2, y2 }: Line
): Line => {
  const distanceBetweenPoints = distance({ x1, y1, x2, y2 });
  const originRatio = originShortenAmount / distanceBetweenPoints;
  const destinationRatio = destinationShortenAmount / distanceBetweenPoints;

  return {
    x1: shiftCoordinate(x1, x2, originRatio),
    y1: shiftCoordinate(y1, y2, originRatio),
    x2: shiftCoordinate(x2, x1, destinationRatio),
    y2: shiftCoordinate(y2, y1, destinationRatio),
  };
};

/**
 * Moves a coordinate to one side by a specified amount
 * @param coordName The coordinate of the line to be moved
 * @param line The line
 * @param offsetAmount The amount to move the line
 * @param lineLength The length of the line
 * @returns One offset coordinate
 */
export const offsetPoint = (
  coordName: 'x1' | 'y1' | 'x2' | 'y2',
  { x1, y1, x2, y2 }: Line,
  offsetAmount: number,
  lineLength: number
) => {
  switch (coordName) {
    case 'x1':
      return x1 + (offsetAmount * (y2 - y1)) / lineLength;
    case 'y1':
      return y1 + (offsetAmount * (x1 - x2)) / lineLength;
    case 'x2':
      return x2 + (offsetAmount * (y2 - y1)) / lineLength;
    case 'y2':
      return y2 + (offsetAmount * (x1 - x2)) / lineLength;
    default:
      throw new Error('Invalid coordinate supplied');
  }
};

/**
 * Takes a line and moves the end coordinates to one side
 * @param offsetAmount The amount to move the line end
 * @param lineLength The length of the line
 * @param line The line to be altered
 * @returns A new line with the end moved to one side
 */
export const offsetLineEnd = (offsetAmount: number, lineLength: number, line: Line): Line => ({
  x1: line.x1, // offsetPoint('x1', line, offsetAmount, lineLength),
  y1: line.y1, // offsetPoint('y1', line, offsetAmount, lineLength),
  x2: offsetPoint('x2', line, offsetAmount, lineLength),
  y2: offsetPoint('y2', line, offsetAmount, lineLength),
});

/**
 * Creates a valid SVG transform string for rotation
 * @param angle Rotation angle in degrees
 */
export const rotate = (angle: number, x?: number, y?: number) =>
  x == null ? `rotate(${angle})` : `rotate(${angle} ${x} ${y})`;
