import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

export const SearchPlaceholder = styled.div`
  background-color: ${({ theme }: { theme: ITheme }) => theme.colours.canvas.primary};
  width: 100%;
  height: 100%;
  margin: initial;

  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    margin: 0 -0.5rem 0 0;
    max-width: 20rem;
    position: relative;
  }
`;

export const SearchInput = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  padding-right: 4rem;
  padding-left: 1rem;
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.body1.size};
`;
