import { MATCH_STATUS } from '../consts/match';

type MatchStatusKeys = keyof typeof MATCH_STATUS;
export type MatchStatus = typeof MATCH_STATUS[MatchStatusKeys];

export interface IMatchSummary {
  accountId: number;
  awayTeam: string;
  awayTeamId: number;
  competitionId: number;
  date: string;
  homeTeam: string;
  homeTeamId: number;
  matchId: number;
  matchPlayStatus: MatchStatus | null;
  seasonId: number;
  has360: boolean | null;
  has360License?: boolean;
}

export interface IMatch extends IMatchSummary {
  awayPenaltyScore: number | null;
  awaySbdataTeamId: number;
  awayScore: number | null;
  awayTeamGroup: string | null;
  competitionLeagueType: string;
  competitionPyramidRank: number;
  competitionTypeId: number;
  has360: boolean;
  homePenaltyScore: number | null;
  homeSbdataTeamId: number;
  homeScore: number | null;
  homeTeamGroup: string | null;
  matchRoundGroup: string;
  matchRoundOrder: number;
  matchRoundType: string | null;
  matchSbdataId: number;
  season: string;
  stage: string;
  teamFirstColor: string | null;
  teamId: number;
}

export interface IMatchStatResponseItem {
  [key: string]: string | number | boolean | null;
  accountId: number;
  awayTeamName: string;
  challenges: number;
  hasExtraTime: boolean;
  hasPenaltyShootout: boolean;
  homeTeam: boolean;
  homeTeamName: string;
  matchAwayPenaltyScore: number | null;
  matchAwayScore: number;
  matchAwayTeamId: number;
  matchDate: string;
  matchHomePenaltyScore: number | null;
  matchHomeScore: number;
  matchHomeTeamId: number;
  matchId: number;
  passes: number;
  pressureRegains: number;
  pressures: number;
  redCards: number;
  secondYellowCards: number;
  shots: number;
  shotsOnTarget: number;
  successfulPasses: number;
  tackles: number;
  xg: number;
  xgConditional: number;
  yellowCards: number;
}

export type IMatchStatResponse = [IMatchStatResponseItem, IMatchStatResponseItem] | [];

export interface IMatchStat {
  [key: string]: string | number | boolean;
  challenges: number;
  homeTeam: boolean;
  passes: number;
  passRatio: number;
  penaltyScore: number;
  posessionRatio: number;
  pressureRegains: number;
  pressures: number;
  redAndSecondYellowCards: string;
  redCards: number;
  score: number;
  secondYellowCards: number;
  shots: number;
  shotsOnTarget: number;
  successfulPasses: number;
  tackles: number;
  tacklesWon: string;
  xg: number;
  xgConditional: number;
  yellowCards: number;
  teamId: number;
  teamName: string;
  totalPasses: string;
}

export interface IMatchStatMeta {
  awayTeamName: string;
  homeTeamName: string;
  matchAwayTeamId: number;
  matchDate: string;
  matchHomeTeamId: number;
  matchId: number;
  hasExtraTime: boolean;
  hasPenaltyShootout: boolean;
}

export interface IMatchStatHomeResponseItem extends IMatchStatResponseItem {
  homeTeam: true;
  teamId: number;
  teamName: string;
}

export interface IMatchStatAwayResponseItem extends IMatchStatResponseItem {
  homeTeam: false;
  teamId: number;
  teamName: string;
}

export interface IMatchStatHome extends IMatchStat {
  homeTeam: true;
  teamId: number;
  teamName: string;
}

export interface IMatchStatAway extends IMatchStat {
  homeTeam: false;
  teamId: number;
  teamName: string;
}

export interface IMatchStats {
  home: IMatchStatHome;
  away: IMatchStatAway;
  meta: IMatchStatMeta;
}

export const isMatchStatHome = (stats: IMatchStatResponseItem): stats is IMatchStatHomeResponseItem => stats.homeTeam;

export interface IMatchDetails {
  accountId: number;
  awayManagers: string;
  awayTeamFirstColor: string;
  awayTeamName: string;
  awayTeamSecondColor: string;
  competitionId: number;
  competitionName: string;
  homeManagers: string;
  homeTeamFirstColor: string;
  homeTeamName: string;
  homeTeamSecondColor: string;
  matchAwayPenaltyScore: number | null;
  matchAwayScore: number;
  matchAwayTeamGroup: string;
  matchAwayTeamId: number;
  matchDate: string;
  matchHomePenaltyScore: number | null;
  matchHomeScore: number;
  matchHomeTeamGroup: string;
  matchHomeTeamId: number;
  matchId: number;
  matchOptaId: number | null;
  matchRoundType: string;
  matchSbdataId: number;
  matchWeather: string | null;
  matchWeek: number | null;
  refereeNames: string;
  seasonId: number;
  seasonName: string;
  venueName: string;
}

export interface IHomeAndAwayStats<T> {
  homeData: T[];
  awayData: T[];
}
