import React from 'react';
import { Table } from '@statsbomb/react-components';
import { TEXT_ALIGNMENT } from '../../consts/typography';

export const StatRow = ({
  homeStat,
  statName,
  awayStat,
}: {
  homeStat: string | number;
  statName: string;
  awayStat: string | number;
}) => (
  <Table.Row>
    <Table.DataCell>{statName}</Table.DataCell>
    <Table.DataCell $textAlign={TEXT_ALIGNMENT.RIGHT}>{homeStat}</Table.DataCell>
    <Table.DataCell $textAlign={TEXT_ALIGNMENT.RIGHT}>{awayStat}</Table.DataCell>
  </Table.Row>
);
