import { atom } from 'jotai';
import { atomWithQuery } from 'jotai/query';
import { groupBy } from 'ramda';
import { QueryFunction } from 'react-query';
import {
  modelFiltersConfig,
  passesFilterConfig,
  pitchOrientationConfig,
  PlayerPositionsFilterConfig,
} from '../config/passNetwork/filters';
import { IMatchDetails } from '../types/match';
import { IPass } from '../types/passes';
import {
  IMergedPassAndCombinations,
  IModelFilter,
  IPassesFilter,
  IPassNetworkVizPasses,
  Period,
  PlayerPosition,
  PASS_VOLUME_PER_PLAYER,
  PASS_XG_OBV_PER_PLAYER,
  ByPeriod,
  PitchOrientation,
  PitchTitleByPeriod,
  SelectedTeam,
  ObvXgDataModel,
  PitchesLength,
} from '../types/passNetwork';
import { VizCircle, VizText, VizTooltipPlayerWithPeriodId } from '../types/viz';
import {
  formatPassNetworkObvXgChain,
  formatPassCombinations,
  transformPassesAndTouchesIQToSB,
  getPassingLines,
  getPassingCircles,
  getPassingText,
  getPitchData,
  filterBySelectedPeriod,
  addPeriodName,
} from '../utils/passNetwork';
import { matchId as matchIdAtom } from './filters';
import * as PitchConstants from '../consts/pitch';

import { details, matchFetchMany, passNetworkObvXgChain as passNetworkObvXgChainQuery, stats } from './match';
import { rotate } from '../utils/viz/drawing';
import { scale } from './preferences';
import { ColourTheme } from '../types/vizTheme';
import { hyphenDateFromTZ } from '../utils/general';

export const gameFilterSelectedTeam = atom<SelectedTeam>('both');

export const gameFilterGameTime = atom<Period>(Period.STARTING);

export const includedPeriods = atom(get =>
  get(gameFilterGameTime) === Period.FIRST_VS_SECOND ? [Period.FIRST, Period.SECOND] : [get(gameFilterGameTime)]
);

export const modelFilters = atom(modelFiltersConfig as IModelFilter[]);

export const displaySettingPitchColourMode = atom(ColourTheme.LIGHT);

const selectedDataModelIndex = atom<1 | 0>(get => (get(modelFilters)[0].selected ? 0 : 1));

export const selectedDataModel = atom(get => modelFiltersConfig[get(selectedDataModelIndex)] as IModelFilter);

export const nonSelectedDataModel = atom(get => modelFiltersConfig[1 - get(selectedDataModelIndex)] as IModelFilter);

export const passesFilters = atom<IPassesFilter>(passesFilterConfig);

export const minimumPasses = atom(4);

export const minimumMinutes = atom(0);

/** Display Setting Filters */
export const pitchOrientationFilters = atom(pitchOrientationConfig);

export const pitchIsDarkModeSelected = atom(get => get(displaySettingPitchColourMode) === ColourTheme.DARK);

export const playerPositions = atom(PlayerPositionsFilterConfig[0] as PlayerPosition);

export const playerToDisplayTooltip = atom<VizTooltipPlayerWithPeriodId | null>(null);

export const pitchOrientation = atom<PitchOrientation>(get => {
  const [portrait] = get(pitchOrientationFilters);
  return portrait.selected ? 'portrait' : 'landscape';
});

export const scoreLine = atom(get => {
  const matchStats = get(stats);
  if (!matchStats) return '';

  const { home, away, meta } = matchStats;
  return `${meta.homeTeamName} ${home.score} - ${away.score} ${meta.awayTeamName} ${hyphenDateFromTZ(meta.matchDate)}`;
});

export const passNetworkObvXgChainAtom = atom(get => {
  const passNetworkObvXgChain = get(passNetworkObvXgChainQuery).map(addPeriodName);
  const { matchHomeTeamId, matchAwayTeamId, homeTeamName, awayTeamName } = get(details) as IMatchDetails;
  const selectedModel = get(selectedDataModel).value;

  const passNetworkData = filterBySelectedPeriod(passNetworkObvXgChain, get(includedPeriods));

  return formatPassNetworkObvXgChain(
    matchHomeTeamId,
    matchAwayTeamId,
    homeTeamName,
    awayTeamName,
    selectedModel
  )(passNetworkData);
});

export const matchPeriodQueryString = atom(get =>
  get(gameFilterGameTime) === Period.STARTING ? 'starting-xi' : 'all'
);

export const matchPassesAtom = atomWithQuery(get => ({
  queryKey: [`matchPasses-${get(matchPeriodQueryString)}`, get(matchIdAtom)],
  queryFn: matchFetchMany(`/pass-combinations?period=${get(matchPeriodQueryString)}`) as QueryFunction<IPass[]>,
}));

export const passesAtom = atom(get => {
  const passesData = get(matchPassesAtom).map(addPeriodName);
  const passes = passesData.map(pass => ({
    ...pass,
    id: `${pass.passerId}-${pass.receiverId}-${pass.periodId}`,
  }));

  const passData = filterBySelectedPeriod(passes, get(includedPeriods));

  const matchDetails = get(details);

  // TODO: make it fail higher up
  /* istanbul ignore next */
  if (!matchDetails) throw new Error('Match ID not set');

  const { matchHomeTeamId, matchAwayTeamId, homeTeamName, awayTeamName } = matchDetails;
  const selectedModel = get(selectedDataModel).value;

  return formatPassCombinations(matchHomeTeamId, matchAwayTeamId, homeTeamName, awayTeamName, selectedModel)(passData);
});

export const passNetworkModelData = atom<IPassNetworkVizPasses[]>(get => {
  const originalData = get(passNetworkObvXgChainAtom);
  const selectedPeriod = get(gameFilterGameTime);
  const { homeData, awayData } = originalData;
  if (!homeData.length && !awayData.length) return [];

  return transformPassesAndTouchesIQToSB(selectedPeriod)([...homeData, ...awayData]);
});

export const selectedTeamIds = atom<number[]>(get => {
  const team = get(gameFilterSelectedTeam);
  const { matchHomeTeamId, matchAwayTeamId } = get(details) as IMatchDetails;
  switch (team) {
    case 'home':
      return [matchHomeTeamId];
    case 'away':
      return [matchAwayTeamId];
    default:
      return [matchHomeTeamId, matchAwayTeamId];
  }
});

export const pitches = atom<Array<PitchTitleByPeriod>>(get => {
  const selectedTeams = get(selectedTeamIds);
  const { matchHomeTeamId, matchAwayTeamId, homeTeamName, awayTeamName } = get(details) as IMatchDetails;
  const teamLookup = { [matchHomeTeamId]: homeTeamName, [matchAwayTeamId]: awayTeamName };

  return getPitchData(get(includedPeriods), teamLookup, selectedTeams);
});

export const pitchesLength = atom<PitchesLength>(get => get(pitches).length as PitchesLength);

export const isPortrait = atom<boolean>(get => get(pitchOrientation) === 'portrait');

export const pitchDimensions = atom<{ width: number; height: number }>(get =>
  get(isPortrait)
    ? { width: PitchConstants.HEIGHT, height: PitchConstants.WIDTH }
    : { width: PitchConstants.WIDTH, height: PitchConstants.HEIGHT }
);

export const pitchRotation = atom<string>(get => {
  const centrePoint = PitchConstants.WIDTH / 2;
  return rotate(get(isPortrait) ? -90 : 0, centrePoint, centrePoint);
});

// This bundles up all the atoms that we need to render the structure of the viz (the titles and pitches)
export const vizProperties = atom<{
  pitchRotation: string;
  pitchDimensions: { width: number; height: number };
  pitches: Array<PitchTitleByPeriod>;
  isPortrait: boolean;
  model: ObvXgDataModel;
  selectedPeriod: Period;
  selectedTeams: number[];
  selectedColourMode: ColourTheme;
}>(get => ({
  pitchRotation: get(pitchRotation),
  pitchDimensions: get(pitchDimensions),
  pitches: get(pitches),
  isPortrait: get(isPortrait),
  model: get(selectedDataModel).value,
  selectedTeams: get(selectedTeamIds),
  selectedPeriod: get(gameFilterGameTime),
  selectedColourMode: get(displaySettingPitchColourMode),
}));

// This is the main atom that powers the passnetwork viz and contains all the data needed to render markers on our pass network pitches.
export const markerData = atom<{
  passCombinations: ByPeriod<IMergedPassAndCombinations[]>;
  circles: ByPeriod<VizCircle[]>;
  text: ByPeriod<VizText[]>;
}>(get => {
  const passes = get(passesAtom);
  const minPasses = get(minimumPasses);
  const model = get(selectedDataModel).value;
  const passFilters = get(passesFilters);
  const modelData = get(passNetworkModelData);
  const isPortrait = get(pitchOrientation) === 'portrait';
  const selectedScale = get(scale);
  const selectedPeriod = get(gameFilterGameTime);
  const selectedPlayerToDisplay = get(playerToDisplayTooltip);

  const passesVolumePerPlayer = passFilters[PASS_VOLUME_PER_PLAYER].selected;
  const shouldUseDefaultFill = !passFilters[PASS_XG_OBV_PER_PLAYER].selected;

  const modelDataByPeriod = groupBy(
    p => `${p.periodId}`,
    modelData.map(player => ({ ...player, uniqueValue: `${player.playerId}:${player.periodId}` }))
  );

  return {
    circles: getPassingCircles(
      model,
      shouldUseDefaultFill,
      passesVolumePerPlayer,
      modelDataByPeriod,
      selectedScale,
      selectedPlayerToDisplay
    ),
    passCombinations: getPassingLines(
      passes,
      modelData,
      model,
      minPasses,
      passFilters,
      selectedScale,
      selectedPeriod,
      selectedPlayerToDisplay
    ),
    text: getPassingText(modelDataByPeriod, isPortrait, selectedPlayerToDisplay),
  };
});

export const isDownloadModalOpen = atom<Boolean>(false);
