import React from 'react';
import { useAtomValue } from 'jotai/utils';
import { VIZ_THEME } from '../../../../consts/vizTheme';
import * as passNetworkAtoms from '../../../../atoms/passNetwork';
import { KEY_ARROW_Y_POSITION } from '../../../../consts/viz';

export const Arrow = () => {
  const pitchColourMode = useAtomValue(passNetworkAtoms.displaySettingPitchColourMode);

  const {
    LINES: { LINE_STROKE_WIDTH },
    COLOURS,
  } = VIZ_THEME;

  const { TEXT } = COLOURS[pitchColourMode];
  const strokeWidth = LINE_STROKE_WIDTH.DEFAULT;
  const stroke = TEXT.DEFAULT;

  const arrowLength = 44.5;
  const arrowHeadSize = 2.5;

  return (
    <g strokeWidth={strokeWidth} stroke={stroke} transform={`translate(0 ${KEY_ARROW_Y_POSITION})`}>
      <line x1={arrowLength} x2={arrowLength - arrowHeadSize} y1={0} y2={-arrowHeadSize} strokeLinecap="round" />
      <line x1={arrowLength} x2={arrowLength - arrowHeadSize} y1={0} y2={arrowHeadSize} strokeLinecap="round" />
      <line x1={0} y1={0} x2={arrowLength} y2={0} />
    </g>
  );
};
