/* istanbul ignore file */
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import React, { useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import * as filters from '../../atoms/filters';
import { selectedPlayerId } from '../../atoms/filters';
import { getIsPlayerKeeper } from '../../atoms/playersInTeam';

const FakeRadarPage = () => {
  const [playerId] = useAtom(selectedPlayerId);
  return <div>Derived Player ID: {playerId}</div>;
};

export const PlayerSection = () => {
  const { playerId, '*': subpage } = useParams();
  const [, setTeamId] = useAtom(filters.playerId);
  const isKeeper = useAtomValue(getIsPlayerKeeper);

  useEffect(() => {
    setTeamId(playerId ? +playerId : null);
  }, [playerId]);

  return (
    <section id="player-section">
      <h1>Player Section</h1>
      <p>Page: {subpage}</p>
      <p>Player ID: {playerId}</p>
      <p>Is Keeper: {isKeeper.toString()}</p>
      <Routes>
        <Route path="radar" element={<FakeRadarPage />} />
      </Routes>
    </section>
  );
};

export default PlayerSection;
