import React, { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { SearchResultsModal } from '../components/search/SearchResults';
import CompetitionSection from '../components/sections/CompetitionSection';
import MatchSection from '../components/sections/MatchSection';
import PlayerSection from '../components/sections/PlayerSection';
import TeamSection from '../components/sections/TeamSection';
import { pagePrefix } from '../utils/api';

export const AppRoutes = ({
  isMenuOpen,
  isDesktopMenuExpanded,
}: {
  isMenuOpen: boolean;
  isDesktopMenuExpanded: boolean;
}) => (
  <Routes>
    <Route
      path={pagePrefix('/')}
      element={
        <>
          <Suspense fallback={null}>
            <SearchResultsModal />
          </Suspense>
          <Outlet />
        </>
      }
    >
      <Route path={pagePrefix('/player/:playerId/*')} element={<PlayerSection />} />
      <Route path={pagePrefix('/player/:playerId/*')} element={<PlayerSection />} />
      <Route path={pagePrefix('/team/:teamId/*')} element={<TeamSection />} />
      <Route
        path={pagePrefix('/match/:matchId/*')}
        element={<MatchSection isMenuOpen={isMenuOpen} isDesktopMenuExpanded={isDesktopMenuExpanded} />}
      />
      <Route path={pagePrefix('/competition/:compId/*')} element={<CompetitionSection />} />
    </Route>
    <Route path="*" element={<>Page not found</>} />
  </Routes>
);
