import React from 'react';
import { TableContainer, TableTitle } from './Table.styles';
import { StatsTable } from './StatsTable';
import { IStatTableCol } from '../../types/matchStats';

const TableWithTitle = <T extends Record<string, number | string>>({
  title,
  stats,
  columnMetaData,
  field,
  sortCb,
  isHeadSticky,
  stickyColumns,
}: {
  title: string;
  stats: T[];
  columnMetaData: IStatTableCol[];
  field: string | string[];
  sortCb: (arg0: IStatTableCol) => T[] | null;
  isHeadSticky?: boolean;
  stickyColumns?: { [key: string]: number }[];
}) => (
  <>
    <TableTitle>{title}</TableTitle>
    <TableContainer>
      <StatsTable<T>
        stats={stats}
        columnMetaData={columnMetaData}
        field={field}
        sortCb={sortCb}
        isHeadSticky={isHeadSticky}
        stickyColumns={stickyColumns}
      />
    </TableContainer>
  </>
);

export default TableWithTitle;
