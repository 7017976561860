import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

export const TableContainer = styled.div`
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
  background-color: ${({ theme }: { theme: ITheme }) => theme.colours.brand.interface.ink};
  border: ${({ theme }: { theme: ITheme }) => `thin solid ${theme.colours.layering[1]}`};
  & td:first-child,
  & th:first-child {
    text-align: left;
  }
  font-variant-numeric: tabular-nums;
`;

export const TableTitle = styled.h3(({ theme }: { theme: ITheme }) => ({
  padding: `${theme.spacing(0.5)}`,
  backgroundColor: theme.colours.brand.interface.ink,
  color: theme.colours.graphics.secondary.ink,
  fontWeight: 'bold',
  fontSize: theme.typography.heading3.size,
}));

export const StatTitle = styled.h2(({ theme }: { theme: ITheme }) => ({
  color: theme.colours.ink.primary.main,
  fontSize: theme.typography.heading2.size,
  fontWeight: theme.typography.heading2.weight,
  lineHeight: theme.spacing(4),
}));

export const StyledLink = styled.a(({ theme }: { theme: ITheme }) => ({
  textDecorationLine: 'underline',
  '&:hover': {
    color: theme.colours.brand.interface.light,
  },
}));

// TODO wait for designs for the titles.
export const TitleWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }: { theme: ITheme }) => theme.spacing(1)};
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.heading3.size};
`;
