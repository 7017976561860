import React from 'react';
import { Table } from '@statsbomb/react-components';
import { useAtomValue } from 'jotai/utils';
import { getSortState } from '../../utils/table';
import { IFormation, IStatTableCol } from '../../types/matchStats';
import { isPlayerKeeper } from '../../utils/player';
import { matchFormationsAtom } from '../../atoms/match';
import { StyledLink } from './Table.styles';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { formatLocaleNumber } from '../../utils/formatLocaleNumber';

interface IStatsTableProps<T> {
  stats: T[];
  columnMetaData: IStatTableCol[];
  field: string | string[];
  sortCb: (arg0: IStatTableCol) => T[] | null;
  isHeadSticky?: boolean;
  stickyColumns?: { [key: string]: number }[];
}

export const formatKey = <T extends Record<string, number | string>>(stat: T, field: string | string[]) => {
  if (typeof field === 'string') {
    return stat[field];
  }

  return field.reduce((str, key) => (!str ? `${stat[key]}` : `${str}-${stat[key]}`), '');
};

export const formatStatData = (statData: string | number, col: IStatTableCol) => {
  if (!statData) return formatLocaleNumber(0, col.toFixed);
  if (typeof statData === 'string') return statData;
  return formatLocaleNumber(statData, col.toFixed);
};

const playerNameFields = ['passer', 'receiver', 'player', 'playerName'];
const playerNames: { [key: string]: string } = {
  playerName: 'playerId',
  player: 'playerId',
  passer: 'passerId',
  receiver: 'receiverId',
};

export const formatPlayerNameLink = <T extends Record<string, number | string>>(
  playerData: IFormation[],
  stat: T,
  col: IStatTableCol
) => {
  const playerId = stat[playerNames[col.stat]] as number;
  const noPlayerData = !playerData || playerData.length < 1;
  if (!playerId) return '/player/0';
  if (noPlayerData && playerId) return `/player/${playerId}`;

  const isKeeper = isPlayerKeeper('formationPositionName', playerId, playerData);
  if (isKeeper) return `/keeper/${playerId}`;

  return `/player/${playerId}`;
};

export const formatStat = <T extends Record<string, number | string>>(
  playerData: IFormation[],
  stat: T,
  col: IStatTableCol
) => {
  const statData = stat[col.stat];
  if (playerNameFields.includes(col.stat))
    return (
      <StyledLink href={formatPlayerNameLink<T>(playerData, stat, col)}>{formatStatData(statData, col)}</StyledLink>
    );
  return formatStatData(statData, col);
};

export const StatsTable = <T extends Record<string, number | string>>({
  stats,
  columnMetaData,
  field,
  sortCb,
  isHeadSticky,
  stickyColumns,
}: IStatsTableProps<T>) => {
  const playerData = useAtomValue(matchFormationsAtom);

  return (
    <Table isHeadSticky={isHeadSticky} stickyColumns={stickyColumns}>
      <Table.Head>
        <Table.Row>
          {columnMetaData.map((col: IStatTableCol) => (
            <Table.HeaderCell
              key={col.title}
              title={col.toolTip}
              $textAlign={col.textAlign || TEXT_ALIGNMENT.RIGHT}
              sortCb={sortCb(col)}
              sortState={getSortState(columnMetaData, col)}
              aria-sort={getSortState(columnMetaData, col)}
              data-testid={col?.sortableFn ? 'sortable-header' : 'non-sortable-header'}
            >
              {col.title}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {stats.map((stat: T) => (
          <Table.Row key={formatKey<T>(stat, field)}>
            {columnMetaData.map((col: IStatTableCol) => (
              <Table.DataCell $textAlign={col.textAlign || TEXT_ALIGNMENT.RIGHT} key={col.stat}>
                {formatStat<T>(playerData, stat, col)}
              </Table.DataCell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
