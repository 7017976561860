import React, { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai/utils';
import { Pagination } from '../../../_temporary/Pagination/Pagination';
import { PaginationWrapper } from '../../pagination/Pagination.styles';
import { paginatedData } from '../../../utils/pagination';
import { useStatTableSort } from '../../../hooks/useStatTableSort';
import { gameFilterGameTime, nonSelectedDataModel } from '../../../atoms/passNetwork';
import { groupByPeriod, paginationPageChange } from '../../../utils/table';
import { TitleWrapper } from '../Table.styles';
import { HalfWidthCol } from '../../gridVariants/gridVariants';
import TableWithTitle from '../TableWithTitle';
import { IHomeAndAwayTablePaginatedProps } from '../../../types/table';

const HomeAndAwayTablePaginatedFirstVsSecond = <T extends Record<string, number | string>>({
  stats,
  columnMetaData,
  field,
  meta,
  itemsPerPage,
  siblingCount,
  multiSort = false,
  isHeadSticky = false,
  stickyColumns,
}: IHomeAndAwayTablePaginatedProps<T>) => {
  const { homeData, awayData } = useAtomValue(stats);
  const { firstHalf: firstHalfHome, secondHalf: secondHalfHome } = groupByPeriod(homeData);
  const { firstHalf: firstHalfAway, secondHalf: secondHalfAway } = groupByPeriod(awayData);

  const selectedPeriod = useAtomValue(gameFilterGameTime);
  const nonSelectedModel = useAtomValue(nonSelectedDataModel).value;

  const [homeDataFormatted, colsToSort, sortCb] = useStatTableSort<T>(
    multiSort,
    columnMetaData,
    firstHalfHome,
    nonSelectedModel
  );
  const [awayDataFormatted, colsToSortAway, sortCbAway] = useStatTableSort<T>(
    multiSort,
    columnMetaData,
    firstHalfAway,
    nonSelectedModel
  );

  const [activePageHome, setActivePageHome] = useState(1);
  const [activePageAway, setActivePageAway] = useState(1);

  const paginatedHomeData = paginatedData(homeDataFormatted, activePageHome, itemsPerPage);
  const paginatedAwayData = paginatedData(awayDataFormatted, activePageAway, itemsPerPage);

  // Second half
  const [homeDataFormattedSecond, colsToSortSecond, sortCbSecond] = useStatTableSort<T>(
    multiSort,
    columnMetaData,
    secondHalfHome,
    nonSelectedModel
  );
  const [awayDataFormattedSecond, colsToSortAwaySecond, sortCbAwaySecond] = useStatTableSort<T>(
    multiSort,
    columnMetaData,
    secondHalfAway,
    nonSelectedModel
  );

  const [activePageHomeSecond, setActivePageHomeSecond] = useState(1);
  const [activePageAwaySecond, setActivePageAwaySecond] = useState(1);

  const paginatedHomeDataSecond = paginatedData(homeDataFormattedSecond, activePageHomeSecond, itemsPerPage);
  const paginatedAwayDataSecond = paginatedData(awayDataFormattedSecond, activePageAwaySecond, itemsPerPage);

  useEffect(() => {
    setActivePageHome(1);
    setActivePageAway(1);
    setActivePageHomeSecond(1);
    setActivePageAwaySecond(1);
  }, [selectedPeriod]);

  return (
    homeData &&
    awayData && (
      <>
        <TitleWrapper>First Half</TitleWrapper>
        <>
          <HalfWidthCol>
            <TableWithTitle
              title={meta.homeTeamName}
              stats={paginatedHomeData}
              columnMetaData={colsToSort}
              field={field}
              sortCb={sortCb}
              isHeadSticky={isHeadSticky}
              stickyColumns={stickyColumns}
            />
            <PaginationWrapper>
              <Pagination
                activePage={activePageHome}
                dataLength={firstHalfHome.length}
                onPageChange={paginationPageChange(setActivePageHome)}
                itemsPerPage={itemsPerPage}
                siblingCount={siblingCount}
              />
            </PaginationWrapper>
          </HalfWidthCol>
          <HalfWidthCol>
            <TableWithTitle
              title={meta.awayTeamName}
              stats={paginatedAwayData}
              columnMetaData={colsToSortAway}
              field={field}
              sortCb={sortCbAway}
              isHeadSticky={isHeadSticky}
              stickyColumns={stickyColumns}
            />

            <PaginationWrapper>
              <Pagination
                activePage={activePageAway}
                dataLength={firstHalfAway.length}
                onPageChange={paginationPageChange(setActivePageAway)}
                itemsPerPage={itemsPerPage}
                siblingCount={siblingCount}
              />
            </PaginationWrapper>
          </HalfWidthCol>
        </>
        <TitleWrapper>Second Half</TitleWrapper>
        <>
          <HalfWidthCol>
            <TableWithTitle
              title={meta.homeTeamName}
              stats={paginatedHomeDataSecond}
              columnMetaData={colsToSortSecond}
              field={field}
              sortCb={sortCbSecond}
              isHeadSticky={isHeadSticky}
              stickyColumns={stickyColumns}
            />
            <PaginationWrapper>
              <Pagination
                activePage={activePageHomeSecond}
                dataLength={secondHalfHome.length}
                onPageChange={paginationPageChange(setActivePageHomeSecond)}
                itemsPerPage={itemsPerPage}
                siblingCount={siblingCount}
              />
            </PaginationWrapper>
          </HalfWidthCol>
          <HalfWidthCol>
            <TableWithTitle
              title={meta.awayTeamName}
              stats={paginatedAwayDataSecond}
              columnMetaData={colsToSortAwaySecond}
              field={field}
              sortCb={sortCbAwaySecond}
              isHeadSticky={isHeadSticky}
              stickyColumns={stickyColumns}
            />

            <PaginationWrapper>
              <Pagination
                activePage={activePageAwaySecond}
                dataLength={secondHalfAway.length}
                onPageChange={paginationPageChange(setActivePageAwaySecond)}
                itemsPerPage={itemsPerPage}
                siblingCount={siblingCount}
              />
            </PaginationWrapper>
          </HalfWidthCol>
        </>
      </>
    )
  );
};

export default HomeAndAwayTablePaginatedFirstVsSecond;
