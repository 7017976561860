import React from 'react';
import { SwitchInput, Indicator, Circle, Label } from './Switch.styles';

interface SwitchProps {
  onChange: Function;
  isChecked: boolean;
  labelText: string;
}

export const Switch = ({ onChange, isChecked, labelText }: SwitchProps) => (
  <Label>
    <SwitchInput checked={isChecked} onChange={onChange} />
    <Indicator $isChecked={isChecked}>
      <Circle $isChecked={isChecked} />
    </Indicator>
    {labelText}
  </Label>
);

export default { Switch };
