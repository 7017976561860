/* istanbul ignore file */
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import * as filters from '../../atoms/filters';
import { selectedTeamId } from '../../atoms/filters';

const FakeRadarPage = () => {
  const [teamId] = useAtom(selectedTeamId);
  return <div>Derived Team ID: {teamId}</div>;
};

export const TeamSection = () => {
  const { teamId, '*': subpage } = useParams();
  const [, setTeamId] = useAtom(filters.teamId);

  useEffect(() => {
    setTeamId(teamId ? +teamId : null);
  }, [teamId]);

  return (
    <section id="team-section">
      <h1>Team Section</h1>
      <p>Page: {subpage}</p>
      <p>Team ID: {teamId}</p>
      <Routes>
        <Route path="radar" element={<FakeRadarPage />} />
      </Routes>
    </section>
  );
};

export default TeamSection;
