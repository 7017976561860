/* istanbul ignore file */
import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

export const CompetitionSection = () => {
  const { compId, '*': subpage } = useParams();

  return (
    <section id="competition-section">
      <h1>Comp Section</h1>
      <p>Page: {subpage}</p>
      <p>comp ID: {compId}</p>
      <Routes>
        <Route path="radar" element={<></>} />
      </Routes>
    </section>
  );
};

export default CompetitionSection;
