import React, { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai/utils';
import { Pagination } from '../../../_temporary/Pagination/Pagination';
import { PaginationWrapper } from '../../pagination/Pagination.styles';
import { paginatedData } from '../../../utils/pagination';
import { useStatTableSort } from '../../../hooks/useStatTableSort';
import { gameFilterGameTime, nonSelectedDataModel } from '../../../atoms/passNetwork';
import { HalfWidthCol } from '../../gridVariants/gridVariants';
import TableWithTitle from '../TableWithTitle';
import HomeAndAwayTablePaginatedFirstVsSecond from './HomeAndAwayTablePaginatedFirstVsSecond';
import { IHomeAndAwayTablePaginatedProps } from '../../../types/table';
import { paginationPageChange } from '../../../utils/table';

const HomeAndAwayTablePaginated = <T extends Record<string, number | string>>({
  stats,
  columnMetaData,
  field,
  meta,
  itemsPerPage,
  siblingCount,
  multiSort = false,
  isHeadSticky = false,
  stickyColumns,
}: IHomeAndAwayTablePaginatedProps<T>) => {
  const { homeData, awayData } = useAtomValue(stats);

  const selectedPeriod = useAtomValue(gameFilterGameTime);

  const nonSelectedModel = useAtomValue(nonSelectedDataModel).value;

  const [homeDataFormatted, colsToSort, sortCb] = useStatTableSort<T>(
    multiSort,
    columnMetaData,
    homeData,
    nonSelectedModel
  );
  const [awayDataFormatted, colsToSortAway, sortCbAway] = useStatTableSort<T>(
    multiSort,
    columnMetaData,
    awayData,
    nonSelectedModel
  );

  const [activePageHome, setActivePageHome] = useState(1);
  const [activePageAway, setActivePageAway] = useState(1);

  const paginatedHomeData = paginatedData(homeDataFormatted, activePageHome, itemsPerPage);
  const paginatedAwayData = paginatedData(awayDataFormatted, activePageAway, itemsPerPage);

  useEffect(() => {
    setActivePageHome(1);
    setActivePageAway(1);
  }, [selectedPeriod]);

  return (
    homeData &&
    awayData && (
      <>
        <HalfWidthCol>
          <TableWithTitle
            title={meta.homeTeamName}
            stats={paginatedHomeData}
            columnMetaData={colsToSort}
            field={field}
            sortCb={sortCb}
            isHeadSticky={isHeadSticky}
            stickyColumns={stickyColumns}
          />
          <PaginationWrapper>
            <Pagination
              activePage={activePageHome}
              dataLength={homeData.length}
              onPageChange={paginationPageChange(setActivePageHome)}
              itemsPerPage={itemsPerPage}
              siblingCount={siblingCount}
            />
          </PaginationWrapper>
        </HalfWidthCol>
        <HalfWidthCol>
          <TableWithTitle
            title={meta.awayTeamName}
            stats={paginatedAwayData}
            columnMetaData={colsToSortAway}
            field={field}
            sortCb={sortCbAway}
            isHeadSticky={isHeadSticky}
            stickyColumns={stickyColumns}
          />

          <PaginationWrapper>
            <Pagination
              activePage={activePageAway}
              dataLength={awayData.length}
              onPageChange={paginationPageChange(setActivePageAway)}
              itemsPerPage={itemsPerPage}
              siblingCount={siblingCount}
            />
          </PaginationWrapper>
        </HalfWidthCol>
      </>
    )
  );
};

export const SomeHomeAwayTablesPaginated = <T extends Record<string, string | number>>({
  showFirstVsSecond,
  ...props
}: IHomeAndAwayTablePaginatedProps<T> & { showFirstVsSecond: boolean }) =>
  showFirstVsSecond ? <HomeAndAwayTablePaginatedFirstVsSecond {...props} /> : <HomeAndAwayTablePaginated {...props} />;

export default HomeAndAwayTablePaginated;
