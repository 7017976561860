import React from 'react';
import HalfPitch from './HalfPitch';

const Pitch = ({ pitchLineColour, pitchBackground }: { pitchLineColour: string; pitchBackground: string }) => (
  <>
    <HalfPitch pitchLineColour={pitchLineColour} pitchBackground={pitchBackground} end="defending" />
    <HalfPitch pitchLineColour={pitchLineColour} pitchBackground={pitchBackground} end="attacking" />
  </>
);

export default Pitch;
