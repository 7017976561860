import { partial } from 'ramda';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { TABLE_SORT_STATES } from '../../types/table';
import { sortItems } from '../../utils/table';

export const passingAndTouches = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player',
    toolTip: 'Player Name',
    stat: 'playerName',
    sortableFn: partial(sortItems, ['playerName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'TB',
    toolTip: 'Throughballs',
    stat: 'tb',
    sortableFn: partial(sortItems, ['tb']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'PIB',
    toolTip: 'Passes Inside Box',
    stat: 'pib',
    sortableFn: partial(sortItems, ['pib']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'PintoB',
    toolTip: 'Passes into Box',
    stat: 'pIntoB',
    sortableFn: partial(sortItems, ['pIntoB']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OP PintoB',
    toolTip: 'Open Play Passes into Box',
    stat: 'opPintoB',
    sortableFn: partial(sortItems, ['opPintoB']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'TIB',
    toolTip: 'Touches in Box',
    stat: 'tib',
    sortableFn: partial(sortItems, ['tib']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'LB',
    toolTip: 'Long Balls',
    stat: 'lb',
    sortableFn: partial(sortItems, ['lb']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'LB%',
    toolTip: 'Long Ball Percentage',
    stat: 'lbPercentage',
    toFixed: 0,
    sortableFn: partial(sortItems, ['lbPercentage']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OP Pass',
    toolTip: 'Open Play Pass',
    stat: 'opPass',
    sortableFn: partial(sortItems, ['opPass']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OP F3 Pass',
    toolTip: 'Open Play Final Third Passes',
    stat: 'opF3Pass',
    sortableFn: partial(sortItems, ['opF3Pass']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Pass%',
    toolTip: 'Pass Percentage',
    stat: 'passPercentage',
    toFixed: 0,
    sortableFn: partial(sortItems, ['passPercentage']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Cross',
    toolTip: 'Crosses',
    stat: 'cross',
    sortableFn: partial(sortItems, ['cross']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Cross%',
    toolTip: 'Cross Percentage',
    stat: 'crossPercentage',
    toFixed: 0,
    sortableFn: partial(sortItems, ['crossPercentage']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
];
