import React from 'react';
import { DropdownSelectList } from '@statsbomb/react-components';
import { useAtomValue } from 'jotai/utils';
import { ITeamSeason } from '../../types/team';
import { getTeamsInLeague } from '../../atoms/teamsInLeague';
import { selectedTeamId } from '../../atoms/filters';
import { DropdownClickHandler, IOption } from '../../types/dropdown';
import { sortByTeamName } from '../../utils/match';

const DropdownList = DropdownSelectList;

export const TeamFilterInner = ({
  teams,
  selectedTeam,
  onSelectTeam,
}: {
  teams: ITeamSeason[];
  selectedTeam?: ITeamSeason;
  onSelectTeam: DropdownClickHandler<IOption>;
}) => (
  <DropdownList
    label="Team"
    name="team-select"
    options={teams.map((team: ITeamSeason) => ({
      value: team.teamId,
      label: team.teamName,
    }))}
    placeholder="Select a Team"
    value={
      selectedTeam
        ? {
            label: selectedTeam.teamName,
            value: selectedTeam.teamId,
          }
        : null
    }
    onSelect={onSelectTeam}
  />
);

export const TeamFilter = ({ onSelectTeam }: { onSelectTeam: DropdownClickHandler<IOption> }) => {
  const teams = useAtomValue(getTeamsInLeague);
  const teamId = useAtomValue(selectedTeamId);
  const selectedTeam: ITeamSeason | undefined = teams.find((team: ITeamSeason) => team.teamId === teamId);

  return <TeamFilterInner {...{ teams: sortByTeamName(teams), selectedTeam, onSelectTeam }} />;
};
