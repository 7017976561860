/* istanbul ignore file */
import React from 'react';
import { TopNavigation, HamburgerMenuIcon, iqTheme } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { ErrorBoundary } from '@sentry/react';
import { isMenuOpen as isMenuOpenAtom } from '../../atoms/navigation';
import { MenuButton } from './HeaderButton';
import iqSoccer from '../../images/logos/iqSoccer.svg';
import { TopNavWrapper, LogoContainer, FilterContainer } from './TopNavContainer.styles';
import { resourcePrefix } from '../../utils/api';
import { teamId } from '../../atoms/filters';
import { ZINDEX } from '../../consts/zIndex';

// TODO: refactor when component library adds classname to topnav
const NavBar = ({ children }: { children: React.ReactNode }) => {
  const [isMenuOpen, setIsMenuOpen] = useAtom(isMenuOpenAtom);
  const selectedTeam = useAtomValue(teamId);
  return (
    <header data-testid="nav-bar" style={{ zIndex: ZINDEX.navigation[2], position: 'sticky', top: 0 }}>
      <TopNavigation>
        <TopNavWrapper>
          <LogoContainer>
            <MenuButton aria-expanded={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)} type="button">
              {/* TODO: Change light to theme when we have dark mode */}
              <HamburgerMenuIcon width="30" alt="Menu Button" fill={iqTheme.light.colours.graphics.tertiary.main} />
            </MenuButton>
            <a href={`/team/${selectedTeam}/dashboard`}>
              <img src={resourcePrefix(iqSoccer)} alt="IQ Soccer Logo" width="141" height="25" />
            </a>
          </LogoContainer>
          <FilterContainer>{children}</FilterContainer>
        </TopNavWrapper>
      </TopNavigation>
    </header>
  );
};

const TopNavContainer = ({ children }: { children?: React.ReactNode }) => (
  <ErrorBoundary fallback={<h3>Something went wrong</h3>}>
    <NavBar>{children}</NavBar>
  </ErrorBoundary>
);

export default TopNavContainer;
