import {
  descend,
  filter,
  pipe,
  sortWith,
  both,
  chain,
  concat,
  map,
  partial,
  assoc,
  includes,
  sortBy,
  prop,
  omit,
} from 'ramda';
import { MATCH_STATUS } from '../consts/match';
import { IHomeAndAwayStats, IMatch, IMatchStats, IMatchSummary } from '../types/match';
import { IShot, IStatTableCol, TableStats } from '../types/matchStats';
import { IObvXgChain, Period } from '../types/passNetwork';
import { ITeamNameIndex } from '../types/team';
import { mergeObjectsForCSVBy, generateCSV, attachCSVHeaders } from './csv';
import { formatStatRow, formatMatchStatRow } from './matchStats';

export const isMatchPlayed = <T extends IMatchSummary>(match: T): boolean =>
  match.matchPlayStatus === MATCH_STATUS.played;

export const isMatchInComp =
  (currentCompId: number) =>
  (match: IMatch): boolean =>
    match.competitionId === currentCompId;

export const isMatchInSeason =
  (currentSeasonId: number) =>
  (match: IMatch): boolean =>
    match.seasonId === currentSeasonId;

export const matchContainsTeam =
  <T extends IMatchSummary>(teamId: number) =>
  (match: T) =>
    match.homeTeamId === teamId || match.awayTeamId === teamId;

export const getBestMatchInCS = (compId: number, seasonId: number, matches: IMatch[]): IMatch | undefined => {
  const matchesInCS = matches.filter(match => isMatchInComp(compId)(match) && isMatchInSeason(seasonId)(match));
  const played = matchesInCS.filter(isMatchPlayed);
  const bestMatches = sortWith<IMatch>([descend(m => m.date)], played.length ? played : matchesInCS);
  return bestMatches[0];
};

export const getPlayedMatchesForTeam = (teamId: number) =>
  pipe(filter(both(matchContainsTeam(teamId), isMatchPlayed)), sortWith([descend(m => m.date)]));

export const formatCSVData = <T extends ITeamNameIndex>(groupByKey: string, tableStats: TableStats<T>[]) =>
  concat(
    chain((table: TableStats<T>) => map(formatStatRow(groupByKey, table[1]))(table[0].homeData))(tableStats),
    chain((table: TableStats<T>) => map(formatStatRow(groupByKey, table[1]))(table[0].awayData))(tableStats)
  );

export const tableAsCSV =
  (key: string) =>
  <T>(CSVData: T[]) =>
    pipe(mergeObjectsForCSVBy(key), generateCSV, attachCSVHeaders)(CSVData);

export const singleCSVData = <T extends ITeamNameIndex>(
  tableData: IHomeAndAwayStats<T>,
  config: IStatTableCol[],
  groupBy?: string
) => {
  const tableStats: TableStats<T>[] = [[tableData, config]];
  const groupByKey = groupBy || 'playerId';
  const CSVData = formatCSVData<T>(groupByKey, tableStats);

  return tableAsCSV(groupByKey)(CSVData);
};

export const singleCSVMatchData = (tableData: IMatchStats, config: IStatTableCol[]) => {
  const CSVData = [tableData.home, tableData.away].map(formatMatchStatRow(config));

  return tableAsCSV('teamId')(CSVData);
};

const formatColTitle = (tableName: string, fieldsToIgnore: string[], item: IStatTableCol) =>
  includes(item.title, fieldsToIgnore) ? item : assoc('title', `${tableName} ${item.title}`, item);

export const formatColHeaderWithTableTitle = (tableName: string, fieldsToIgnore: string[]) =>
  map(partial(formatColTitle, [tableName, fieldsToIgnore]));

export const sortByTeamName = sortBy(prop('teamName'));

export const firstVsSecondTableAsCSV =
  (key: string) =>
  <T>(CSVData: T[]) =>
    pipe(map(omit([key])), generateCSV, attachCSVHeaders)(CSVData);

export const firstVsSecondCSVData = <T extends ITeamNameIndex>(
  tableData: { firstHalf: IHomeAndAwayStats<T>; secondHalf: IHomeAndAwayStats<T> },
  config: IStatTableCol[],
  groupByKey: string = 'playerId'
) =>
  firstVsSecondTableAsCSV(groupByKey)(
    concat(
      formatCSVData<T>(groupByKey, [[tableData.firstHalf, config]]),
      formatCSVData<T>(groupByKey, [[tableData.secondHalf, config]])
    )
  );

// TODO: Make generic - error with '.periodId'
export const removePeriodPenalties = (stat: IShot | IObvXgChain) => stat.periodId !== Period.PENALTIES;
