import React from 'react';
import { styled } from '@statsbomb/react-components';
import { Col, Grid } from 'react-flexbox-grid';
import { ITheme } from '../../types/ITheme';
import { Card } from '../Card.styles';

// TODO: Give this a proper type
export const desktopOnly = <T,>(comp: T) => styled(comp)`
  ${({ theme }: { theme: ITheme }) => theme.mqTo('md')} {
    display: none;
  }
`;

export const mobileOnly = <T,>(comp: T) => styled(comp)`
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: none;
  }
`;

export const GridWrapper = styled(Grid)`
  padding-bottom: ${({ theme }: { theme: ITheme }) => theme.spacing(5)};
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    padding-bottom: 0;
  }
`;

export const DesktopOnlyCol = desktopOnly(Col);
export const MobileOnlyCol = mobileOnly(Col);
export const MobileOnlyCard = mobileOnly(Card);

export const HalfWidthCol = ({ children }: { children: React.ReactNode }) => (
  <Col xs={12} sm={12} md={12} lg={6} xl={6}>
    {children}
  </Col>
);
