/* istanbul ignore file */
import { atom } from 'jotai';
import { atomWithQuery } from 'jotai/query';
import { QueryFunction, QueryKey } from 'react-query';
import { fetchMany } from '../utils/api';
import { selectedTeamId, selectedPlayerId, selectedCompSeason } from './filters';
import { isPlayerKeeper } from '../utils/player';
import { IPlayerSeason } from '../types/player';
import { CompSeason } from '../types/competitionSeason';

export const getPlayersInTeamSeason = atomWithQuery(get => ({
  queryKey: ['getPlayersInTeamSeason', get(selectedCompSeason), get(selectedTeamId)],
  queryFn: (async ({ queryKey: [, compSeasonId, teamId] }): Promise<IPlayerSeason[] | null> => {
    if (!compSeasonId || !teamId) return null;
    return fetchMany(
      `/team/${teamId}/players?competition-id=${(compSeasonId as CompSeason)[0]}&season-id=${
        (compSeasonId as CompSeason)[1]
      }`
    );
  }) as QueryFunction<IPlayerSeason[] | null, QueryKey>,
}));

export const getIsPlayerKeeper = atom(get => {
  const playerId = get(selectedPlayerId);
  if (!playerId || playerId === 0) return false;

  const playerData = get(getPlayersInTeamSeason);
  if (!playerData) return false;

  return playerData.length > 0 ? isPlayerKeeper('primaryPosition', playerId, playerData) : false;
});
