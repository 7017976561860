import React from 'react';

import ChartSettingsFilters from '../../components/filters/ChartSettingsFilters';
import DisplaySettingsFilters from '../../components/filters/DisplaySettingsFilters';
import { GameFiltersComponent } from '../../components/filters/GameFilters';
import { IPassNetworkFilters } from '../../types/passNetwork';

export const tabbedModalConfig: readonly IPassNetworkFilters[] = [
  { name: 'Chart Settings', component: <ChartSettingsFilters /> },
  { name: 'Game Filters', component: <GameFiltersComponent /> },
  { name: 'Display Settings', component: <DisplaySettingsFilters /> },
];
