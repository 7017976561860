/* istanbul ignore file */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const setupHeap = userId => {
  window.heap.identify(userId);
};

const setupSentry = environment => {
  Sentry.init({
    debug: !!Number(process.env.REACT_APP_SENTRY_DEBUG),
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  });
};

export function initialSetup(env, userId) {
  setupHeap(userId);
  setupSentry(window.getEnv());
}
