/* eslint-disable react/prop-types */

import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@statsbomb/react-components';
import { Button, Container, List, Item } from './Pagination.styles';
import { pagesToShow } from './Pagination.helpers';

const ICON_SIZE = 24;

export const Pagination = ({ activePage, onPageChange, itemsPerPage, siblingCount, dataLength }) => {
  const totalPageCount = Math.ceil(dataLength / itemsPerPage);
  const pages = pagesToShow({
    adjacentPages: siblingCount,
    currentPage: activePage,
    lastPage: totalPageCount,
  });

  return (
    <Container aria-label="pagination">
      <List>
        <Item>
          <Button
            aria-label="previous page"
            type="button"
            disabled={activePage <= 1}
            onClick={() => onPageChange({ pageSelected: activePage - 1 })}
          >
            <ChevronLeftIcon width={ICON_SIZE} />
          </Button>
        </Item>
        {pages.map(pageNumber => (
          <Item key={pageNumber}>
            <Button
              aria-label={pageNumber === activePage ? `page ${pageNumber} - active` : `page ${pageNumber}`}
              type="button"
              $isActive={pageNumber === activePage}
              onClick={() => onPageChange({ pageSelected: pageNumber })}
            >
              {pageNumber}
            </Button>
          </Item>
        ))}
        <Item>
          <Button
            aria-label="next page"
            type="button"
            onClick={() => onPageChange({ pageSelected: activePage + 1 })}
            disabled={activePage >= totalPageCount}
          >
            <ChevronRightIcon width={ICON_SIZE} />
          </Button>
        </Item>
      </List>
    </Container>
  );
};
