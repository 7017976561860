import React, { useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Radio } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { gameFilterGameTime, gameFilterSelectedTeam } from '../../atoms/passNetwork';
import * as matchAtoms from '../../atoms/match';
import { gameFilterGameTimeConfig } from '../../config/passNetwork/filters';
import { Period, PeriodName, SelectedTeam } from '../../types/passNetwork';
import { Filter, FilterGroup } from './FilterWrapper.styles';

const GameFilters = () => {
  const [selectedTeam, setSelectedTeam] = useAtom(gameFilterSelectedTeam);
  const [selectedGameTime, setSelectedGameTime] = useAtom(gameFilterGameTime);
  const matchStats = useAtomValue(matchAtoms.stats);
  if (!matchStats) throw new Error('Missing Match ID');
  const {
    meta: { matchId, homeTeamName, awayTeamName, hasExtraTime },
  } = matchStats;

  useEffect(() => {
    setSelectedGameTime(Period.STARTING);
  }, [matchId]);

  const selectedTeamChangeHandler = ({ newValue }: { newValue: SelectedTeam }) => {
    setSelectedTeam(newValue);
  };

  const selectedGameTimeChangeHandler = ({ newValue }: { newValue: Period }) => {
    setSelectedGameTime(Number(newValue));
  };

  const gameTimeWithoutExtraTime = gameFilterGameTimeConfig.filter(
    gamePeriod => gamePeriod !== Period.THIRD && gamePeriod !== Period.FOURTH
  );

  const gameTimes = hasExtraTime ? gameFilterGameTimeConfig : gameTimeWithoutExtraTime;

  return (
    <Filter>
      <FilterGroup>
        <Radio groupTitle="Game Time" groupId="game-time">
          {gameTimes.map(periodId => (
            <Radio.Input
              key={periodId}
              id={`id-${periodId}`}
              label={PeriodName[periodId]}
              value={periodId.toString()}
              checkedValue={selectedGameTime.toString()}
              handleChange={selectedGameTimeChangeHandler}
            />
          ))}
        </Radio>
      </FilterGroup>
      <FilterGroup>
        <Radio groupTitle="Teams" groupId="teams">
          <Radio.Input
            id="id-both"
            label="Both"
            value="both"
            checkedValue={selectedTeam}
            handleChange={selectedTeamChangeHandler}
          />
          <Radio.Input
            id="id-home-team"
            label={homeTeamName}
            value="home"
            checkedValue={selectedTeam}
            handleChange={selectedTeamChangeHandler}
          />
          <Radio.Input
            id="id-away-team"
            label={awayTeamName}
            value="away"
            checkedValue={selectedTeam}
            handleChange={selectedTeamChangeHandler}
          />
        </Radio>
      </FilterGroup>
    </Filter>
  );
};

export const GameFiltersComponent = () => (
  <ErrorBoundary fallback={<h3>Something went wrong</h3>}>
    <GameFilters />
  </ErrorBoundary>
);
