import { any, equals, last, pipe, split, tail, replace, map, join, toUpper, filter, drop, head } from 'ramda';
import { Location } from 'react-router-dom';
import { SectionPrefix, sectionPrefixes } from '../types/Navigation';
import { pagePrefix } from './api';

const isSection = (str: string): str is SectionPrefix => any(equals(str), sectionPrefixes);

export const getSection = ({ pathname }: Location): SectionPrefix => {
  const section = `/${pathname.replace('/_', '').split('/')[1] || ''}`;
  if (isSection(section)) return section;

  throw new Error(`Section not recognised: ${section}`);
};

export const getSegments = (loc: Location): string[] => tail(pipe(location => location.pathname, split('/'))(loc));

export const getSubPage = (loc: Location): string | undefined => last(getSegments(loc));

export const getSamePageURL = (location: Location, newId: string | number) =>
  pagePrefix(`${getSection(location)}/${newId}/${getSubPage(location)}`);

export const generateURL = (path: string, params: Record<string, string>) => `${path}?${new URLSearchParams(params)}`;

const capitalize = replace(/(?:^|\s)\S/g, toUpper);
const titleSegments = (segments: unknown[]) => [pipe(drop(1), head)(segments), last(segments)];

export const createMenuTitle = (location: Location) =>
  pipe(
    getSegments,
    titleSegments,
    // @ts-ignore
    filter(s => !!s),
    map(replace(/-/g, ' ')),
    map(capitalize),
    join(' - ')
  )(location);
