import React from 'react';
import { VizTooltipPlayer } from '../../../types/viz';
import { calcPlayerOnLeftSide } from '../../../utils/passNetwork';
import { TooltipHr, TooltipRow, TooltipTitle, TooltipWrapper } from './PassNetworkTooltips.styles';

const PassNetworkTooltip = ({
  mousePosition,
  playerToDisplay,
  pitchWidth,
  isPortrait,
}: {
  mousePosition: any;
  playerToDisplay: VizTooltipPlayer | null;
  pitchWidth: number;
  isPortrait: boolean;
}) => {
  if (!playerToDisplay) return null;

  const isOnLeftOfPitch = calcPlayerOnLeftSide(playerToDisplay, isPortrait, pitchWidth);
  const tooltip = playerToDisplay.toolTipData.map(item => (
    <TooltipRow key={`${playerToDisplay.playerId}-tooltip-${item.label}`}>
      <span>{item.label}</span>
      <span>{item.value}</span>
    </TooltipRow>
  ));
  return (
    <TooltipWrapper mousePosition={mousePosition} isOnLeftOfPitch={isOnLeftOfPitch} data-testid="event-tooltip">
      <TooltipTitle>{playerToDisplay.playerName}</TooltipTitle>
      <TooltipHr />
      {tooltip}
    </TooltipWrapper>
  );
};

export default PassNetworkTooltip;
