import { ReactElement } from 'react';
import {
  MAX_COMBI_OBV,
  MAX_COMBI_XG,
  MAX_OBV,
  MAX_XG,
  MIN_COMBI_OBV,
  MIN_COMBI_XG,
  MIN_OBV,
  MIN_XG,
} from '../consts/passNetwork';
import { IPass } from './passes';
import { ITeamNameIndex } from './team';
import { ColourTheme } from './vizTheme';

export interface IObvXgChain {
  accountId: number;
  competitionId: number;
  competitionName: string;
  matchId: number;
  obvPass: number;
  passes: number;
  periodId: number;
  playerId: number;
  playerName: string;
  seasonId: number;
  seasonName: string;
  teamId: number;
  teamName: string;
  touches: number;
  x: number;
  xg: number;
  y: number;
}

export interface IObvXgChainFormatted extends Omit<IObvXgChain, 'obvPass'> {
  obv: number;
}

export const obvXgDataModels = ['obv', 'xg'] as const;

export const obxXgAssociatedAttr = {
  obv: { attr: 'obv', max: MAX_OBV, min: MIN_OBV, combinationMax: MAX_COMBI_OBV, combinationMin: MIN_COMBI_OBV },
  xg: { attr: 'xg', max: MAX_XG, min: MIN_XG, combinationMax: MAX_COMBI_XG, combinationMin: MIN_COMBI_XG },
} as const;

export type ObvXgDataModel = typeof obvXgDataModels[number];

export enum Period {
  ALL = 0,
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  PENALTIES = 5,
  FIRST_VS_SECOND = 6,
  STARTING = 7,
}

export const PeriodName: ByPeriod<string> = {
  [Period.ALL]: 'All',
  [Period.FIRST]: 'First Half',
  [Period.SECOND]: 'Second Half',
  [Period.THIRD]: 'Extra Time 1',
  [Period.FOURTH]: 'Extra Time 2',
  [Period.PENALTIES]: 'Penalties',
  [Period.FIRST_VS_SECOND]: 'First Half vs Second Half',
  [Period.STARTING]: 'Starting Eleven',
} as const;

export type ByPeriod<T> = {
  [key in Period]: T;
};

export type SelectedTeam = 'home' | 'away' | 'both';

export const PASSES_BETWEEN_PLAYERS = 'passes-between-players';
export const PASSES_VOLUME = 'pass-volume-between-players';
export const PASS_XG_OBV_BETWEEN_PLAYERS = 'pass-xg-obv-between-players';
export const PASS_VOLUME_PER_PLAYER = 'pass-volume-per-player';
export const PASS_XG_OBV_PER_PLAYER = 'pass-xg-obv-per-player';

export const passFilterNames = [
  PASSES_BETWEEN_PLAYERS,
  PASSES_VOLUME,
  PASS_XG_OBV_BETWEEN_PLAYERS,
  PASS_VOLUME_PER_PLAYER,
  PASS_XG_OBV_PER_PLAYER,
] as const;

export type PassFilterNames = typeof passFilterNames[number];

interface Filter {
  selected: boolean;
  title: string;
}
export type INestedFilter = {
  [key: string]: Filter & {
    children?: INestedFilter;
  };
};

export interface IPassesFilter extends INestedFilter {
  [PASSES_BETWEEN_PLAYERS]: Filter & {
    children: {
      [PASSES_VOLUME]: Filter;
      [PASS_XG_OBV_BETWEEN_PLAYERS]: Filter;
    };
  };
  [PASS_VOLUME_PER_PLAYER]: Filter;
  [PASS_XG_OBV_PER_PLAYER]: Filter;
}

export interface IModelFilter {
  text: string;
  value: ObvXgDataModel;
  selected: boolean;
}

const pitchOrientations = ['portrait', 'landscape'] as const;

export type PitchOrientation = typeof pitchOrientations[number];

export interface IPitchOrientationFilter {
  text: string;
  value: PitchOrientation;
  selected: boolean;
}
const playerPositions = ['Average Pass Location', 'Starting Position'] as const;

export type PlayerPosition = typeof playerPositions[number];
export interface IDisplaySettingPitchMode {
  text: string;
  value: ColourTheme;
}

export interface IPassNetworkFilters {
  name: string;
  component: ReactElement;
}

export type IPassNetworkVizPasses = IObvXgChainFormatted & { passesRadius: number; touchesRadius: number };
export type IPassNetworkVizPassCombinations = IPass & ITeamNameIndex;

export type IMergedPassAndCombinations = IPassNetworkVizPassCombinations & {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  stroke: string;
  strokeWidth: number;
  key: string;
  teamId: number;
  playerId: number;
  opacity: number;
};

interface KeyText {
  min: string;
  max: string;
}

interface PassNetworkKeyItems {
  circleColourText: KeyText;
  circleSizeText: (selectedPeriod: Period) => KeyText;
  lineText: (selectedMinPasses: number, selectedPeriod: Period) => KeyText;
}

export type ObvXgKeyLookup = {
  [key in ObvXgDataModel]: PassNetworkKeyItems;
};
export interface PitchTitleByPeriod {
  periodId: Period;
  teamId: number;
  title: string;
}

export type VizPassesWithUniqueValue = IPassNetworkVizPasses & { uniqueValue: string };

export type ModelDataByPeriod = Partial<ByPeriod<Array<VizPassesWithUniqueValue>>>;

export const PASS_NETWORK_VIZ_KEYS = ['PASS_VOLUME_PER_PLAYER', 'PASS_VOLUME', 'PASS_OBV_XG_PER_PLAYER'] as const;

export type PassNetworkVizKeys = typeof PASS_NETWORK_VIZ_KEYS[number];

export interface TeamLookup {
  [key: number]: string;
}

export type PitchesLength = 1 | 2 | 4;
