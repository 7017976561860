import React from 'react';
import { DropdownSelectList, ThreeSixtyIcon } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import * as filters from '../../atoms/filters';
import * as matchAtoms from '../../atoms/match';
import { DropdownClickHandler, IMatchOption, IOption } from '../../types/dropdown';
import { IMatchSummary } from '../../types/match';
import { Icon } from './MatchFilter.styles';
import { CompSeasonOptionInner, TextWithEllipsis } from './CompSeasonFilter.styles';
import { filterDropdownOptions, hyphenDateFromTZ } from '../../utils/general';
import { BoldSpan } from '../typography/Typography.styles';

const DropdownList = DropdownSelectList;
const { Option } = DropdownList.Components;

export const formatMatchNameForSearch = (match: IMatchSummary) =>
  `${match.homeTeam} vs ${match.awayTeam}, ${hyphenDateFromTZ(match.date)}`;

export const formatMatchNameForDisplay = (match: IMatchSummary, selectedTeamId: number) => {
  const { homeTeam, homeTeamId, awayTeam, date } = match;
  const isHomeTeamSelected = homeTeamId === selectedTeamId;
  const formattedDate = hyphenDateFromTZ(date);
  return (
    <span data-testid="match-option">
      <BoldSpan $isBold={isHomeTeamSelected}>{homeTeam}</BoldSpan> vs{' '}
      <BoldSpan $isBold={!isHomeTeamSelected}>{awayTeam}</BoldSpan>, {formattedDate}
    </span>
  );
};

export const toolTipAvailable360 = '360 data is available for this match';
export const toolTipNotAvailable360 = '360 data is coming soon for this match';

const MatchOption = ({ data, ...props }: { data: IMatchOption }) => (
  <Option {...props}>
    <CompSeasonOptionInner>
      <TextWithEllipsis title={data?.searchKey} $width={80}>
        {data?.label}
      </TextWithEllipsis>
      {data.has360License && (
        <Icon title={data.has360 ? toolTipAvailable360 : toolTipNotAvailable360} has360={data.has360}>
          <ThreeSixtyIcon width={20} />
        </Icon>
      )}
    </CompSeasonOptionInner>
  </Option>
);

export const MatchFilterInner = ({
  matches,
  selectedMatch,
  onSelectMatch,
  selectedTeamId,
}: {
  matches: IMatchSummary[];
  selectedMatch?: IMatchSummary;
  onSelectMatch: DropdownClickHandler<IOption>;
  selectedTeamId?: number;
}) => {
  if (!selectedTeamId) return null;
  return (
    <DropdownList
      components={{ Option: MatchOption }}
      label="Match"
      name="match-select"
      filterOption={filterDropdownOptions}
      options={matches.map((match: IMatchSummary) => ({
        value: match.matchId,
        label: formatMatchNameForDisplay(match, selectedTeamId),
        searchKey: formatMatchNameForSearch(match),
        has360: match.has360 || false,
        has360License: match.has360License || false,
      }))}
      placeholder="Select a Match"
      value={
        selectedMatch
          ? {
              label: formatMatchNameForDisplay(selectedMatch, selectedTeamId),
              value: selectedMatch.matchId,
            }
          : null
      }
      onSelect={onSelectMatch}
    />
  );
};

export const MatchFilter = ({ onSelectMatch }: { onSelectMatch: DropdownClickHandler<IOption> }) => {
  const [matchId] = useAtom(filters.selectedMatchId);
  const matches = useAtomValue(matchAtoms.matchesForTeam) as IMatchSummary[];
  const selectedTeamId = useAtomValue(filters.selectedTeamId);

  const selectedMatch = matches.find((match: IMatchSummary) => match.matchId === matchId);

  return <MatchFilterInner {...{ matches, selectedMatch, onSelectMatch, selectedTeamId }} />;
};
