import { atom } from 'jotai';
import { atomWithQuery } from 'jotai/query';
import { IUserAtom, IUser, LoginType } from '../types/IUser';
import { fetchOne } from '../utils/api';

export const user = atom<IUserAtom>({ error: false, user: null });

const url = '/user';

export const getLoggedinUser = atomWithQuery(() => ({
  queryKey: 'getLoggedinUser',
  queryFn: async (): Promise<IUser> => (await fetchOne(url)) as IUser,
}));

export const getUserRole = atom(get => (get(getLoggedinUser)?.loginType || 'user') as LoginType);
export const getUserId = atom(get => get(getLoggedinUser).loginId);
