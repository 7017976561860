import { atomWithQuery } from 'jotai/query';
import { map } from 'ramda';
import { fetchMany } from '../utils/api';
import { selectedTeamId } from './filters';
import { IMatch } from '../types/match';

const param = ':team-id';
const url = `/team/${param}/fixtures`;

export const fixMatchId = (protoMatch: Omit<IMatch, 'matchId'> & { gameId: number }) => ({
  ...protoMatch,
  matchId: protoMatch.gameId,
});

export const getFixturesForTeam = atomWithQuery(get => ({
  queryKey: ['fixtures', get(selectedTeamId)],
  queryFn: async ({ queryKey: [, teamId] }): Promise<IMatch[]> =>
    map(fixMatchId)(await fetchMany(url.replace(`${param}`, `${teamId}`))),
}));
