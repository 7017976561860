import { TEXT_ALIGNMENT } from '../../consts/typography';

export const substitutions = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Period',
    toolTip: 'Period',
    stat: 'period',
  },
  {
    title: 'Minute',
    toolTip: 'Substitution minute',
    stat: 'eventMinute',
  },
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player off',
    toolTip: 'Player substituted off',
    stat: 'playerOffName',
  },
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player on',
    toolTip: 'Player substituted on',
    stat: 'playerOnName',
  },
];
