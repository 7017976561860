import { Scale } from '../types/viz';

// default 5-point domain, used by several scales
const DEFAULT_DOMAIN = [0, 0.25, 0.5, 0.75, 1];

// Magma, Plasma and Viridis come from:
// https://www.thinkingondata.com/something-about-viridis-library/

export const MAGMA: Scale = {
  colours: ['#000004', '#51127c', '#b73779', '#fc8961', '#fcfdbf'],
  domain: DEFAULT_DOMAIN,
};

export const PLASMA: Scale = {
  colours: ['#0d0887', '#7e03a8', '#cc4778', '#f89540', '#f0f921'],
  domain: DEFAULT_DOMAIN,
};

export const VIRIDIS: Scale = {
  colours: ['#440154', '#3b528b', '#21918c', '#5ec962', '#fde725'],
  domain: DEFAULT_DOMAIN,
};

export const POSEIDON: Scale = {
  colours: ['#520073', '#57359b', '#6385b3', '#71d2d6', '#94ffe5'],
  domain: DEFAULT_DOMAIN,
};

// not a grayscale, I just named it this so it's confusing for other people
export const GRAY: Scale = {
  colours: ['#474e63', '#5f5252', '#ec8638', '#ffe100'],
  domain: [0, 0.2, 0.7, 1],
};

// scale used on the old pass network but with the whitish value taken out.
export const PASS_NETWORK: Scale = {
  colours: [
    'rgb(42, 93, 159)',
    'rgb(93, 189, 211)',
    // 'rgb(231, 248, 230)',
    'rgb(253, 233, 96)',
    'rgb(240, 152, 62)',
    'rgb(213, 79, 27)',
    'rgb(191, 0, 0)',
  ],
  domain: [0, 0.2, /* 0.4, */ 0.5, 0.6, 0.8, 1],
};

export const LINEAR: Scale = {
  colours: ['#16969911', '#169699'],
  domain: [0, 1],
};
