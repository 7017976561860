import {
  map,
  keys,
  join,
  pipe,
  head,
  chain,
  uniq,
  prop,
  propEq,
  filter,
  mergeAll,
  assoc,
  reduce,
  reject,
  equals,
  juxt,
} from 'ramda';

const quote = (col: unknown) => `"${col ?? 0}"`;

export const generateCSV = <T extends Record<string, unknown>>(list: T[]) => {
  const colNames = keys(head(list));
  const cols = map(quote, colNames);

  const valueSelectorFn = juxt(map(prop)(colNames));
  const quotedValues = pipe(valueSelectorFn, map(quote));

  const vals = pipe(map(quotedValues), join('\r\n'))(list);

  return encodeURI(`${cols}${'\r\n'}${vals}`);
};

export const attachCSVHeaders = (csv: string) => `data:text/csv;charset=utf-8,%EF%BB%BF${csv}`;

export const mergeObjectsForCSVBy = (key: string) => (objects: {}[]) => {
  const uniqKeys = reject(equals(key), uniq(chain(keys, objects)));
  const primaryKeys = uniq(map(prop(key), objects));
  return map(pk => {
    const rows: Record<string, unknown>[] = filter(propEq(key, pk), objects);
    return reduce((newRow, uk) => assoc(uk, mergeAll(rows)[uk], newRow), {}, uniqKeys);
  }, primaryKeys);
};
