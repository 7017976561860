import React from 'react';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useLocation, Location } from 'react-router-dom';
import { searchResults, searchResultsOpen } from '../../atoms/search';
import { SearchResults, SearchResultItem, SearchResultTitle, SearchModal } from './SearchResults.styles';
import { SectionPrefix } from '../../types/Navigation';
import { navConfig } from '../../config/navigation';
import { getSamePageURL, getSection } from '../../utils/navigation';
import { ISearchSection } from '../../types/search';

const generateSearchUrl = (prefix: SectionPrefix, id: number, location: Location) => {
  if (prefix === getSection(location)) return getSamePageURL(location, id);

  return navConfig
    .filter(section => section.sections.some(sections => sections === prefix))[0]
    .links[0].href.replace(/:\w+Id/, `${id}`); // This is not robust and we should remove it when we do search properly
};

interface ISearchResultsProps {
  results: ISearchSection[];
  open: boolean;
  setOpen: (arg0: boolean) => void;
  location: Location;
}

export const SearchResultsModalInner = ({ results, open, setOpen, location }: ISearchResultsProps) => {
  if (!open) return null;

  return (
    <SearchModal onClick={() => setOpen(false)}>
      <SearchResults>
        {results.map((section, sectionIndex) => (
          <React.Fragment key={`search-results-${section.id}`}>
            <SearchResultTitle $empty={!!section.items.length}>
              {section.items.length} {section.items.length === 1 ? section.title[0] : section.title[1]}
            </SearchResultTitle>
            {section.items.map((item, itemIndex) => (
              <SearchResultItem
                key={item.id}
                tabIndex={sectionIndex * 10 + itemIndex}
                href={generateSearchUrl(
                  `/${item.type.replace('goalkeeper', 'keeper')}` as SectionPrefix,
                  item.id,
                  location
                )}
              >
                {item.name}
              </SearchResultItem>
            ))}
          </React.Fragment>
        ))}
      </SearchResults>
    </SearchModal>
  );
};

export const SearchResultsModal = () => {
  const results = useAtomValue(searchResults);
  const [open, setOpen] = useAtom(searchResultsOpen);
  const location = useLocation();
  return <SearchResultsModalInner {...{ results, open, setOpen, location }} />;
};
