import { partial } from 'ramda';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { TABLE_SORT_STATES } from '../../types/table';
import { sortItems } from '../../utils/table';

export const obv = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player',
    toolTip: 'Player Name',
    stat: 'playerName',
    sortableFn: partial(sortItems, ['playerName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OBV',
    toolTip: 'OBV',
    stat: 'obv',
    toFixed: 2,
    sortableFn: partial(sortItems, ['obv']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Pass OBV',
    toolTip: 'Pass OBV',
    stat: 'obvPass',
    toFixed: 2,
    sortableFn: partial(sortItems, ['obvPass']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'D&C OBV',
    toolTip: 'Dribble & Carry OBV',
    stat: 'obvDribbleCarry',
    toFixed: 2,
    sortableFn: partial(sortItems, ['obvDribbleCarry']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'DA OBV',
    toolTip: 'Defensive Action OBV',
    stat: 'obvDefensiveAction',
    toFixed: 2,
    sortableFn: partial(sortItems, ['obvDefensiveAction']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Shot OBV',
    toolTip: 'Shot OBV',
    stat: 'obvShot',
    toFixed: 2,
    sortableFn: partial(sortItems, ['obvShot']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'GK OBV',
    toolTip: 'GK OBV',
    stat: 'obvGk',
    toFixed: 2,
    sortableFn: partial(sortItems, ['obvGk']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
];
