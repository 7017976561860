import React, { forwardRef, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import { Button as StyledDownloadButton, DownloadIcon } from '../button/Button.styles';
import { isSafari } from '../../utils/general';

export const download = (element: HTMLElement | null) => {
  if (!element) return;
  element.click();
};

export const DownloadButton = ({
  onClick = () => Promise.resolve(),
  fileContents,
  title,
  children,
}: {
  onClick?: () => Promise<void>;
  fileContents: string;
  title: string;
  children?: React.ReactNode;
}) => {
  const aRef = useRef(null);

  return (
    <StyledDownloadButton
      data-testid={`download-${title}`}
      name="Download"
      onClick={async () => {
        await onClick();
        if (aRef.current) download(aRef.current);
      }}
      type="button"
    >
      <DownloadIcon width={30} />
      {children && <span>{children}</span>}
      <a style={{ display: 'none' }} ref={aRef} onClick={e => e.stopPropagation()} href={fileContents} download={title}>
        Download
      </a>
    </StyledDownloadButton>
  );
};

export const DownloadImgButton = forwardRef<
  HTMLElement,
  { title: string; onDownloadComplete: Function; children: React.ReactNode }
>(({ title, onDownloadComplete, children }, ref) => {
  const vizRef = ref as React.MutableRefObject<HTMLElement>;
  const [imgData, setImgData] = useState('');

  const generateAndSetImgData = async () => {
    if (!vizRef.current) return;
    const image = await toPng(vizRef.current);

    /**
     * On safari, the first time you hit download, the image is incomplete,
     * so we regenerate this image until we get two that are the same.
     */
    const getSafariDownloadImage = async (firstImage: string): Promise<string> => {
      const secondImage = await toPng(vizRef.current);
      if (firstImage.length === secondImage.length) {
        return secondImage;
      }
      return getSafariDownloadImage(secondImage);
    };

    const imageData = isSafari(navigator.userAgent) ? await getSafariDownloadImage(image) : image;

    setImgData(imageData);
    onDownloadComplete();
  };

  return (
    <DownloadButton onClick={generateAndSetImgData} title={title} fileContents={imgData}>
      {children}
    </DownloadButton>
  );
});
