import React from 'react';
import { useAtom } from 'jotai';
import { ToggleButton } from '@statsbomb/react-components';
import { pitchOrientationFilters } from '../../atoms/passNetwork';
import { IPitchOrientationFilter } from '../../types/passNetwork';
import { Title } from './ModelFilters.styles';

const PitchOrientationFilter = () => {
  const [orientations, setOrientations] = useAtom(pitchOrientationFilters);

  const pitchOrientationHandleChange = (orientation: IPitchOrientationFilter) =>
    setOrientations(
      orientations.map(o => (o.value === orientation.value ? { ...o, selected: true } : { ...o, selected: false }))
    );
  return (
    <>
      <Title>Pitch Orientation</Title>
      <ToggleButton.Container>
        {orientations.map(orientation => {
          const { value, text, selected } = orientation;

          return (
            <ToggleButton.Button
              key={value}
              type="button"
              aria-pressed={selected}
              active={selected}
              value={value}
              onClick={() => pitchOrientationHandleChange(orientation)}
            >
              {text}
            </ToggleButton.Button>
          );
        })}
      </ToggleButton.Container>
    </>
  );
};

export default PitchOrientationFilter;
