import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

export const Title = styled.span`
  background-color: ${({ theme }: { theme: ITheme }) => `${theme.colours.brand.interface.ink}`};
  color: ${({ theme }: { theme: ITheme }) => `${theme.colours.graphics.secondary.ink}`};
  font-weight: bold;
  font-size: ${({ theme }: { theme: ITheme }) => `${theme.typography.heading3.size}`};
  display: flex;
  text-transform: capitalize;
`;
