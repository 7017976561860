export const hyphenDateFromTZ = (date: string) => date.split('T')[0];

export const timeFromTZ = (date: string) => {
  const [hour, min] = date.split('T')[1].split(':');
  return [hour, min].join(':');
};

export const hyphenDateTimeFromTZ = (date: string) => `${hyphenDateFromTZ(date)} ${timeFromTZ(date)}`;

export const filterDropdownOptions = (
  { data: { value, searchKey } }: { data: { value: number | string; searchKey: string } },
  searchTerm: string
) => searchKey.toUpperCase().includes(searchTerm.toUpperCase()) || value.toString().includes(searchTerm);

export const noOp = () => {};

export const isSafari = (userAgent: string) => /^((?!chrome|android).)*safari/i.test(userAgent);
