import React from 'react';
import { range } from 'ramda';
import { keyText } from '../../../../config/passNetwork/key';
import { ObvXgDataModel } from '../../../../types/passNetwork';
import { Scale } from '../../../../types/viz';
import { DEFAULT_OPACITY } from '../../../../consts/passNetwork';
import { chromaValue } from '../../../../utils/viz/scales';
import { KeyText } from './Key.styles';
import { KEY_ARROW_Y_POSITION, KEY_CIRCLE_Y_POSITION } from '../../../../consts/viz';
import KeyElement from './KeyElement';

export const ObvXgKey = ({
  selectedModel,
  selectedScale,
  strokeLineWidth,
}: {
  selectedModel: ObvXgDataModel;
  selectedScale: Scale;
  strokeLineWidth: number;
}) => {
  const { circleColourText } = keyText[selectedModel];

  const circleRadius = 3.75;
  const circleSpacing = 9.25;
  const padding = 0.25;

  return (
    <div>
      <KeyElement>
        {range(0, 5).map(index => (
          <g
            transform={`translate(${circleRadius + padding + index * circleSpacing} 0)`}
            stroke={chromaValue(selectedScale, index * 0.25)}
            fill={chromaValue(selectedScale, index * 0.25)}
            strokeWidth={strokeLineWidth}
          >
            <circle r={circleRadius} cx={0} cy={KEY_CIRCLE_Y_POSITION} fillOpacity={DEFAULT_OPACITY} />
            <line x1={-circleRadius} y1={KEY_ARROW_Y_POSITION} x2={circleRadius} y2={KEY_ARROW_Y_POSITION} />
          </g>
        ))}
      </KeyElement>
      <KeyText>
        <p>{circleColourText.min}</p>
        <p>{circleColourText.max}</p>
      </KeyText>
    </div>
  );
};
