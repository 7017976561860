import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PassNetworkPage } from '../components/pages/match/PassNetwork';
import { MatchStatsPage } from '../components/pages/match/MatchStats';

export const MatchRoutes = () => (
  <Routes>
    <Route path="match-stats" element={<MatchStatsPage />} />
    <Route path="pass-network" element={<PassNetworkPage />} />
    <Route path="*" element={<></>} />
  </Routes>
);
