import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChildWrapper = styled.div`
  padding-left: ${({ theme }: { theme: ITheme }) => `${theme.spacing(1)}`};
`;

export const SwitchContainer = styled.div`
  margin-bottom: ${({ theme }: { theme: ITheme }) => `${theme.spacing(1)}`};
`;
