import { Atom, atom } from 'jotai';
import { atomWithQuery } from 'jotai/query';
import { map } from 'ramda';
import { QueryFunction, QueryKey } from 'react-query';
import { ISearchSection, resultType, ResultType, SearchResults } from '../types/search';
import { fetchMany } from '../utils/api';
import { debounce } from '../utils/debounce';
import { generateURL } from '../utils/navigation';

export const searchQueryForUI = atom('');
export const searchQueryForAPI = atom('');
export const searchResultsOpen = atom(false);

export const debouncedSet = debounce(
  (set: (a: Atom<unknown>, v: unknown) => void, atomToUpdate: Atom<unknown>, value: unknown) =>
    set(atomToUpdate, value),
  300
);

export const searchQuery = atom(
  get => get(searchQueryForUI),
  (_, set, newQuery: string) => {
    set(searchQueryForUI, newQuery);
    set(searchResultsOpen, true);
    debouncedSet(set, searchQueryForAPI, newQuery);
  }
);

export const searchResultsRaw = atomWithQuery(get => ({
  queryKey: ['matchPlayerStats', get(searchQueryForAPI)],
  queryFn: (async <T extends any>({ queryKey: [, query] }: { queryKey: unknown[] }): Promise<T[] | null> => {
    if (!query) return null;
    return (await fetchMany(generateURL(`/search`, { q: query as string }))) as T[];
  }) as QueryFunction<SearchResults | null, QueryKey>,
}));

const searchTitleMap: Record<ResultType, string[]> = {
  player: ['Player', 'Players'],
  goalkeeper: ['Keeper', 'Keepers'],
  match: ['Match', 'Matches'],
  team: ['Team', 'Teams'],
  competition: ['League', 'Leagues'],
};

export const searchResults = atom<ISearchSection[]>(get => {
  const raw = get(searchResultsRaw);

  return map(
    type => ({
      id: type,
      title: searchTitleMap[type],
      items: raw?.filter(result => result.type === type) || [],
    }),
    resultType
  );
});
