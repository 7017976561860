import { partial } from 'ramda';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { TABLE_SORT_STATES } from '../../types/table';
import { sortItems } from '../../utils/table';

const obvXgChain = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player',
    toolTip: 'Player Name',
    stat: 'playerName',
    sortableFn: partial(sortItems, ['playerName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Touches',
    toolTip: 'Touches',
    stat: 'touches',
    toFixed: 0,
    sortableFn: partial(sortItems, ['touches']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Passes',
    toolTip: 'Passes',
    stat: 'passes',
    toFixed: 0,
    sortableFn: partial(sortItems, ['passes']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'OBV',
    toolTip: 'OBV',
    stat: 'obv',
    toFixed: 2,
    sortableFn: partial(sortItems, ['obv']),
    order: TABLE_SORT_STATES.DESC,
  },
  {
    title: 'xG Chain',
    toolTip: 'xG',
    stat: 'xg',
    toFixed: 2,
    sortableFn: partial(sortItems, ['xg']),
    order: TABLE_SORT_STATES.DESC,
  },
];

const obvStats = ['playerName', 'passes', 'obv'];
const xgChainStats = ['playerName', 'touches', 'xg'];

export const obv = obvXgChain.filter(column => obvStats.includes(column.stat));
export const xgChain = obvXgChain.filter(column => xgChainStats.includes(column.stat));
