import React from 'react';
import { useAtomValue } from 'jotai/utils';
import { useStatTableSort } from '../../../hooks/useStatTableSort';
import { nonSelectedDataModel } from '../../../atoms/passNetwork';
import { HalfWidthCol } from '../../gridVariants/gridVariants';
import TableWithTitle from '../TableWithTitle';
import HomeAndAwayTableFirstVsSecond, { IHomeAndAwayTableProps } from './HomeAndAwayTableFirstVsSecond';

export const HomeAndAwayTable = <T extends Record<string, number | string>>({
  stats,
  columnMetaData,
  field,
  meta,
  multiSort = false,
  isHeadSticky = false,
  stickyColumns,
  hideNonSelectedModel = false,
}: IHomeAndAwayTableProps<T> & { hideNonSelectedModel?: boolean }) => {
  const { homeData, awayData } = useAtomValue(stats);
  const nonSelectedModel = useAtomValue(nonSelectedDataModel).value;
  const modelToHide = hideNonSelectedModel ? nonSelectedModel : null;

  const [homeDataFormatted, colsToSort, sortCb] = useStatTableSort(multiSort, columnMetaData, homeData, modelToHide);
  const [awayDataFormatted, colsToSortAway, sortCbAway] = useStatTableSort(
    multiSort,
    columnMetaData,
    awayData,
    modelToHide
  );

  return (
    homeData &&
    awayData && (
      <>
        <HalfWidthCol>
          <TableWithTitle
            title={meta.homeTeamName}
            stats={homeDataFormatted}
            columnMetaData={colsToSort}
            field={field}
            sortCb={sortCb}
            isHeadSticky={isHeadSticky}
            stickyColumns={stickyColumns}
          />
        </HalfWidthCol>
        <HalfWidthCol>
          <TableWithTitle
            title={meta.awayTeamName}
            stats={awayDataFormatted}
            columnMetaData={colsToSortAway}
            field={field}
            sortCb={sortCbAway}
            isHeadSticky={isHeadSticky}
            stickyColumns={stickyColumns}
          />
        </HalfWidthCol>
      </>
    )
  );
};

/**
 * Either shows 2 tables:
 *  (home vs away)
 * or 4:
 *  (home first half vs away first half,
 *   home second half vs away second half)
 */
export const SomeHomeAwayTables = <T extends Record<string, string | number>>({
  showFirstVsSecond,
  ...props
}: IHomeAndAwayTableProps<T> & { showFirstVsSecond: boolean; hideNonSelectedModel: boolean }) =>
  showFirstVsSecond ? <HomeAndAwayTableFirstVsSecond {...props} /> : <HomeAndAwayTable {...props} />;

export default HomeAndAwayTable;
