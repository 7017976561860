import { atom } from 'jotai';
import { CompSeason } from '../types/competitionSeason';
import { generateCompSeasonId } from '../utils/competitionSeasons';
import { firstOutfielderFromSquad } from '../utils/player';
import { getFixturesForTeam } from './fixturesForTeam';
import { getPlayersInTeamSeason } from './playersInTeam';
import { getLoggedinUser } from './user';

export const playerId = atom<number | null>(null);
export const teamId = atom<number | null>(null);
export const matchId = atom<number | null>(null);
export const compSeason = atom<CompSeason | null>(null); // [comp, season]

export const selectedCompSeason = atom<CompSeason, CompSeason>(
  get => {
    const cs = get(compSeason);
    if (cs) return cs;

    const firstFixture = get(getFixturesForTeam)[0];
    if (!firstFixture) throw Error(`Team has no fixtures`);

    return [firstFixture.competitionId, firstFixture.seasonId] as const;
  },
  (get, set, newCompSeason: CompSeason) => set(compSeason, newCompSeason)
);

export const selectedCompSeasonId = atom(get => {
  const cs = get(selectedCompSeason);
  return generateCompSeasonId(cs);
});

export const selectedTeamId = atom(
  get => (get(teamId) || get(getLoggedinUser)?.teamId) ?? 0,
  (_, set, newTeamId: number) => set(teamId, newTeamId)
);

export const selectedPlayerId = atom<number | null, number>(
  get => {
    const player = get(playerId);
    if (player) return player;

    const playersInTeamSeason = get(getPlayersInTeamSeason);
    if (!playersInTeamSeason || !playersInTeamSeason.length) return null;

    return firstOutfielderFromSquad(playersInTeamSeason).playerId;
  },
  (_, set, newPlayerId) => set(playerId, newPlayerId)
);

export const selectedMatchId = atom(
  get => get(matchId) || get(getFixturesForTeam)[0]?.matchId,
  (_, set, newMatchId: number | null) => set(matchId, newMatchId)
);
