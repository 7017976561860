/* eslint-disable dot-notation */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'jotai';
import { IntlProvider } from 'react-intl';
import { iqTheme, ThemeProvider } from '@statsbomb/react-components';
import { BrowserRouter as Router } from 'react-router-dom';
import messagesFormatted from './lang';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoadingSpinner from './components/loadingSpinner/LoadingSpinner';

const locale = 'en-GB';
const messages = messagesFormatted[locale];

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={iqTheme.light}>
      <IntlProvider messages={messages} defaultLocale={locale} locale={locale}>
        <Provider>
          <Router>
            {/* 
                TODO: Potentially make this better?
                e.g., spinner in centre or something.
                This is here for now as we make a call to the atom
                and it is not set yet.
            */}
            <Suspense fallback={<LoadingSpinner />}>
              <App />
            </Suspense>
          </Router>
        </Provider>
      </IntlProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
