import React from 'react';
import { useAtom } from 'jotai';
import { ToggleButton } from '@statsbomb/react-components';
import { modelFilters } from '../../atoms/passNetwork';
import { IModelFilter } from '../../types/passNetwork';
import { Title } from './ModelFilters.styles';

const ModelFilter = () => {
  const [models, setModels] = useAtom(modelFilters);

  const handleChange = (model: IModelFilter) =>
    setModels(models.map(m => (m.value === model.value ? { ...m, selected: true } : { ...m, selected: false })));

  return (
    <>
      <Title>Data Model</Title>
      <ToggleButton.Container>
        {models.map(model => {
          const { value, text, selected } = model;

          return (
            <ToggleButton.Button
              key={value}
              type="button"
              aria-pressed={selected}
              active={selected}
              value={value}
              onClick={() => handleChange(model)}
            >
              {text}
            </ToggleButton.Button>
          );
        })}
      </ToggleButton.Container>
    </>
  );
};

export default ModelFilter;
