import { styled } from '@statsbomb/react-components';
import { PITCH_DOWNLOAD_WIDTH } from '../../consts/download';
import { TOP_NAV_HEIGHT } from '../../consts/navigation';
import { ZINDEX } from '../../consts/zIndex';
import { ITheme, Themed } from '../../types/ITheme';

/**
 * TODO-later:
 * This is technically a generic grayish background for modals with a low
 * opacity so could be used for all our future generic modals once we have more.
 */
export const Background = styled.aside(({ theme, $isHidden }: { theme: ITheme; $isHidden: boolean }) => ({
  display: $isHidden ? 'none' : 'block',
  zIndex: ZINDEX.modals[0],
  position: 'fixed',
  top: TOP_NAV_HEIGHT,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.colours.overlay,
}));

/**
 * TODO-later:
 * This is technically a generic modal styling
 * so could be used for all our future generic modals once we have more.
 */
export const Modal = styled.div(({ theme }: Themed) => ({
  position: 'absolute',
  top: '47.5%',
  left: '50%',
  width: '72%',
  maxHeight: '80vh',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colours.canvas.secondary,
  boxShadow: theme.effects.dropShadow.regular,
}));

export const ModalHeader = styled.div(({ theme }: Themed) => ({
  height: '5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5),
  borderBottom: `thin solid ${theme.colours.graphics.secondary.light}`,
  fontWeight: theme.typography.heading2.weight,
  fontSize: theme.typography.heading2.size,
}));

export const ModalContentWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  minWidth: PITCH_DOWNLOAD_WIDTH,
  width: '100%',
  pointerEvents: 'none',
}));

export const ModalContent = styled.div(({ theme }: Themed) => ({
  backgroundColor: theme.colours.canvas.primary,
  padding: theme.spacing(1.5),
  overflow: 'auto',
  maxHeight: '70vh',
}));

export const ModalFooter = styled.div(({ theme }: Themed) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: theme.spacing(2),
  borderTop: `thin solid ${theme.colours.graphics.secondary.light}`,
  backgroundColor: theme.colours.canvas.secondary,
}));
