import React, { Suspense, useEffect } from 'react';
import { SearchIcon } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { identity } from 'ramda';
import { useParams, useLocation, useNavigate, Location, useSearchParams, NavigateFunction } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import * as filterAtoms from '../../atoms/filters';
import { getFixturesForTeam } from '../../atoms/fixturesForTeam';
import * as matchAtoms from '../../atoms/match';
import { isDesktopMenuExpanded as isDesktopMenuExpandedAtom, isMobileFiltersOpen } from '../../atoms/navigation';
import * as search from '../../atoms/search';
import { generateNavigation, navConfig } from '../../config/navigation';
import { MatchRoutes } from '../../routes/MatchRoutes';
import { DropdownClickHandler, IOption, IOptionCS } from '../../types/dropdown';
import { IMatch, IMatchDetails, IMatchSummary } from '../../types/match';
import { splitCompSeasonId } from '../../utils/competitionSeasons';
import { getBestMatchInCS, getPlayedMatchesForTeam, matchContainsTeam } from '../../utils/match';
import { generateURL, getSamePageURL } from '../../utils/navigation';
import { CompSeasonFilter, CompSeasonFilterInner } from '../filters/CompSeasonFilter';
import { MatchFilter, MatchFilterInner } from '../filters/MatchFilter';
import { TeamFilter, TeamFilterInner } from '../filters/TeamFilter';
import { SideNavigationLogic, SideNavigationInner } from '../navigation/SideNavigation';
import MatchSwapTeamButton from '../navigation/MatchSwapTeamButton';
import TopNavContainer from '../navigation/TopNavContainer';
import { SearchInput, SearchPlaceholder } from '../search/SearchPlaceholder.styles';
import { Section } from './Section.styles';
import { Split } from '../Split.styles';
import { MobileFilters } from '../filters/MobileFilters';
import { MobileSubnavMatchSection } from '../navigation/MobileSubnav';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { noOp } from '../../utils/general';

interface MatchSectionInnerProps {
  isMenuOpen: boolean;
  isDesktopMenuExpanded: boolean;
  setMatchId: (update: number) => void;
  setCompSeason: (update: [number, number]) => void;
  setTeamId: (update: number) => void;
  onClickSwapTeam: () => void;
  matchIdParam?: string;
  teamIdParam: string | null;
  matchDetails: IMatchDetails | null;
  searchQuery: string;
  setSearchQuery: (update: string) => void;
  onSelectMatch: DropdownClickHandler<IOption>;
  onSelectTeam: DropdownClickHandler<IOption>;
  onSelectCompSeason: DropdownClickHandler<IOptionCS>;
}

type MatchSectionFiltersProps = Pick<
  MatchSectionInnerProps,
  'onClickSwapTeam' | 'onSelectMatch' | 'onSelectTeam' | 'onSelectCompSeason'
>;

const MatchSectionFilters = (props: MatchSectionFiltersProps) => {
  const { onSelectMatch, onClickSwapTeam, onSelectTeam, onSelectCompSeason } = props;
  return (
    <>
      <Suspense fallback={<MatchFilterInner matches={[]} onSelectMatch={identity} />}>
        <MatchFilter onSelectMatch={onSelectMatch} />
      </Suspense>
      <MatchSwapTeamButton {...{ onClick: onClickSwapTeam }} />
      <Suspense fallback={<TeamFilterInner teams={[]} onSelectTeam={identity} />}>
        <TeamFilter {...{ onSelectTeam }} />
      </Suspense>
      <Suspense fallback={<CompSeasonFilterInner compSeasons={[]} onSelectCompSeason={identity} />}>
        <CompSeasonFilter {...{ onSelectCompSeason }} />
      </Suspense>
    </>
  );
};

export const MatchSectionInner = ({
  isMenuOpen,
  isDesktopMenuExpanded,
  setMatchId,
  setCompSeason,
  setTeamId,
  onClickSwapTeam,
  matchIdParam,
  teamIdParam,
  matchDetails,
  searchQuery,
  setSearchQuery,
  onSelectMatch,
  onSelectTeam,
  onSelectCompSeason,
}: MatchSectionInnerProps) => {
  useEffect(() => {
    if (matchIdParam) setMatchId(+matchIdParam);
  }, [matchIdParam]);

  useEffect(() => {
    if (teamIdParam) setTeamId(+teamIdParam);
  }, [teamIdParam]);

  useEffect(() => {
    if (!matchDetails) return;
    setCompSeason([matchDetails.competitionId, matchDetails.seasonId]);
    if (!teamIdParam) setTeamId(matchDetails.matchHomeTeamId);
  }, [matchDetails]);

  return (
    <>
      <Suspense fallback={<TopNavContainer />}>
        <TopNavContainer>
          <MatchSectionFilters
            onSelectMatch={onSelectMatch}
            onClickSwapTeam={onClickSwapTeam}
            onSelectTeam={onSelectTeam}
            onSelectCompSeason={onSelectCompSeason}
          />
          <SearchPlaceholder>
            <SearchInput
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={({ target: { value } }: { target: any }) => setSearchQuery(value)}
            />
            <SearchIcon
              width={30}
              alt="Search"
              style={{ opacity: '0.3', position: 'absolute', right: '5px', top: '12px' }}
            />
          </SearchPlaceholder>
        </TopNavContainer>
      </Suspense>

      <Split data-testid="main-split">
        {/* TODO: Make a proper fallback */}
        <Suspense fallback={<p>Loading Navigation...</p>}>
          <SideNavigationLogic />
        </Suspense>

        <MobileSubnavMatchSection matchDetails={matchDetails as IMatchDetails} />

        <Section
          id="match-section"
          data-testid="match-section"
          isMenuOpen={isMenuOpen}
          isDesktopMenuExpanded={isDesktopMenuExpanded}
        >
          {useAtomValue(isMobileFiltersOpen) && (
            <MobileFilters title="Select Match">
              <MatchSectionFilters
                onSelectMatch={onSelectMatch}
                onClickSwapTeam={onClickSwapTeam}
                onSelectTeam={onSelectTeam}
                onSelectCompSeason={onSelectCompSeason}
              />
            </MobileFilters>
          )}
          <MatchRoutes />
        </Section>
      </Split>
    </>
  );
};

export const onClickSwapTeam =
  (
    navigate: NavigateFunction,
    location: Location,
    teamId: number,
    matchDetails: IMatchDetails | null,
    matchIdParam?: string
  ) =>
  () => {
    if (!matchDetails || !matchIdParam) return;
    const otherTeamId: number =
      matchDetails.matchHomeTeamId === teamId ? matchDetails.matchAwayTeamId : matchDetails.matchHomeTeamId;
    navigate(generateURL(getSamePageURL(location, matchIdParam), { teamId: `${otherTeamId}` }));
  };

export const onSelectMatch =
  (navigate: NavigateFunction, location: Location, teamId: number): DropdownClickHandler<IOption> =>
  ({ value: newMatchId }) =>
    navigate(generateURL(getSamePageURL(location, newMatchId), { teamId: `${teamId}` }));

export const onSelectTeam =
  (
    navigate: NavigateFunction,
    location: Location,
    matches: IMatchSummary[],
    matchDetails: IMatchDetails | null,
    matchIdParam?: string
  ): DropdownClickHandler<IOption> =>
  async ({ value: newTeamId }) => {
    if (!matchDetails || !matchIdParam) return;

    if (
      matchContainsTeam(newTeamId)({
        homeTeamId: matchDetails.matchHomeTeamId,
        awayTeamId: matchDetails.matchAwayTeamId,
      } as IMatchSummary)
    ) {
      navigate(generateURL(getSamePageURL(location, matchIdParam), { teamId: `${newTeamId}` }));
      return;
    }

    const playedMatches = getPlayedMatchesForTeam(newTeamId)(matches);
    if (!playedMatches.length) return; // TODO: probably show an info box. No played Matches in this CS for this team yet.

    navigate(generateURL(getSamePageURL(location, playedMatches[0].matchId), { teamId: `${newTeamId}` }));
  };

export const onSelectCompSeason =
  (
    navigate: NavigateFunction,
    location: Location,
    teamId: number,
    matches: IMatch[]
  ): DropdownClickHandler<IOptionCS> =>
  async ({ value: newCompSeasonId }) => {
    const [compId, seasonId] = splitCompSeasonId(newCompSeasonId.toString());
    const match = getBestMatchInCS(compId, seasonId, matches);
    if (!match) return; // no matches in CS, so it's not selectable
    navigate(generateURL(getSamePageURL(location, match.matchId), { teamId: `${teamId}` }));
  };

export const MatchSection = ({
  isMenuOpen,
  isDesktopMenuExpanded,
}: {
  isMenuOpen: boolean;
  isDesktopMenuExpanded: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { matchId: matchIdParam } = useParams();
  const teamIdParam = searchParams.get('teamId');
  const matchDetails = useAtomValue(matchAtoms.details);
  const matches = useAtomValue(matchAtoms.matchesForCompSeason);
  const teamMatches = useAtomValue(getFixturesForTeam);
  const [, setMatchId] = useAtom(filterAtoms.selectedMatchId);
  const [, setCompSeason] = useAtom(filterAtoms.selectedCompSeason);
  const [teamId, setTeamId] = useAtom(filterAtoms.selectedTeamId);
  const [searchQuery, setSearchQuery] = useAtom(search.searchQuery);

  return (
    <MatchSectionInner
      {...{
        isMenuOpen,
        isDesktopMenuExpanded,
        setMatchId,
        setCompSeason,
        setTeamId,
        matchIdParam,
        teamIdParam,
        matchDetails,
        searchQuery,
        setSearchQuery,
        onClickSwapTeam: onClickSwapTeam(navigate, location, teamId, matchDetails, matchIdParam),
        onSelectMatch: onSelectMatch(navigate, location, teamId),
        onSelectTeam: onSelectTeam(navigate, location, matches, matchDetails, matchIdParam),
        onSelectCompSeason: onSelectCompSeason(navigate, location, teamId, teamMatches),
      }}
    />
  );
};

const EmptySection = ({
  isMenuOpen,
  isDesktopMenuExpanded,
}: {
  isMenuOpen: boolean;
  isDesktopMenuExpanded: boolean;
}) => (
  <>
    <TopNavContainer>
      <MatchFilterInner selectedTeamId={1} matches={[]} onSelectMatch={identity} />
      <MatchSwapTeamButton onClick={noOp} />
      <TeamFilterInner teams={[]} onSelectTeam={identity} />
      <CompSeasonFilterInner compSeasons={[]} onSelectCompSeason={identity} />
      <SearchPlaceholder>
        <SearchIcon
          width={30}
          alt="Search"
          style={{ opacity: '0.3', position: 'absolute', right: '5px', top: '12px' }}
        />
      </SearchPlaceholder>
    </TopNavContainer>

    <Split data-testid="main-split">
      <SideNavigationInner
        {...{
          isDesktopMenuExpanded: useAtomValue(isDesktopMenuExpandedAtom),
          isMenuOpen: false,
          isMobile: false,
          mobileSectionId: null,
          navigationItems: generateNavigation(navConfig, 'user', 0, 0, 0, 0, 0),
          onItemClick: noOp,
          selectedSection: '/match',
          selectedSubpage: '',
          setIsDesktopMenuExpanded: noOp,
          title: '',
        }}
      />

      <MobileSubnavMatchSection />

      <Section
        id="match-section"
        data-testid="match-section"
        isMenuOpen={isMenuOpen}
        isDesktopMenuExpanded={isDesktopMenuExpanded}
      >
        <LoadingSpinner />
      </Section>
    </Split>
  </>
);

export default ({ isMenuOpen, isDesktopMenuExpanded }: { isMenuOpen: boolean; isDesktopMenuExpanded: boolean }) => (
  /* TODO: Make a proper fallback */
  <ErrorBoundary fallback={<h3>Something went wrong</h3>}>
    <Suspense fallback={<EmptySection {...{ isMenuOpen, isDesktopMenuExpanded }} />}>
      <MatchSection {...{ isMenuOpen, isDesktopMenuExpanded }} />
    </Suspense>
  </ErrorBoundary>
);
