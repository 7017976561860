import { styled } from '@statsbomb/react-components';
import { KEY_COMPONENT_GAP } from '../../../../consts/viz';
import { ITheme } from '../../../../types/ITheme';

export const KeyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: ${KEY_COMPONENT_GAP};
  margin: 1rem 0;
`;

export const KeyText = styled.p(({ theme }: { theme: ITheme; colour: string }) => ({
  fontSize: theme.typography.body3.size,
  fontWeight: theme.typography.body3.weight,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));
