export enum ColourTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface TextPalette {
  DEFAULT: string;
  BORDER: string;
}

export interface ColourPalette {
  TEXT: TextPalette;
  PITCH: {
    BACKGROUND: string; // using canvas.tertiary
    LINES: string; // using canvas.inverse
  };
}

export interface ThemeColourPalette {
  [ColourTheme.LIGHT]: ColourPalette;
  [ColourTheme.DARK]: ColourPalette;
}

export interface StrokeWidths {
  THIN: number;
  DEFAULT: number;
  THICK: number;
}

export interface ThemeFontSize {
  SMALL: number;
  DEFAULT: number;
  LARGE: number;
}

export interface VizTheme {
  COLOURS: ThemeColourPalette;
  TEXT: {
    FONT_SIZE: ThemeFontSize;
  };
  LINES: {
    LINE_STROKE_WIDTH: StrokeWidths;
    TEXT_STROKE_WIDTH: StrokeWidths;
  };
}
