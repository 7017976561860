import React from 'react';
import { Group } from '@visx/group';
import { WIDTH, HEIGHT, SPOT_RADIUS, CENTRE_CIRCLE_RADIUS } from '../../../../consts/pitch';
import PenaltyBox from './PenaltyBox';
import { getPitchAttributes } from './utils';

type SVGPathBooleanOption = 0 | 1;
type SVGPathMove = ['m', number, number];
type SVGPathArc = ['a', number, number, number, SVGPathBooleanOption, SVGPathBooleanOption, number, number];
type SVGPathSegments = Array<SVGPathMove | SVGPathArc>;

const move = (x: number, y: number): SVGPathMove => ['m', x, y];

const arc = (
  rx: number,
  ry: number,
  xAxisRotation: number,
  largeArcFlag: SVGPathBooleanOption,
  sweepFlag: SVGPathBooleanOption,
  dx: number,
  dy: number
): SVGPathArc => ['a', rx, ry, xAxisRotation, largeArcFlag, sweepFlag, dx, dy];

const path = (...segments: SVGPathSegments): string => segments.map(segment => segment.join(' ')).join(' ');

const rightArc = (x: number, y: number, rx: number, ry: number) => {
  const dy = ry * 2;
  return path(move(x, y - ry), arc(rx, ry, 0, 0, 1, 0, dy));
};

const HalfPitch = ({
  pitchLineColour,
  pitchBackground,
  end = 'attacking',
}: {
  pitchLineColour: string;
  pitchBackground: string;
  end?: string;
}) => {
  const pitchAttributes = getPitchAttributes(pitchLineColour, pitchBackground);

  return (
    <Group transform={`rotate(${end === 'attacking' ? 0 : 180} 60 40)`}>
      <rect
        data-testid={`${end}-pitch`}
        data-name="pitch-outline"
        x={WIDTH / 2}
        y={0}
        width={WIDTH / 2}
        height={HEIGHT}
        {...pitchAttributes}
      />
      <path
        data-testid={`${end}-center-circle`}
        data-name="centre-circle"
        d={rightArc(WIDTH / 2, HEIGHT / 2, CENTRE_CIRCLE_RADIUS, CENTRE_CIRCLE_RADIUS)}
        {...pitchAttributes}
        fill="none"
      />
      <ellipse id="centreSpot" cx={WIDTH / 2} cy={HEIGHT / 2} rx={SPOT_RADIUS} ry={SPOT_RADIUS} {...pitchAttributes} />
      <PenaltyBox pitchBackground={pitchBackground} pitchLineColour={pitchLineColour} />
    </Group>
  );
};

export default HalfPitch;
