import { atom } from 'jotai';
import { Scale } from '../types/viz';
import { PASS_NETWORK, POSEIDON } from '../consts/scale';
import { pitchIsDarkModeSelected } from './passNetwork';

// In future, make this something the user can configure + save:
export const scale = atom<Scale>(get => {
  const isPitchDarkMode = get(pitchIsDarkModeSelected);

  /**
   * Picked a relatively appropriate scale for dark mode
   * but these values should be updated in future with
   * advice from jim / kat
   */
  return isPitchDarkMode ? POSEIDON : PASS_NETWORK;
});
