import React, { Suspense, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useAtomValue } from 'jotai/utils';
import { ErrorBoundary } from '@sentry/react';
import { Accordion } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { Page } from '../../Page';
import * as matchAtoms from '../../../atoms/match';
import * as passNetworkAtoms from '../../../atoms/passNetwork';
import { Card } from '../../Card.styles';
import { StatTitle } from '../../tables/Table.styles';
import { substitutions as substitutionsMetaData } from '../../../config/passNetwork/substitutions';
import * as passesMetaData from '../../../config/passNetwork/passes';
import * as passNetworkConfig from '../../../config/passNetwork/passNetwork';
import { HomeAndAwayTable } from '../../tables/HomeAndAwayTable';
import { ITEMS_PER_PAGE, SIBLING_COUNT } from '../../../consts/match';
import { DownloadSomeTablesButton } from '../../downloads/CSVDownloads';
import ChartSettingsFilters from '../../filters/ChartSettingsFilters';
import { GameFiltersComponent } from '../../filters/GameFilters';
import DisplaySettingsFilters from '../../filters/DisplaySettingsFilters';
import { GAP_BETWEEN_COMPONENTS, ROW_MARGIN } from '../../../consts/spacing';
import TabbedModal from '../../modals/TabbedModal';
import { tabbedModalConfig } from '../../../config/passNetwork/TabbedModal';
import { IModelFilter, IPassNetworkFilters, Period } from '../../../types/passNetwork';
import { GridWrapper, DesktopOnlyCol } from '../../gridVariants/gridVariants';
import { DesktopFilterWrapper } from '../../filters/FilterWrapper.styles';
import { SomeHomeAwayTables } from '../../tables/HomeAndAwayTable/HomeAndAwayTable';
import { SomeHomeAwayTablesPaginated } from '../../tables/HomeAndAwayTable/HomeAndAwayTablePaginated';
import { DesktopOnly, MobileTitleCard, PageTitle, ScoreLine } from './PassNetwork.styles';
import { IMatchStatMeta } from '../../../types/match';
import { IStatTableCol } from '../../../types/matchStats';
import PassNetworksViz from '../../viz/whole/PassNetwork';
import { DownloadModal } from '../../modals/DownloadModal';
import { Button, DownloadIcon } from '../../button/Button.styles';
import { PITCH_DOWNLOAD_HEIGHT, PITCH_DOWNLOAD_WIDTH } from '../../../consts/download';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';
import { DownloadButtonProps } from './PassNetwork.types';
import { DownloadFooter } from '../../downloads/Footer';

const DownloadButton = ({ onDownloadClick }: DownloadButtonProps) => (
  <Button style={{ flexBasis: 40 }} data-testid="download-modal-button" onClick={onDownloadClick}>
    <DownloadIcon width={30} />
  </Button>
);

const MatchTitle = () => {
  const matchDetails = useAtomValue(matchAtoms.details);
  const scoreLine = useAtomValue(passNetworkAtoms.scoreLine);
  return (
    <div style={{ width: '100%' }}>
      <PageTitle>Passing Network</PageTitle>
      <ScoreLine>
        <p>{scoreLine}</p>
        <p>
          {matchDetails?.competitionName} {matchDetails?.seasonName}
        </p>
      </ScoreLine>
    </div>
  );
};

const MatchTitleWithDownload = (props: DownloadButtonProps) => (
  <div style={{ display: 'flex' }}>
    <div style={{ flexGrow: '1' }}>
      <DesktopOnly>
        <MatchTitle />
      </DesktopOnly>
    </div>
    <DesktopOnly>
      <DownloadButton {...props} />
    </DesktopOnly>
  </div>
);

const DesktopFilters = () => {
  const [isChartSettingsExpanded, setIsChartSettingsExpanded] = useState(true);
  const [isGameFiltersExpanded, setIsGameFiltersExpanded] = useState(true);
  const [isDisplaySettingsExpanded, setIsDisplaySettingsExpanded] = useState(true);
  return (
    <DesktopOnlyCol xs={12} md={3}>
      <DesktopFilterWrapper>
        <Accordion
          isExpanded={isChartSettingsExpanded}
          onAccordionClick={() => setIsChartSettingsExpanded(!isChartSettingsExpanded)}
          title="Chart Settings"
        >
          <ChartSettingsFilters />
        </Accordion>
      </DesktopFilterWrapper>

      <DesktopFilterWrapper>
        <Accordion
          isExpanded={isGameFiltersExpanded}
          onAccordionClick={() => setIsGameFiltersExpanded(!isGameFiltersExpanded)}
          title="Game Filters"
        >
          <GameFiltersComponent />
        </Accordion>
      </DesktopFilterWrapper>

      <DesktopFilterWrapper>
        <Accordion
          isExpanded={isDisplaySettingsExpanded}
          onAccordionClick={() => setIsDisplaySettingsExpanded(!isDisplaySettingsExpanded)}
          title="Display Settings"
        >
          <DisplaySettingsFilters />
        </Accordion>
      </DesktopFilterWrapper>
    </DesktopOnlyCol>
  );
};

const VizCard = () => {
  const scoreLine = useAtomValue(passNetworkAtoms.scoreLine);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useAtom(passNetworkAtoms.isDownloadModalOpen);
  const [, setPlayerToDisplay] = useAtom(passNetworkAtoms.playerToDisplayTooltip);

  const pitchesLength = useAtomValue(passNetworkAtoms.pitchesLength);
  const pitchOrientation = useAtomValue(passNetworkAtoms.pitchOrientation);

  const pitchDownloadHeight = PITCH_DOWNLOAD_HEIGHT[pitchOrientation][pitchesLength];

  const onDownloadClick = () => {
    setPlayerToDisplay(null);
    setIsDownloadModalOpen(true);
  };

  return (
    <>
      <MobileTitleCard as={Row} between="xs" xs={12} md={9}>
        <MatchTitle />
        <DownloadButton {...{ onDownloadClick }} />
      </MobileTitleCard>
      <Card as={Row} between="xs">
        <Col xs={12}>
          <MatchTitleWithDownload {...{ onDownloadClick }} />
          <PassNetworksViz isOnModal={false} />
        </Col>
      </Card>
      <DownloadModal
        isHidden={!isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
        title={`Pass Network - ${scoreLine}`}
        height={pitchDownloadHeight}
        width={PITCH_DOWNLOAD_WIDTH}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <MatchTitle />
          <PassNetworksViz isOnModal />
          <DownloadFooter />
        </div>
      </DownloadModal>
    </>
  );
};

const SubsTableCard = ({ meta }: { meta: IMatchStatMeta }) => (
  <Card as={Row} style={{ marginTop: GAP_BETWEEN_COMPONENTS }} between="xs">
    <Col xs={12}>
      <Row>
        <Col xs={12} sm={6}>
          <StatTitle>Substitutions</StatTitle>
        </Col>
      </Row>
      <Row style={{ marginTop: ROW_MARGIN, marginBottom: ROW_MARGIN }}>
        <Suspense fallback={<LoadingSpinner />}>
          <HomeAndAwayTable
            {...{
              stats: matchAtoms.substitutionsAtom,
              columnMetaData: substitutionsMetaData,
              field: 'playerOffName',
              meta,
            }}
          />
        </Suspense>
      </Row>
    </Col>
  </Card>
);

const ModelTableCard = ({
  selectedModel,
  meta,
  showFirstVsSecond,
  columnMetaDataObvXg,
  fileName,
}: {
  selectedModel: IModelFilter;
  meta: IMatchStatMeta;
  showFirstVsSecond: boolean;
  columnMetaDataObvXg: IStatTableCol[];
  fileName: string;
}) => (
  <Card as={Row} style={{ marginTop: GAP_BETWEEN_COMPONENTS }} between="xs">
    <Col xs={12}>
      <Row style={{ marginTop: ROW_MARGIN }}>
        <Col xs={12} sm={6}>
          <StatTitle>{selectedModel.text}</StatTitle>
        </Col>
        <Col xs={12} sm={6}>
          <DownloadSomeTablesButton
            name={selectedModel.text}
            tableAtom={passNetworkAtoms.passNetworkObvXgChainAtom}
            config={columnMetaDataObvXg}
            groupBy="playerName"
            fileName={fileName}
            {...{
              showFirstVsSecond,
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: ROW_MARGIN, marginBottom: ROW_MARGIN }}>
        <Suspense fallback={<LoadingSpinner />}>
          <SomeHomeAwayTables
            stats={passNetworkAtoms.passNetworkObvXgChainAtom}
            columnMetaData={columnMetaDataObvXg}
            field="playerId"
            stickyColumns={[{ index: 1, width: 8 }]}
            isHeadSticky
            hideNonSelectedModel
            {...{
              meta,
              showFirstVsSecond,
            }}
          />
        </Suspense>
      </Row>
    </Col>
  </Card>
);

const PassCombiTableCard = ({
  selectedModel,
  meta,
  showFirstVsSecond,
  fileName,
}: {
  selectedModel: IModelFilter;
  meta: IMatchStatMeta;
  showFirstVsSecond: boolean;
  fileName: string;
}) => (
  <Card as={Row} style={{ marginTop: GAP_BETWEEN_COMPONENTS }} between="xs">
    <Col xs={12}>
      <Row style={{ marginTop: ROW_MARGIN }}>
        <Col xs={12} sm={6}>
          <StatTitle>Passing Combinations {`- ${selectedModel.text}`}</StatTitle>
        </Col>
        <Col xs={12} sm={6}>
          <DownloadSomeTablesButton
            name={`Passing Combinations - ${selectedModel.text}`}
            tableAtom={passNetworkAtoms.passesAtom}
            config={passesMetaData[selectedModel.value]}
            groupBy="id"
            fileName={fileName}
            {...{
              showFirstVsSecond,
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: ROW_MARGIN, marginBottom: ROW_MARGIN }}>
        <Suspense fallback={<LoadingSpinner />}>
          <SomeHomeAwayTablesPaginated
            {...{ meta, showFirstVsSecond }}
            stats={passNetworkAtoms.passesAtom}
            columnMetaData={passesMetaData.passes}
            field={['passerId', 'receiverId', 'periodId']}
            itemsPerPage={ITEMS_PER_PAGE}
            siblingCount={SIBLING_COUNT}
            multiSort={false}
            isHeadSticky
            stickyColumns={[
              { index: 1, width: 8 },
              { index: 2, width: 8 },
            ]}
          />
        </Suspense>
      </Row>
    </Col>
  </Card>
);

export const PassNetwork = () => {
  const matchStats = useAtomValue(matchAtoms.stats);

  if (!matchStats) throw new Error('Missing Match ID');

  const { meta } = matchStats;

  const selectedModel = useAtomValue(passNetworkAtoms.selectedDataModel);
  const columnMetaDataObvXg = selectedModel.value === 'obv' ? passNetworkConfig.obv : passNetworkConfig.xgChain;
  const selectedPeriod = useAtomValue(passNetworkAtoms.gameFilterGameTime);
  const fileName = useAtomValue(matchAtoms.matchDownloadVsFileName);

  const showFirstVsSecond = selectedPeriod === Period.FIRST_VS_SECOND;

  return (
    <Page title={`${meta.homeTeamName} vs ${meta.awayTeamName} - Pass Network`}>
      <GridWrapper fluid>
        <Row>
          <DesktopFilters />
          <Col xs={12} md={9}>
            <VizCard />
            <SubsTableCard meta={meta} />
            <ModelTableCard
              selectedModel={selectedModel}
              meta={meta}
              showFirstVsSecond={showFirstVsSecond}
              columnMetaDataObvXg={columnMetaDataObvXg}
              fileName={`${selectedModel.text} - ${fileName} - Pass network.csv`}
            />
            <PassCombiTableCard
              selectedModel={selectedModel}
              meta={meta}
              showFirstVsSecond={showFirstVsSecond}
              fileName={`Passing Combinations - ${selectedModel.text} - ${fileName} - Pass network.csv`}
            />
          </Col>
        </Row>
      </GridWrapper>
      <TabbedModal config={tabbedModalConfig as IPassNetworkFilters[]} />
    </Page>
  );
};

export const PassNetworkPage = () => (
  <ErrorBoundary fallback={<h3>Something went wrong</h3>}>
    <PassNetwork />
  </ErrorBoundary>
);
