import chroma from 'chroma-js';
import { Scale } from '../../types/viz';

/**
 * Returns a css colour string for the given percentile in the specified scale.
 * ie. on a scale of red -> blue, 0% would be red, 50% would be purple-y, 100% would be blue.
 * @param percentile A number between 0 and 1.
 */
export const chromaValue = (scale: Scale, percentile: number) =>
  chroma.scale(scale.colours).domain(scale.domain)(percentile).css();
