export const MATCH_STATUS = {
  played: 'Played',
  forfeited: 'Forfeited',
  abandoned: 'Abandoned',
  cancelled: 'Cancelled',
  postponed: 'Postponed',
  unplayed: null,
} as const;

export const DEFAULT_VISIBLE_MATCH_STATUSES = [
  MATCH_STATUS.unplayed,
  MATCH_STATUS.played,
  MATCH_STATUS.abandoned,
  MATCH_STATUS.forfeited,
] as const;

interface IMatchPeriods {
  [key: number]: string;
}

export const MATCH_PERIODS = {
  1: 'First Half',
  2: 'Second Half',
  3: 'First Half Extra Time',
  4: 'Second Half Extra Time',
} as IMatchPeriods;

export const ITEMS_PER_PAGE = 10 as const;
export const SIBLING_COUNT = 2 as const;
