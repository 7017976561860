import { range } from 'ramda';

export const SPINNER_SIZE = 15;

export const SPINNER_CENTER = SPINNER_SIZE / 2;

export const SPINNER_STROKE_WIDTH = (SPINNER_SIZE - SPINNER_SIZE / 10) / 12;

const SPINNER_OUTER_RADIUS = SPINNER_CENTER - SPINNER_SIZE / 20 - SPINNER_STROKE_WIDTH / 2;

export const SPINNER_RADII = range(0, 6).map(
  (multiplier: number) => SPINNER_OUTER_RADIUS - multiplier * SPINNER_STROKE_WIDTH
);
