import { styled } from '@statsbomb/react-components';
import { ITheme } from '../../types/ITheme';
import { EXPANDED_NAV_WIDTH, NAV_WIDTH, TOP_NAV_HEIGHT } from '../../consts/navigation';
import { ZINDEX } from '../../consts/zIndex';

interface FullscreenModalProps {
  $isDesktopMenuExpanded?: boolean;
}

export const FullscreenContainer = styled.div<FullscreenModalProps>`
  position: fixed;
  background: ${({ theme }: { theme: ITheme }) => theme.colours.canvas.secondary};
  top: ${TOP_NAV_HEIGHT};
  height: calc(100vh - ${TOP_NAV_HEIGHT});
  width: 100vw;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: ${ZINDEX.modals[0]};
  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    left: ${({ $isDesktopMenuExpanded }: { $isDesktopMenuExpanded: boolean }) =>
      $isDesktopMenuExpanded ? EXPANDED_NAV_WIDTH : NAV_WIDTH};
    width: ${({ $isDesktopMenuExpanded }: { $isDesktopMenuExpanded: boolean }) =>
      $isDesktopMenuExpanded ? `calc(100vw - ${EXPANDED_NAV_WIDTH})` : `calc(100vw - ${NAV_WIDTH})`};
  }
`;
