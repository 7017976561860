/* istanbul ignore file */
// TODO: Remove istanbul ignore when working on nav
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SideNavigation, styled } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { isSectionSelected } from '../../config/navigation';
import * as nav from '../../atoms/navigation';
import { getSection, createMenuTitle, getSubPage } from '../../utils/navigation';
import { ILink, Nav, SectionPrefix } from '../../types/Navigation';
import { SideNavigationWrapper } from './SideNavigation.styles';

interface IOnClickParams {
  isSubmenuItem: boolean;
  sectionName: string;
}

interface ISideNavigationInnerProps {
  isDesktopMenuExpanded: boolean;
  isMenuOpen: boolean;
  isMobile: boolean;
  mobileSectionId: string | null;
  navigationItems: Nav;
  onItemClick: ({ isSubmenuItem }: IOnClickParams, sectionId: string) => void;
  selectedSection: SectionPrefix;
  selectedSubpage: string | undefined;
  setIsDesktopMenuExpanded: (arg0: boolean) => void;
  title: string;
}

// JTD: Temporary a tag as I can't just pass in a
const A = styled.a``;

export const SideNavigationInner = ({
  isDesktopMenuExpanded,
  isMenuOpen,
  isMobile,
  mobileSectionId,
  navigationItems,
  onItemClick,
  selectedSection,
  selectedSubpage,
  setIsDesktopMenuExpanded,
  title,
}: ISideNavigationInnerProps) => (
  <SideNavigationWrapper $itemLength={navigationItems.length}>
    <SideNavigation
      isDesktopMenuExpanded={isDesktopMenuExpanded}
      isMobileMenuOpen={isMenuOpen}
      onExpandButtonClick={() => setIsDesktopMenuExpanded(!isDesktopMenuExpanded)}
      title={title}
    >
      {navigationItems.map((section, index) => (
        <SideNavigation.Item
          key={section.id}
          isItemSelected={
            isMobile ? mobileSectionId === section.id : isSectionSelected(selectedSection, selectedSubpage, section)
          }
          icon={section.icon}
          isMobile={isMobile}
          onItemClick={(params: IOnClickParams) => onItemClick(params, section.id)}
          classNames={[
            section.position === 'bottom' && index > 0 && navigationItems[index - 1].position !== 'bottom'
              ? 'bottom'
              : '',
          ]}
          sectionName={section.name}
          // If an item position is 'bottom', this will display the subnav starting from the bottom
          // TODO: change name position to something better, maybe subnavDisplay
          position={section.position}
          component={A}
          componentProps={{ href: `${section.links[0].href}` }}
        >
          {section.links?.length > 1 &&
            section.links.map((subitem: ILink) => (
              <SideNavigation.SubmenuItem
                key={subitem.href}
                onItemClick={(params: IOnClickParams) => onItemClick(params, section.id)}
              >
                {subitem.href.startsWith('/_') ? (
                  <Link to={subitem.href}>{subitem.name}</Link>
                ) : (
                  <a href={subitem.href}>{subitem.name}</a>
                )}
              </SideNavigation.SubmenuItem>
            ))}
        </SideNavigation.Item>
      ))}
    </SideNavigation>
  </SideNavigationWrapper>
);

export const SideNavigationLogic = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const location = useLocation();
  const selectedSection = getSection(location);
  const selectedSubpage = getSubPage(location);
  const menuTitle = createMenuTitle(location) as string;

  const navigationItems = useAtomValue(nav.navigationItems);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 767;

  const [isDesktopMenuExpanded, setIsDesktopMenuExpanded] = useAtom(nav.isDesktopMenuExpanded);
  const [isMenuOpen, setIsMenuOpen] = useAtom(nav.isMenuOpen);
  const [mobileSectionId, setMobileSectionId] = useState<string | null>(null);

  const onItemClick = ({ isSubmenuItem }: IOnClickParams, sectionId: string) => {
    if (isMobile && isSubmenuItem) {
      setMobileSectionId(null);
      setIsMenuOpen(false);
    } else if (isMobile && mobileSectionId === sectionId) {
      setMobileSectionId(null);
    } else {
      setMobileSectionId(sectionId);
    }
  };

  return (
    <SideNavigationInner
      {...{
        isDesktopMenuExpanded,
        isMenuOpen,
        isMobile,
        mobileSectionId,
        navigationItems,
        onItemClick,
        selectedSection,
        selectedSubpage,
        setIsDesktopMenuExpanded,
        title: menuTitle || 'Menu',
      }}
    />
  );
};
