import React from 'react';
import { Table, styled } from '@statsbomb/react-components';
import { IMatchStatAway, IMatchStatHome, IMatchStatMeta } from '../../types/match';
import { StatRow } from './StatRow';
import { IStatTableCol } from '../../types/matchStats';
import { formatStatData } from './StatsTable';
import { isNumber, isString } from '../../types/utils';
import { ITheme } from '../../types/ITheme';
import { TEXT_ALIGNMENT } from '../../consts/typography';

/**
 * JTD: When using sticky columns, the react-components table
 * adds a box-shadow and position sticky on both mobile and desktop.
 * For this table, we only want this styling on mobile devices
 * so I have restyled the component based on the md size media query.
 */

const StyledTable = styled(Table)`
${({ theme }: { theme: ITheme }) => theme.mqTo('md')} {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15rem;
  }
}

${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
  ${({ $columnToStick }: { $columnToStick: number }) =>
    !!$columnToStick &&
    `
    th:nth-of-type(${$columnToStick}) {
      position: initial;
      left: initial;
      box-shadow: initial;
    };
    th:nth-of-type(${$columnToStick + 1}) {
      padding-left: initial;
    }
    th:nth-of-type(${$columnToStick - 1}) {
      padding-right: initial;
    }

    td:nth-of-type(${$columnToStick}) {
      position: initial;
      left: initial;
      box-shadow: initial;
      border-right: initial;
    }
    td:nth-of-type(${$columnToStick + 1}) {
      padding-left: initial;
    }
    td:nth-of-type(${$columnToStick - 1}) {
      padding-right: initial;
    }
  `}
`;

export const MainStatsTable = ({
  home,
  away,
  meta,
  config,
}: {
  home: Omit<IMatchStatHome, 'homeTeam'>;
  away: Omit<IMatchStatAway, 'homeTeam'>;
  meta: IMatchStatMeta;
  config: IStatTableCol[];
}) => (
  <StyledTable $columnToStick={1}>
    <Table.Head>
      <Table.Row>
        <Table.HeaderCell>{}</Table.HeaderCell>
        <Table.HeaderCell $textAlign={TEXT_ALIGNMENT.RIGHT}>{meta.homeTeamName}</Table.HeaderCell>
        <Table.HeaderCell $textAlign={TEXT_ALIGNMENT.RIGHT}>{meta.awayTeamName}</Table.HeaderCell>
      </Table.Row>
    </Table.Head>

    <Table.Body>
      {config.map(col => {
        const homeStat = home[col.stat];
        const awayStat = away[col.stat];
        if ((!isString(homeStat) && !isNumber(homeStat)) || (!isString(awayStat) && !isNumber(awayStat))) return null;
        if (col.stat === 'penaltyScore' && homeStat === 0 && awayStat === 0) return null;

        return (
          <StatRow
            key={col.title}
            statName={col.title}
            homeStat={formatStatData(homeStat, col)}
            awayStat={formatStatData(awayStat, col)}
          />
        );
      })}
    </Table.Body>
  </StyledTable>
);
