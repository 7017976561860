import React from 'react';
import {
  WIDTH,
  PENALTY_BOX_HEIGHT,
  HEIGHT,
  PENALTY_SPOT_DISTANCE,
  CENTRE_CIRCLE_RADIUS,
  SPOT_RADIUS,
  PENALTY_BOX_WIDTH,
  SIX_YARD_BOX_WIDTH,
  SIX_YARD_BOX_HEIGHT,
  GOAL_HEIGHT,
} from '../../../../consts/pitch';
import { getPitchAttributes } from './utils';

const PenaltyBox = ({ pitchBackground, pitchLineColour }: { pitchBackground: string; pitchLineColour: string }) => {
  const defaultAttributes = getPitchAttributes(pitchLineColour, 'transparent');

  return (
    <>
      <clipPath id="outside-the-penalty-box">
        <rect x={0} width={WIDTH - PENALTY_BOX_HEIGHT} y="0" height={HEIGHT} />
      </clipPath>
      <g id="penalty-box-markings">
        <ellipse
          id="penaltyD"
          clipPath="url(#outside-the-penalty-box)"
          cx={WIDTH - PENALTY_SPOT_DISTANCE}
          cy={HEIGHT / 2}
          rx={CENTRE_CIRCLE_RADIUS}
          ry={CENTRE_CIRCLE_RADIUS}
          {...defaultAttributes}
        />
        <rect
          id="eighteenYardBox"
          x={WIDTH - PENALTY_BOX_HEIGHT}
          y={(HEIGHT - PENALTY_BOX_WIDTH) / 2}
          width={PENALTY_BOX_HEIGHT}
          height={PENALTY_BOX_WIDTH}
          {...defaultAttributes}
          /**
           * overwriting the default fill rather than recalling the function
           * */
          fill={pitchBackground}
        />
        <ellipse
          id="penaltySpot"
          clipPath="1"
          cx={WIDTH - PENALTY_SPOT_DISTANCE}
          cy={HEIGHT / 2}
          rx={SPOT_RADIUS}
          ry={SPOT_RADIUS}
          {...defaultAttributes}
        />
        <rect
          id="sixYardBox"
          x={WIDTH - SIX_YARD_BOX_WIDTH}
          y={(HEIGHT - SIX_YARD_BOX_HEIGHT) / 2}
          width={SIX_YARD_BOX_WIDTH}
          height={SIX_YARD_BOX_HEIGHT}
          {...defaultAttributes}
        />
        <rect
          id="goal"
          x={WIDTH - 0.7}
          y={(HEIGHT - GOAL_HEIGHT) / 2}
          width={0.7}
          height={GOAL_HEIGHT}
          {...defaultAttributes}
          /**
           * overwriting the default fill rather than recalling the function
           * */
          fill={pitchLineColour}
        />
      </g>
    </>
  );
};

export default PenaltyBox;
