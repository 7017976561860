export const RADIUS_OFFSET_PASSER = 0;
export const RADIUS_OFFSET_RECEIVER = 2;
export const COMBINATION_LINE_OFFSET = -3;
export const KEY_ARROW_Y_POSITION = 10.5;
export const KEY_CIRCLE_Y_POSITION = 4;
export const KEY_COMPONENT_GAP = '3.2rem';

// When positioning text, setting the dy to this seems to center it vertically
export const TEXT_VERTICAL_CENTRE = '0.4275em';

export const PITCH_PORTRAIT_MAX_WIDTH = '45rem';
export const PITCH_LANDSCAPE_MAX_WIDTH = '70rem';
