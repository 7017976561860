import React from 'react';
import { ToggleButton } from '@statsbomb/react-components';
import { useAtom } from 'jotai';
import { displaySettingPitchColourMode } from '../../atoms/passNetwork';
import { displaySettingPitchModeConfig } from '../../config/passNetwork/filters';
import { Title } from './ModelFilters.styles';
import PitchOrientationFilter from './PitchOrientationFilters';
import { Filter, FilterGroup } from './FilterWrapper.styles';

const DisplaySettingsFilters = () => {
  const [selectedPitchColourMode, setSelectedPitchColourMode] = useAtom(displaySettingPitchColourMode);

  return (
    <Filter>
      <FilterGroup>
        <PitchOrientationFilter />
      </FilterGroup>
      <FilterGroup>
        <Title>Pitch Mode</Title>
        <ToggleButton.Container>
          {displaySettingPitchModeConfig.map(pitchMode => (
            <ToggleButton.Button
              key={pitchMode.value}
              type="button"
              aria-pressed={selectedPitchColourMode}
              active={selectedPitchColourMode === pitchMode.value}
              value={pitchMode.value}
              onClick={() => setSelectedPitchColourMode(pitchMode.value)}
            >
              {pitchMode.text}
            </ToggleButton.Button>
          ))}
        </ToggleButton.Container>
      </FilterGroup>
    </Filter>
  );
};

export default DisplaySettingsFilters;
