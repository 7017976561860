import { styled } from '@statsbomb/react-components';
import { ZINDEX } from '../../consts/zIndex';
import { ITheme } from '../../types/ITheme';
import { FullscreenContainer } from './Fullscreen.styles';

export const MobileOnlyFullscreenContainer = styled(FullscreenContainer)`
  flex-direction: column;

  ${({ theme }: { theme: ITheme }) => theme.mqFrom('md')} {
    display: none;
  }
`;

export const Tabs = styled.div`
  display: flex;
  gap: ${({ theme }: { theme: ITheme }) => theme.spacing(1)};
  overflow: scroll;
  padding: ${({ theme }: { theme: ITheme }) => `${theme.spacing(1)}`};
  white-space: nowrap;
  min-height: 5rem;
`;

export const TitleSection = styled.div(({ theme: { colours, spacing, effects } }: { theme: ITheme }) => ({
  background: colours.canvas.secondary,
  boxShadow: effects.dropShadow.regular,
  padding: `${spacing(1.5)} ${spacing(2)}`,
  width: '100vw',
  zIndex: ZINDEX.modals[0],
}));

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: ${({ theme }: { theme: ITheme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }: { theme: ITheme }) => theme.spacing(10)};
`;

export const ModalFooterContainer = styled.div(
  ({ theme: { colours, effects, spacing, mqFrom } }: { theme: ITheme }) => ({
    alignItems: 'center',
    background: colours.canvas.secondary,
    bottom: 0,
    boxShadow: effects.dropShadow.regular,
    display: 'flex',
    justifyContent: 'center',
    padding: spacing(1),
    position: 'sticky',
    width: '100vw',
    zIndex: ZINDEX.modals[0],
    [`${mqFrom('md')}`]: {
      display: 'none',
    },
  })
);

export const Button = styled.button(
  ({ theme: { colours, typography, spacing }, $active }: { theme: ITheme; $active: boolean }) => ({
    alignItems: 'center',
    background: $active ? colours.brand.interface.main : colours.canvas.tertiary,
    borderRadius: '0.3rem',
    color: $active ? colours.ink.inverse.main : colours.graphics.primary.light,
    display: 'flex',
    fontSize: typography.heading3.size,
    height: '3rem',
    justifyContent: 'center',
    padding: `${spacing(0)} ${spacing(1)}`,
  })
);

export const ButtonWithIcon = styled(Button)(({ theme: { colours } }: { theme: ITheme; $active: boolean }) => ({
  gap: '1rem',
  [`> svg`]: {
    fill: colours.graphics.primary.light,
    width: '2.8rem',
  },
}));
