import {
  ascend,
  both,
  compose,
  descend,
  eqProps,
  filter,
  map,
  pick,
  pipe,
  prop,
  sortWith,
  split,
  uniqWith,
} from 'ramda';
import { CompSeason, CSPredicate, ICompetitionSeason, ICompSeasonID, MapCSIdToCS } from '../types/competitionSeason';

const sameComp: CSPredicate = eqProps('competitionId');
const sameSeason: CSPredicate = eqProps('seasonId');

// Take a list of things with comp and season IDs and return unique by comp and season ID
const uniqByCompAndSeason = uniqWith(both(sameComp, sameSeason));

// Take the CS IDs from the unique list
const uniqCSIDs = compose(map(pick(['competitionId', 'seasonId'])), uniqByCompAndSeason);

const isNotBlankCS = (cs: ICompetitionSeason | undefined): cs is ICompetitionSeason => !!cs;
const rejectBlankCS: (cs: (ICompetitionSeason | undefined)[]) => ICompetitionSeason[] = filter(isNotBlankCS);

// Take a list of CS IDs and return the real CompSeason objects
const mapCSIdToCS = (allCS: ICompetitionSeason[]): MapCSIdToCS =>
  map((csId: ICompSeasonID) => allCS.find(cs => sameComp(cs, csId) && sameSeason(cs, csId)));

// Convert a list of team fixtures into their corresponding CompSeason objects
export const getCompSeasonsFromFixtures = (allCompSeasons: ICompetitionSeason[]) =>
  pipe(
    uniqCSIDs,
    mapCSIdToCS(allCompSeasons),
    rejectBlankCS,
    sortWith([descend(prop('seasonName')), ascend(prop('competitionName'))])
  );

export const generateCompSeasonId: {
  (cs: CompSeason): string;
  (compId: number, seasonId: number): string;
} = (csOrCompId: CompSeason | number, seasonId?: number) => {
  if (typeof csOrCompId === 'number') return generateCompSeasonId([csOrCompId, seasonId as number]);
  return csOrCompId.join('/');
};

export const splitCompSeasonId = (compSeasonId: string) => pipe(split('/'), map(Number))(compSeasonId);
