import React from 'react';
import { TEXT_VERTICAL_CENTRE } from '../../../../consts/viz';
import { VIZ_THEME } from '../../../../consts/vizTheme';
import { VizTooltip, VizTooltipPlayer } from '../../../../types/viz';
import { Circle } from '../../whole/PassNetwork.styles';

const { TEXT, LINES } = VIZ_THEME;

export const PlayerCircleBorder = ({
  r,
  cx,
  cy,
  fill,
  stroke,
  strokeOpacity,
}: {
  r: number;
  cx: number;
  cy: number;
  fill: string;
  stroke: string;
  strokeOpacity: number;
}) => <circle {...{ fill, r, cx, cy, stroke }} fillOpacity={0} strokeOpacity={strokeOpacity} strokeWidth={1} />;

export const PlayerCircle = ({
  r,
  cx,
  cy,
  fill,
  fillOpacity,
}: {
  r: number;
  cx: number;
  cy: number;
  fill: string;
  fillOpacity: number;
}) => <circle {...{ fill, r, cx, cy }} fillOpacity={fillOpacity} />;

export const PlayerText = ({
  colour,
  borderColour,
  x,
  y,
  playerName,
}: {
  colour: string;
  borderColour: string;
  x: number;
  y: number;
  playerName: string;
}) => (
  <text
    data-testid={`${playerName}-text`}
    fill={colour}
    fontSize={TEXT.FONT_SIZE.SMALL}
    textAnchor="middle"
    fontWeight="bold"
    strokeWidth={LINES.TEXT_STROKE_WIDTH.DEFAULT}
    stroke={borderColour}
    style={{ paintOrder: 'stroke' }}
    x={x}
    y={y}
    dy={TEXT_VERTICAL_CENTRE}
  >
    {playerName}
  </text>
);

export const PassingLines = ({
  x1,
  y1,
  x2,
  y2,
  stroke,
  strokeWidth,
  uniqueValue,
  opacity,
}: {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  stroke: string;
  strokeWidth: number;
  uniqueValue: string;
  opacity: number;
}) => (
  <>
    <defs opacity={opacity}>
      <marker
        id={`pass-combination-arrowhead-${uniqueValue}`}
        markerWidth={20}
        markerHeight={20}
        refX={1}
        refY={0.5}
        orient="auto"
      >
        <path d="M 4 0 L 0 0 L 0 1.7" fill={stroke} />
      </marker>
    </defs>
    <line
      data-testid={x1}
      {...{ x1, y1, x2, y2, stroke, strokeWidth }}
      opacity={opacity}
      markerEnd={`url(#pass-combination-arrowhead-${uniqueValue}`}
    />
  </>
);

export const PlayerCircleTooltip = ({
  r,
  cx,
  cy,
  fill,
  onClick,
  playerName,
  playerId,
  toolTipData,
}: {
  r: number;
  cx: number;
  cy: number;
  fill: string;
  onClick: (arg0: VizTooltipPlayer, arg1: React.MouseEvent<SVGCircleElement, MouseEvent>) => void;
  playerName: string;
  playerId: number;
  toolTipData: VizTooltip[];
}) => (
  <Circle
    {...{ fill, r, cx, cy }}
    fillOpacity={0}
    onClick={(event: React.MouseEvent<SVGCircleElement, MouseEvent>) =>
      onClick({ playerName, playerId, toolTipData, cx, cy }, event)
    }
    data-testid={`tooltipclick-${playerId}`}
  />
);
