import { partial } from 'ramda';
import { TEXT_ALIGNMENT } from '../../consts/typography';
import { TABLE_SORT_STATES } from '../../types/table';
import { sortItems } from '../../utils/table';

export const pressuresAndCounterPressures = [
  {
    textAlign: TEXT_ALIGNMENT.LEFT,
    title: 'Player',
    stat: 'playerName',
    toolTip: 'Player Name',
    sortableFn: partial(sortItems, ['playerName']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Pressures',
    stat: 'pressures',
    toolTip: 'Pressures',
    sortableFn: partial(sortItems, ['pressures']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Total Duration',
    stat: 'totalDuration',
    toolTip: 'Total Duration of Pressures',
    toFixed: 1,
    sortableFn: partial(sortItems, ['totalDuration']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Duration Per Pressure',
    stat: 'durationPerPressure',
    toolTip: 'Duration Per Pressure',
    toFixed: 1,
    sortableFn: partial(sortItems, ['durationPerPressure']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
  {
    title: 'Pressured Action Fails',
    stat: 'pressuredActionFails',
    toolTip: 'Pressured Action Fails',
    sortableFn: partial(sortItems, ['pressuredActionFails']),
    order: TABLE_SORT_STATES.DEFAULT,
  },
];
