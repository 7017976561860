import { styled } from '@statsbomb/react-components';
import { TOOLTIP_OFFSET_LEFT, TOOLTIP_OFFSET_RIGHT, TOOLTIP_OFFSET_TOP } from '../../../consts/passNetwork';
import { MousePosition } from '../../../types/viz';
import { ITheme } from '../../../types/ITheme';
import { ZINDEX } from '../../../consts/zIndex';

export const TooltipWrapper = styled.div`
  background-color: ${({ theme }: { theme: ITheme }) => theme.colours.canvas.primary};
  left: ${({ isOnLeftOfPitch, mousePosition }: { isOnLeftOfPitch: boolean; mousePosition: MousePosition }) =>
    isOnLeftOfPitch ? mousePosition.x + TOOLTIP_OFFSET_LEFT : mousePosition.x - TOOLTIP_OFFSET_RIGHT}px;
  top: ${({ mousePosition }: { mousePosition: MousePosition }) => mousePosition.y - TOOLTIP_OFFSET_TOP}px;
  position: absolute;
  border-radius: 1rem;
  width: 15rem;
  z-index: ${ZINDEX.modals[0]};
`;
export const TooltipRow = styled.div`
  display: flex;
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.body2.size};
  font-weight: ${({ theme }: { theme: ITheme }) => theme.typography.body2.weight};
  padding: ${({ theme }: { theme: ITheme }) => `0 ${theme.spacing(1)} ${theme.spacing(0.75)}`};
  > :last-child {
    margin-left: auto;
  }
`;
export const TooltipTitle = styled.h3`
  font-size: ${({ theme }: { theme: ITheme }) => theme.typography.heading3.size};
  font-weight: ${({ theme }: { theme: ITheme }) => theme.typography.heading3.weight};
  padding: ${({ theme }: { theme: ITheme }) => `${theme.spacing(0.75)} ${theme.spacing(1)}`};
`;
export const TooltipHr = styled.hr`
  border: ${({ theme }: { theme: ITheme }) => `thin solid ${theme.colours.brand.interface.main}`};
  margin-bottom: ${({ theme }: { theme: ITheme }) => `${theme.spacing(0.75)}`};
`;
