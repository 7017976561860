import React from 'react';
import { useTheme } from 'styled-components';
import { SPINNER_CENTER, SPINNER_RADII, SPINNER_SIZE, SPINNER_STROKE_WIDTH } from './loadingSpinner.constants';
import {
  BottomSpinnerRotation,
  LoadingMessage,
  SpinnerContainer,
  SpinnerSectionContainer,
  SpinnerWrapper,
  TopSpinnerRotation,
} from './LoadingSpinner.styles';

const SpinnerSectionInterior = ({ radius }: { radius: number }) => {
  const theme = useTheme();

  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${SPINNER_SIZE} ${SPINNER_SIZE}`}>
      <circle
        cx={SPINNER_CENTER}
        cy={SPINNER_CENTER}
        r={radius}
        stroke={theme.colours.brand.interface.main}
        fill="none"
        strokeWidth={SPINNER_STROKE_WIDTH}
      />
    </svg>
  );
};

const TopSpinnerSection = ({
  animationPercentageOffset,
  radius,
}: {
  animationPercentageOffset: number;
  radius: number;
}) => (
  <TopSpinnerRotation animationPercentageOffset={animationPercentageOffset}>
    <SpinnerSectionInterior radius={radius} />
  </TopSpinnerRotation>
);

const BottomSpinnerSection = ({
  animationPercentageOffset,
  radius,
}: {
  animationPercentageOffset: number;
  radius: number;
}) => (
  <BottomSpinnerRotation animationPercentageOffset={animationPercentageOffset}>
    <SpinnerSectionInterior radius={radius} />
  </BottomSpinnerRotation>
);

const TopSpinner = () => (
  <SpinnerSectionContainer>
    <TopSpinnerSection animationPercentageOffset={10} radius={SPINNER_RADII[0]} />
    <TopSpinnerSection animationPercentageOffset={5} radius={SPINNER_RADII[2]} />
    <TopSpinnerSection animationPercentageOffset={0} radius={SPINNER_RADII[4]} />
  </SpinnerSectionContainer>
);

const BottomSpinner = () => (
  <SpinnerSectionContainer>
    <BottomSpinnerSection animationPercentageOffset={10} radius={SPINNER_RADII[1]} />
    <BottomSpinnerSection animationPercentageOffset={5} radius={SPINNER_RADII[3]} />
    <BottomSpinnerSection animationPercentageOffset={0} radius={SPINNER_RADII[5]} />
  </SpinnerSectionContainer>
);

export const LoadingSpinner = () => (
  <SpinnerWrapper data-testid="loading-spinner">
    <SpinnerContainer>
      <TopSpinner />
      <BottomSpinner />
    </SpinnerContainer>
    <LoadingMessage>Loading...</LoadingMessage>
  </SpinnerWrapper>
);

export default LoadingSpinner;
