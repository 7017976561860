import { styled } from '@statsbomb/react-components';
import { ROW_MARGIN } from '../../../consts/spacing';
import { PITCH_PORTRAIT_MAX_WIDTH, PITCH_LANDSCAPE_MAX_WIDTH } from '../../../consts/viz';
import { ZINDEX } from '../../../consts/zIndex';
import { ITheme } from '../../../types/ITheme';

export const PitchTitle = styled.p(({ theme, colour }: { theme: ITheme; colour: string }) => ({
  fontSize: theme.typography.body2.size,
  fontWeight: theme.typography.body2.weight,
  marginTop: ROW_MARGIN,
  color: colour,
}));

export const PassNetworkWrapper = styled.div(({ pitchBackground }: { pitchBackground: string }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 20px',
  backgroundColor: pitchBackground,
}));

export const PitchesWrapper = styled.div(
  ({ isPortrait, isSinglePitch }: { isPortrait: boolean; isSinglePitch: boolean }) => {
    const pitchWidthMultiplier = isSinglePitch ? 1 : 2;

    return {
      maxWidth: isPortrait
        ? `calc(${PITCH_PORTRAIT_MAX_WIDTH} * ${pitchWidthMultiplier})`
        : `calc(${PITCH_LANDSCAPE_MAX_WIDTH} * ${pitchWidthMultiplier})`,
      position: 'relative',
      width: '100%',
    };
  }
);

export const PassNetworkPitch = styled.div`
  width: '100%';
  overflow: visible;
`;

export const PassNetworkPitchSvg = styled.svg`
  overflow: visible;
`;

export const PassNetworkMarkersWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${ZINDEX.bodyContent[1]};
`;

export const Circle = styled.circle`
  cursor: pointer;
`;
