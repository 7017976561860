import React, { RefObject, useRef } from 'react';

/**
 * Custom hook to keep track of the mouse position.
 *
 * containerRef should be a reference to the container
 * within which you expect the mouse clicks to occur.
 *
 * The returned mouse position will be the position relative
 * to that container.
 */
export const useMousePosition = (containerRef: RefObject<HTMLDivElement>) => {
  const mousePosition = useRef({ x: 0, y: 0 });

  const changeMousePosition = (mouseEvent: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    const containerPosition = containerRef?.current?.getBoundingClientRect() || {
      left: 0,
      top: 0,
    };
    mousePosition.current = {
      x: mouseEvent.clientX - containerPosition.left,
      y: mouseEvent.clientY - containerPosition.top,
    };
  };

  return [mousePosition.current, changeMousePosition] as const;
};

export default useMousePosition;
